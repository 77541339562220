import React, { useState } from "react";
import Breathe1 from './../../../../Assets/Images/Group 2346.png';
import Breathe2 from './../../../../Assets/Images/Group 2347.png';
import Breathe3 from './../../../../Assets/Images/Group 2348.png';

import flip2 from './../../../../Assets/Images/blue.png';
import flip1 from './../../../../Assets/Images/green.png';
import flip3 from './../../../../Assets/Images/purple.png';

import club1 from './../../../../Assets/Images/club1.jpg';
import club2 from './../../../../Assets/Images/club2.jpg';
import club3 from './../../../../Assets/Images/club3.jpg';
import Close from './../../../../Assets/Images/Close.png';
import {Col, Row, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SectionTitle from "../../../../Components/SectionTitle";

// Breathe data 
const Breathe = [
    {image: Breathe1, flip: flip1},
    {image: Breathe2, flip: flip2},
    {image: Breathe3, flip: flip3}
];

export default (args)=>{
    const [modal, setModal] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const toggle = (prop) => {
        setModal(!modal);
        setActiveModal(prop);
    };
   

    return (
        <React.Fragment>
           <Container>
                <Row className="justify-content-center">
                    <Col xl={11}>
                        <SectionTitle >
                            <h3 className="section-title" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"><span>Let Humanity</span> Breathe</h3>
                            <div data-aos="zoom-in-up" data-aos-delay="150"  data-aos-duration="1000"><span class="sm-title"></span></div>
                        </SectionTitle>
                        <Row className="text-center">
                        {Breathe
                            .map((item, index) => (
                            <Col sm={6} md={4} key={index} data-aos="flip-left" data-aos-delay={`${index}0`}  data-aos-duration="1000" >
                            
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div className="image-wrap p-0 p-lg-4">
                                                <img src={item.image} alt="Breathe" className={`img${index}`} />
                                                
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div className="image-wrap p-0 p-lg-4">
                                                <img src={item.flip} alt="Breathe" className={`img${index}`} />
                                                <Button className="btn btn-light btn-lg" onClick={()=>toggle(index)}>Read More</Button> 
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                            </Col>
                        ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
            {activeModal === 0 && 
                <Modal isOpen={modal} toggle={toggle} {...args} className="modal-xl">
                    <button onClick={toggle}
                        type="button"
                        className="custom-close btn btn-link"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >
                        <img src={Close} alt="img" className="img-fluid" />
                    </button>
                <ModalHeader className="p-0" >
                    <img src={club3} alt="img" className="img-fluid" />
                </ModalHeader>
                <ModalBody className="p-4 m-xl-2">
                    <div className="pb-3">
                        <p>To care for someone who once cared for us is the highest honour. Getting old is an inevitable process and it is inherit in all the human being. We learn our identity and discover who we really are by being with them only.</p>
                        <p><strong>#Patriarch Club </strong> runs <strong>We Care Program </strong>; which helps our senior citizens. Personally, we really care our elderly people and want to give them a ray of hope so let’s join hands to make them happy by giving extra attention and affection.</p>
                    </div>
                    <div className="pb-3">
                        <h4>Who can get the Help </h4>
                        <p>Although we wish to take care all our beloved and venerable elderly but due to limited resources, as of now we are trying to help Alzheimer's patients. This disease is a neurological disorder in which death of brain cells causes memory loss and cognitive decline. </p>
                    </div>
                    <div className="">
                        <h4>How can we Help</h4>
                        <p><strong>We Care Programme </strong>is an initiative to keep Alzheimer's patients intact and to remain safe with their loved ones. He/She may get <strong>Medical Tag</strong> by sharing few information in below mentioned format and send it back to us at <a href="mailto:hello@intellotags.com">hello@intellotags.com</a> </p>
                        
                        <p>Patient Name</p>
                        <p>Caretaker Name</p>
                        <p>Address with Zip Code/ Pin Code</p>
                        <p>Emergency Contact No with Country Code</p>
                        <p>Email Id </p>
                        <p>Patient document mentioning Alzheimer (Attach in JPG Format)</p>
                    </div>
                </ModalBody>
            </Modal>
            }
            {activeModal === 1 && 
                <Modal isOpen={modal} toggle={toggle} {...args} className="modal-xl">
                    <button onClick={toggle}
                        type="button"
                        className="custom-close btn btn-link"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >
                        <img src={Close} alt="img" className="img-fluid" />
                    </button>
                <ModalHeader className="p-0">
                    <img src={club2} alt="img" className="img-fluid" />
                </ModalHeader>
                <ModalBody className="p-4 m-xl-2">
                    <div className="pb-3">
                        <p>Happiness begins from the moment you do something good for others. Those who consistently helps others, are Happy and do not come across any obstacles in their lives. Researches indicates that they are less stressed, live more and experience improved mental health. Your constant inner intent should be towards helping others. If you are unable to do so for any reason, make sure that you don't hurt anyone anymore.</p>
                        <p><strong>#Benevolent</strong> Club runs a <strong>Reward Programme</strong>; this programme is free incentive reward which auto is built in all the <strong>Intello Tags</strong>.</p>
                    </div>
                    <div className="pb-3">
                        <h4>Who can get the Reward</h4>
                        <p>Only the first responder who scans <strong>Intello Tags</strong>, Get connected with you or your loved ones, Gives information about your lost valuables or mishap is eligible and can be rewarded by us.</p>
                    </div>
                    <div className="">
                        <h4>How to get Reward</h4>
                        <p>Helping others is a wonderful inbuilt personality trait and nobody does it for greed but to spread this helping nature across the globe, we want to appreciate your efforts.</p>
                        <p>All you need to do is, simply click on an option (GET REWARD) after scanning the <strong>Intello Tag</strong> of the owner who needs your help utmost at that time. </p>
                        <ul>
                            <li>Scan the QR of the vehicle/valuable.</li>
                            <li>Click on Reward button</li>
                            <li>Fill The form and submit</li>
                            <li>Claim Reward</li>
                        </ul>
                        <p className="font-medium text-center">We will deliver your reward along with an appreciation certificate at your email id.</p>
                    </div>
                </ModalBody>
            </Modal>
            }
            {activeModal === 2 && 
                <Modal isOpen={modal} toggle={toggle} {...args} className="modal-xl">
                    <button onClick={toggle}
                        type="button"
                        className="custom-close btn btn-link"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >
                        <img src={Close} alt="img" className="img-fluid" />
                    </button>
                <ModalHeader className="p-0">
                    <img src={club1} alt="img" className="img-fluid" />
                </ModalHeader>
                <ModalBody className="p-4 m-xl-2">
                    <div className="pb-3">
                        <p>The Corona Virus (COVID-19) pandemic is the defining global health crisis of our time and the greatest challenge we have faced since World War II. Since its emergence in Asia last year, the virus has spread to every continent. Pandemic is much more than a health crisis, it's also a precedent social economic crisis, stressing every one of countries its touches. It has the potential to create devastating social, Economic & Political Effects. That will leave deep and long standing scars. <strong>#Altruistic Club</strong> runs an <strong>Appreciation Programme</strong> which helps all the workers and co-workers who are taking care of COVID-19 patients and maintain a balance in our society by providing services.</p>
                       
                    </div>
                    <div className="pb-3">
                        <h4>Who can get the Benefit</h4>
                        <p>We owe a depth of gratitude to all those Great People who selflessly put themselves in harm’s way to help others. We intend to serve all the Heroes and respect their work but as of now will be able to provide Intello Tags to Doctors, Police and Army personnel.</p>
                    </div>
                    <div className="">
                        <h4>How to get the Benefit</h4>
                        <p>Front liners like you deserves our respect, encouragement, for doing what you do. Please allow us to serve you by sharing few information in below mentioned format and send it back to us at <a href="mailto:hello@intellotags.com">hello@intellotags.com</a> </p>
                        
                        <p>Name</p>
                        <p>Address with Zip Code/ Pin Code</p>
                        <p>Contact No with Country Code</p>
                        <p>Email Id </p>
                        <p>Designation</p>
                        <p>Official ID card (Attach in JPG Format)</p>
                    </div>
                </ModalBody>
            </Modal>
            }
        </React.Fragment>
    )
} 