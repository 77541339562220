import React, { useEffect, useState, useRef } from "react";
import Header from '../../../Components/Header';
import {Container, Row, Col, Breadcrumb, BreadcrumbItem, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap'
import Plus from './../../../Assets/Images/Plus1x.png';
import Edit from './../../../Assets/Images/Edit.png';
import Delete from './../../../Assets/Images/delete.png';
import {useDispatch, useSelector} from "react-redux"
import { useNavigate, useLocation } from "react-router-dom";
import { fetchOTP, getAddress, placeOrder, postDeleteAddres, postsaveAddres } from "../../../utility/service";
import states from "./../../../Data/states.json"
import { toast } from 'react-toastify';
import validator from 'validator';
import { async } from "q";
import { LoaderForIndividualPageDispatch } from "../../../Components/Loader/LoaderForIndividualPage.slice.reducer";

function ManageAddress() {

    const navigate = useNavigate();
    const dispatch= useDispatch();
    const { pathname, state } = useLocation();

    const formRef = useRef(null);
    const [Coupon, setCoupon] = useState('')
    const [cSelected, setCSelected] = useState([]);
    const [rSelected, setRSelected] = useState(1);

    // Check out functionality start

    const [mobileNumberForOtp, setMobileNumberForOtp] = useState("");
    const [name, setName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [locality, setlocality] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [State, setSTate] = useState("");
    const [landmark, setLandmark] = useState("");
    const [alternateNumber, setalternateNumber] = useState("");
    const [addType, setAddType] = useState("home");
    
    const [nameErr, setNameErr] = useState(false)
    const [mobileNumberErr, setMobileNumberErr] = useState(false)
    const [pinCodeErr, setPinCodeErr] = useState(false)
    const [localityErr, setLocalityErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [cityErr, setCityErr] = useState(false)
    const [stateErr, setStateErr] = useState(false)
    const [landmarkErr, setLandmarkErr] = useState(false)
    const [alternateNumberErr, setAlternateNumberErr] = useState(false);
    const [addTypeErr, setAddTypeErr] = useState("Home");
    const [totalPriceOfTotalItems, setTotalPriceOfTotalItems] = useState();
    const [promoCode, setPromoCode] = useState("");
    const [randamDigitForTIandIN, setRandamDigitForTIandIN] = useState();
    const [savedAddressList, setSavedAddressList] = useState([]);
    
    
    const [addId, setAddId] = useState("");
    const [isAddEdit, setIsAddEdit] = useState(false);
    const [isApplyingDefaultAddress, setIsApplyingDefaultAddress] = useState(false);
    const [infoForPlaceOrder, setInfoForPlaceOrder] = useState({});
    const [billingInfo, setBillingInfo] = useState({});

    const [activeRoute] = useState(pathname.split("/")[1]);

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "name":
                setName(e.target.value.replace(/[^a-z\s]/gi, ''));
                setNameErr(false)
            break;
            case "mobileNumber":
                if(/^\d{0,12}$/.test(e.target.value)){
                    setMobileNumber(e.target.value);
                    setMobileNumberErr(false)
                }
            break;
            case "pinCode":
                if(/^\d{0,12}$/.test(e.target.value)){
                    setPinCode(e.target.value);
                    setPinCodeErr(false)
                }
            break;
            case "locality":
                setlocality(e.target.value);
                setLocalityErr(false)
            break;
            case "address":
                setaddress(e.target.value);
                setAddressErr(false)
            break;
            case "city":
                setcity(e.target.value);
                setCityErr(false)
            break;
            case "state":
                setSTate(e.target.value);
                setStateErr(false)
            break;
            case "landmark":
                setLandmark(e.target.value);
                setLandmarkErr(false)
            break;
            case "alternateNumber":
                if(/^\d{0,12}$/.test(e.target.value)){
                    setalternateNumber(e.target.value);
                    setAlternateNumberErr(false)
                }
            break;
           
        }
    }
    
    const cart= useSelector(state=>state.cart);
    const profile= useSelector(state=>state.profile);

    const [OTPmodal, setOTPModal] = useState(false);
    const [otpVerifyModal, setOtpVerifyModal] = useState(false);
    const [OTP, setOTP] = useState("");


    useEffect(()=>{
        async function getAddressAsync(){
            try{
                let dataObj= {
                    UserID: profile.details.UserId,
                }
                let res= await getAddress(dataObj, dispatch);
                
                if(res.Success.length >0){
                    setSavedAddressList(res.Success);
                    setRSelected(res.Success.find(add=>add.IsDefaultAddress === 1 ).ID);
                    
                    res.Success.forEach((add)=>{
                        // console.log("getAddressAsyncgetAddressAsync", add); 
                        if(add.IsDefaultAddress === 1){
                            setBillingInfo({Name: add.Name,
                                Mobile: add.Mobile,
                                Pincode: add.Pincode,
                                Locality: add.Locality,
                                Address: add.Address,
                                City: add.City,
                                State: add.State,
                                Landmark: add.Landmark
                            })
                        }
                    })


                    if(activeRoute === "manage-address"){

                        let info= res.Success.filter(add=> add.IsDefaultAddress === 1 ).map((add)=> {
                            return { 
                                MobileNumber: add.Mobile,
                                OTPNumber: OTP,
                                address_type: add.AddressType,  // --> radio button
                                pincode: add.Pincode,
                                customername: add.Name,
                                email: profile.details.Email,
                                phone: add.AlternatePhone,
                                add: add.Address,
                                billing_address: add.Address,
                                address: add.Address,
                                UserID: profile.details.UserId
                            }
                        })[0];
                        setInfoForPlaceOrder(info);
                    }
                 
                }else{
                    setRSelected("new");
                }
                
            }catch(err){
            }
        }
        getAddressAsync();
    }, [profile]);

    const applyAddType = (addType)=>{
        setAddType(addType)
    }

    const toggle=()=>{
        setOTPModal(!OTPmodal);
    }

    useEffect(()=>{
        // let getTotalPriceOfTotalItems= cart.item.map(item=> item.TotalAmount).reduce((acc, currentValue) => parseFloat(acc) + parseFloat(currentValue), 0);
        // setTotalPriceOfTotalItems(getTotalPriceOfTotalItems);

        if(state && state.TotalAmountMinusingFirstPurchaseDiscount){
            let getTotalPriceOfTotalItems= 0;
            getTotalPriceOfTotalItems= state.TotalAmountMinusingFirstPurchaseDiscount;
            setTotalPriceOfTotalItems(getTotalPriceOfTotalItems);
        }
        if(state){
            setPromoCode(state.promoCode);
        }
        let getRandamDigitForTIandIN= generateRandomString(10);
        setRandamDigitForTIandIN(getRandamDigitForTIandIN);
    }, [])

    const placeOrderAndShowOTPview = ()=>{
        return  <div>
            <Modal isOpen={OTPmodal} toggle={()=>{
                toggle();
            }} >
                <ModalHeader >Please verify your details before proceeding</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Input 
                            type="text" 
                            placeholder="Enter Mobile number" 
                            value={mobileNumberForOtp}
                            onChange={(e)=>{
                                if(/^\d{0,12}$/.test(e.target.value)){
                                    setMobileNumberForOtp(e.target.value);
                                }
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={getOTP}>Get OTP</Button>
                </ModalFooter>
            </Modal>
        </div>
    }

    const getOTP = async()=>{

        if(validator.isEmpty(mobileNumberForOtp)){
            toast.error("Please Enter Mobile Number", { });
            return;
        }
        
        let dataObje={
            MobileNumber: mobileNumberForOtp,
            UserID: profile.details.UserId
        }
        console.log("placePayment getOTP 6", profile.details)
        let res= await fetchOTP(dataObje, dispatch);

        
        if(!res.Success){

        }else{
            toggle()
            setOtpVerifyModal(!otpVerifyModal)
        }
    }

    const placeOrderAndShowAddressview = ()=>{
        return  <div>
            <Modal isOpen={otpVerifyModal} toggle={()=>{
                setOtpVerifyModal(false);
            }} >
                <ModalHeader >Please verify your details before proceeding</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input 
                                type="text" 
                                placeholder="Enter Mobile number" 
                                value={mobileNumberForOtp}
                                onChange={(e)=>{
                                    if(/^\d{0,12}$/.test(e.target.value)){
                                        setMobileNumberForOtp(e.target.value);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                type="text" 
                                value={OTP}
                                onChange={(e)=>{
                                    if(/^\d{0,12}$/.test(e.target.value)){
                                        setOTP(e.target.value);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary" onClick={getOTP}>resend OTP</Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="primary" 
                        onClick={placeCartItemOrder}
                    >Place order</Button>
                </ModalFooter>
            </Modal>
        </div>
    }

    const placeCartItemOrder = async()=>{

        if(validator.isEmpty(mobileNumberForOtp)){
            toast.error("Please Enter Mobile Number", { });
            return;
        }

        console.log("infoForPlaceOrder", infoForPlaceOrder)

        let dataObj= {
            CheckedOutCart: cart.item,
            ...infoForPlaceOrder,
            OTPNumber: OTP
        }
       
        try{

            let res= await placeOrder(dataObj, dispatch);
            console.log("placeOrderplaceOrder", res);
            if(res && res.Success && res.Success.ID == -1){
                toast.error(res.Success.TextMessage, { });
                return;
            }else{
                setOtpVerifyModal(false);
                setInfoForPlaceOrder({});
                dispatch(LoaderForIndividualPageDispatch(true))
                formRef.current.submit();
            }
        }catch(err){

        }
    }
     
    function generateRandomString(length) {
        const characters = '0123456789';
        let result = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
      
        return result;
    }

    const saveEditAddress = async() =>{

        // if you are on the /manage-address screen came from `add to cart`
        if(activeRoute === "manage-address" && rSelected !== 'new'){
            toggle();
            setMobileNumberForOtp( profile.details.Phone_Mobile );
            return;
        }

        if(name!== undefined && validator.isEmpty(name.trim())){
            toast.error("Please Enter First Name", { });
            setNameErr(true)
            return;
        }else if(name!== undefined && !validator.isLength(name.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setNameErr(true)
            return;
        }

        if(validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Phone Number", { });
            setMobileNumberErr(true)
            return;
        }

        if(validator.isEmpty(pinCode)){
            toast.error("Please Enter pinCode Code", { });
            setPinCodeErr(true)
            return;
        }

        if(validator.isEmpty(locality)){
            toast.error("Please Enter locality", { });
            setLocalityErr(true)
            return;
        }

        if(validator.isEmpty(address)){
            toast.error("Please Enter address", { });
            setAddressErr(true)
            return;
        }

        if(validator.isEmpty(city)){
            toast.error("Please Enter city", { });
            setCityErr(true)
            return;
        }
       
        if(validator.isEmpty(State)){
            toast.error("Please Enter state", { });
            setStateErr(true)
            return;
        }
        
        if(validator.isEmpty(addType)){
            toast.error("Please Select Address Type", { });
            return;
        }

        let ID= 0;
        let IsDefaultAddress= 1;
        if(isAddEdit){
            ID= addId
            IsDefaultAddress= 0;
        }

        let dataObj= {
            ID,
            IsDefaultAddress,
            Name: name,
            Mobile: mobileNumber,
            Pincode: pinCode,
            Locality: locality,
            Address: address,
            UserId: profile.details.UserId,
            City: city,
            State: State,
            Landmark: landmark,
            AlternatePhone: alternateNumber,
            AddressType: addType,
        }

        try{

            await postsaveAddres(dataObj, dispatch);

            if(activeRoute === "manage-address" && rSelected === 'new'){
                let info={ 
                    MobileNumber: mobileNumber,
                    OTPNumber: OTP,
                    address_type: addType,  // --> radio button
                    pincode: pinCode,
                    customername: name,
                    email: profile.details.Email,
                    phone: alternateNumber,
                    add: address,
                    billing_address: address,
                    address: address,
                    UserID: profile.details.UserId
                }
                setInfoForPlaceOrder(info);
                setBillingInfo({Name: name,
                    Mobile: mobileNumber,
                    Pincode: pinCode,
                    Locality: locality,
                    Address: address,
                    City: city,
                    State: State,
                    Landmark: landmark})
                setMobileNumberForOtp(mobileNumber);
                toggle();
                toast("Address Added successfully")
                return;
            }

            
            let resGetAddress= await getAddress({UserID: profile.details.UserId}, dispatch);
            setRSelected(resGetAddress.Success[resGetAddress.Success.length - 1].ID);
            
            if(resGetAddress.Success.length >0){
                setSavedAddressList(resGetAddress.Success);
            }

            if(isAddEdit){
                toast("Address Updated successfully")
            }else{
                toast("Address Added successfully")
            }
          
            resetAddress();
           
        }catch(err){

        }
    }

    const getStatsView = (e) =>{

        return [<option value= "" selected>Select State</option> ,states.states.map(st=>{

            if(State!=="" && st.state === State){
                return <option value= {st.state} selected>{st.state}</option>
            }
            return <option value= {st.state} >{st.state}</option>
        })]

    }

    const setThisAddDefault =async (add) =>{

        console.log('tis is addd', add)

        setBillingInfo({Name: add.Name,
            Mobile: add.Mobile,
            Pincode: add.Pincode,
            Locality: add.Locality,
            Address: add.Address,
            City: add.City,
            State: add.State,
            Landmark: add.Landmark
        })
        if(activeRoute === "manage-address"){
            let info={ 
                MobileNumber: add.Mobile,
                OTPNumber: OTP,
                address_type: add.AddressType,  // --> radio button
                pincode: add.Pincode,
                customername: add.Name,
                email: profile.details.Email,
                phone: add.AlternatePhone,
                add: add.Address,
                billing_address: add.Address,
                address: add.Address,
                UserID: profile.details.UserId
            }
            setInfoForPlaceOrder(info);
        }
         
        setRSelected(add.ID);
        setIsApplyingDefaultAddress(true)
        setIsAddEdit(false)

        let ID= add.ID
        let IsDefaultAddress= 1;

        let dataObj= {
            ID,
            IsDefaultAddress,
            Name: add.Name,
            Mobile: add.Mobile,
            Pincode: add.Pincode,
            Locality: add.Locality,
            Address: add.Address,
            UserId: profile.details.UserId,
            City: add.City,
            State: add.State,
            Landmark: add.Landmark,
            AlternatePhone: add.AlternatePhone,
            AddressType: add.AddressType,
        }
        try{

            let res= await postsaveAddres(dataObj, dispatch);
            
            resetAddress();
           
        }catch(err){

        }
    }

    const deleteAddressFromList =async (ID) =>{

        if(savedAddressList.find(add=>add.IsDefaultAddress === 1).ID === ID){
            toast.dismiss();
            toast.error("You can't delete default Address", { });
            return;
        }

        let dataObj= { ID }

        try{
            await postDeleteAddres(dataObj, dispatch);
            let resGetAddress= await getAddress({UserID: profile.details.UserId}, dispatch);
            setRSelected(resGetAddress.Success[resGetAddress.Success.length - 1].ID);
            toast("Address deleted successfully")
    
            if(resGetAddress.Success.length >0){
                setSavedAddressList(resGetAddress.Success);
                setRSelected(resGetAddress.Success.find(add=>add.IsDefaultAddress === 1 ).ID);
            }
        }catch(err){

        }
       
    }

    const getSavedAddressListView = () =>{
        
        if(savedAddressList.length > 0){
            return savedAddressList.map((add, index)=>{
                console.log("postsaveAddresspostsaveAddress", add.ID);
                return  <tr>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="customRadio" onClick={() => setThisAddDefault(add)} >
                                <input 
                                    type="radio" 
                                    id={add.ID} 
                                    name="address" 
                                    value={add.ID} 
                                    checked={rSelected == add.ID}
                                />
                                <label for="address1">
                                    <div className="mb-1">{add.Name} <Badge className="ms-1">{add.AddressType}</Badge><br></br></div>
                                    <small>{add.Locality}, {add.City}, {add.State} {add.Pincode}</small>
                                </label>
                            </div>
                        </div>
                    </td>
                    {activeRoute !== "manage-address" && <td>
                        <div className="d-flex align-items-center justify-content-end">
                            <img 
                                src={Edit} 
                                alt="edit" 
                                className="cursor-pointer"
                                onClick={()=>{
                                    onAddressEdit(add);
                                    setRSelected(add.ID);
                                }} 
                            /> 
                            <img 
                                src={Delete} 
                                alt="delete" 
                                className="cursor-pointer ms-2"
                                onClick={()=>{
                                    deleteAddressFromList(add.ID);
                                }} 
                            /> 
                        </div>
                    </td>}
                </tr>
            })
        }
    
    }

    const onAddressEdit = (add) =>{
        
        setIsAddEdit(true)
        setAddId(add.ID);
        setName(add.Name);
        setMobileNumber(add.Mobile);
        setPinCode(add.Pincode);
        setlocality(add.Locality);
        setaddress(add.Address);
        setcity(add.City);
        setSTate(add.State);
        setLandmark(add.Landmark);
        setalternateNumber(add.AlternatePhone);
        setAddType(add.AddressType);

    }
  
    const resetAddress = () =>{
        
        setIsAddEdit(false)
        setAddId("");
        setName("");
        setMobileNumber("");
        setPinCode("");
        setlocality("");
        setaddress("");
        setcity("");
        setSTate("");
        setLandmark("");
        setalternateNumber("");
        setAddType("");

    }

    // Check out functionality end

    return (
    <React.Fragment>
        <Header />
        {placeOrderAndShowOTPview()}
        {placeOrderAndShowAddressview()}
        <main className="bg-light">
            <section className="cart-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <div className="mb-3">
                                <h4>Cart</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem onClick={()=>{navigate("/")}}><a href={e=>e.preventDefault()}>Home</a></BreadcrumbItem>
                                    <BreadcrumbItem onClick={()=>{navigate("/cart")}}><a onClick={e=>e.preventDefault()}>Cart</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Managed Address</BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            <Row>
                                <Col lg={8} className="pt-2">
                                    
                                    <div className="address-sec">
                                    <table className="cart-listing">
                                            <thead>
                                                <tr>
                                                    <th>Delivery Address</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {getSavedAddressListView()}
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="customRadio radio-btn">
                                                                    <input type="radio" id="new" name="address" value="new" checked={rSelected === 'new'} onClick={() =>{
                                                                        resetAddress();
                                                                        setRSelected('new');
                                                                    }} />
                                                                    <label for="new">
                                                                        <div className="btn btn-outline-custom addNewAddress">
                                                                            <img src={Plus } alt="Plus" />Add a new address</div>
                                                                    </label>
                                                                </div>
                                                                {(rSelected === 'new' || isAddEdit) &&
                                                                <form className="pt-4 mt-0 mt-md-2">
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="name">Name</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    placeholder="Enter first Name" 
                                                                                   
                                                                                    name="name" 
                                                                                    value={name} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="phone">Mobile No.</label>
                                                                                <input
                                                                                    type="phone" 
                                                                                    className='form-control' 
                                                                                    placeholder="Enter mobile no." 
                                                                                    
                                                                                    name="mobileNumber" 
                                                                                    value={mobileNumber} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="pincode">Pincode</label>
                                                                                <input  
                                                                                    type="number" 
                                                                                    className='form-control' 
                                                                                    id="pincode" 
                                                                                    placeholder="Enter your pincode" 
                                                                                    
                                                                                    name="pinCode" 
                                                                                    value={pinCode} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="locality">Locality</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    id="locality" 
                                                                                    placeholder="Enter your locality" 

                                                                                    name="locality" 
                                                                                    value={locality} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <div className="mb-3">
                                                                                <label for="address">Address</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    id="address" 
                                                                                    placeholder="Enter your address" 
                                                                                    
                                                                                    name="address" 
                                                                                    value={address} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="city">City/District/Town</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    id="city" 
                                                                                    placeholder="Enter your city" 
                                                                                    
                                                                                    name="city" 
                                                                                    value={city} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="state">State</label>
                                                                                <select 
                                                                                    className="form-control" 
                                                                                    id="state" 
                                                                                    name="state"
                                                                                    onChange={onInputChange}
                                                                                >
                                                                                    {getStatsView()}
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="landmark">Landmark (Optional)</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    id="landmark" 
                                                                                    placeholder="Enter your landmark" 

                                                                                    name="landmark" 
                                                                                    value={landmark} 
                                                                                    onChange={onInputChange} 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label for="alternate">Alternate Phone (Optional)</label>
                                                                                <input  
                                                                                    type="text" 
                                                                                    className='form-control' 
                                                                                    id="alternate" 
                                                                                    placeholder="Enter your alternate"
                                                                                    
                                                                                    name="alternateNumber" 
                                                                                    value={alternateNumber} 
                                                                                    onChange={onInputChange} 
                                                                                     
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <label>Address Type</label>
                                                                                <div className="mt-1">
                                                                                    <span className="customRadio me-4">
                                                                                        <input 
                                                                                            type="radio" 
                                                                                            id="home" 
                                                                                            name="type" 
                                                                                            value="home"
                                                                                            checked= {"home"===addType}
                                                                                            onClick={(e)=>applyAddType(e.target.value)}
                                                                                        />
                                                                                        <label for="home">Home</label>
                                                                                    </span>
                                                                                    <span className="customRadio me-4">
                                                                                        <input 
                                                                                            type="radio" 
                                                                                            id="work" 
                                                                                            name="type" 
                                                                                            value="work"  
                                                                                            checked= {"work"===addType}
                                                                                            onClick={(e)=>applyAddType(e.target.value)}
                                                                                        />
                                                                                        <label for="work">Work</label>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </form>
                                                                }   
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                       {(rSelected === 'new' || activeRoute === "manage-address" || isAddEdit) && <div className="mt-2">
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary mt-4 btn-lg" 
                                                onClick={saveEditAddress}
                                            >Save / Continue</button>
                                        </div>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            {/* payment form */}
            <form 
                ref={formRef} 
                style={{display: "none"}} 
                method="post" id="myForm" name="myForm" action=" https://admin.intellotags.com/Home/Payment" class="ng-scope ng-pristine ng-valid"
            >
                amount:<input type="text" value={totalPriceOfTotalItems} name="amount" fdprocessedid="tlviu" />

                promocode: <input type="text" name="promocode" fdprocessedid="tlviu12" value={promoCode} />
               
                <h3>Form 2111</h3>
                {/* {{BaseURLPayment}} */}
                
                phone:<input type="text" value={infoForPlaceOrder.MobileNumber} name="phone" fdprocessedid="kln8x" />
                <br/>
                
                address_type: <input type="text" value={infoForPlaceOrder.address_type} name="address_type" fdprocessedid="5iqvic"  />
                <br/>
                
                shipping_address:<input type="text" value={infoForPlaceOrder.add} name="shipping_address" fdprocessedid="3zptlu" /><br/>
                
                pincode:<input type="text" value={ infoForPlaceOrder.pincode} name="pincode" fdprocessedid="6dsd2s" />
                <br/>
                
                customername:<input type="text" name="customername" value={infoForPlaceOrder.customername} fdprocessedid="jrquh" />
                <br/>
                
                OTPNumber:<input type="text" value={infoForPlaceOrder.OTPNumber} name="OTPNumber" fdprocessedid="ixllub" />
                <br/>
                
                userID:<input type="text" value={profile.details.UserId} name="userID" fdprocessedid="9tdyde" />
                <br/>
                
                InvoiceNumber:<input type="text" value={randamDigitForTIandIN} name="invoiceNumber" id="InvoiceNumber" fdprocessedid="bgsc3" />
                
                transactionID:<input type="text" value={randamDigitForTIandIN} name="transactionID" id="transactionID" fdprocessedid="xl11u8" />
                
                billing_address:<input type="text" value={`${billingInfo.Name} ${billingInfo.Mobile} ${billingInfo.Address} ${billingInfo.Locality} ${billingInfo.Landmark}  ${billingInfo.City} ${billingInfo.State} ${billingInfo.Pincode}`} name="billing_address" id="billing_address" placeholder="billing_address" fdprocessedid="1wh1s" />
                
                billing_email: <input type="text" value={profile.details.Email} name="billing_email" id="billing_email" placeholder="billing_email" fdprocessedid="281o9j" />
                {/* {{BaseURLPayment}} */}
                <br />
                <button type="submit" class="btn btn-primary" fdprocessedid="m77oxi">Pay</button>
            </form>            
        </main>
    </React.Fragment>
    );
}
  
  export default ManageAddress;