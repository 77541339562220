import React from "react";
import { Container, Row, Col } from 'reactstrap';
import Intelligence from './../../../../Assets/Images/intelligence.png';
import SectionTitle from "../../../../Components/SectionTitle";
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import Intelligence1 from './../../../../Assets/Images/features/1.png';
import Intelligence2 from './../../../../Assets/Images/features/2.png';
import Intelligence3 from './../../../../Assets/Images/features/3.png';
import Intelligence4 from './../../../../Assets/Images/features/4.png';
import Intelligence5 from './../../../../Assets/Images/features/5.png';
import Intelligence6 from './../../../../Assets/Images/features/6.png';
import Intelligence7 from './../../../../Assets/Images/features/7.png';
import Intelligence8 from './../../../../Assets/Images/features/8.png';

export default ()=>{  
    
    // features data 
const featuresData = [
    {image: Intelligence1, title: 'Simple Setup ', subTitle: 'Buy Tag<br/>Activate Tag<br/>Attach' },
    {image: Intelligence2, title: 'Smartphone Compatible', subTitle: 'IOS & Android Friendly<br/>Easy Scan' },
    {image: Intelligence3, title: 'Robust communication', subTitle: 'Get notified by Call<br/>Email<br/>WhatsApp' },
    {image: Intelligence4, title: 'Location Enabled', subTitle: 'Captures GPS Location<br/>Last scan history' },
    {image: Intelligence7, title: 'Go Global', subTitle: 'Deliver and Serve<br/>globally' },
    {image: Intelligence8, title: 'Weather-proof', subTitle: 'UV Protected<br/>Water Proof<br/>No wear & tear' },
    {image: Intelligence6, title: 'Maintenance – Free ', subTitle: 'No Recharge<br/>No Batteries<br/>No Repair' },
    {image: Intelligence5, title: 'Get Rewarded', subTitle: 'Be the first citizen<br/>Help other<br/>Get rewards' }
]

    return (
        <React.Fragment>
            <Container>
                    <div className="text-center  section-heading " >
                        <h3 className="section-title" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"><span>Intello</span> Features</h3>
                        {/* <p data-aos="zoom-in-up" data-aos-delay="50">A scan is tiny but peace of mind is huge</p> */}
                        <div data-aos="zoom-in-up"  data-aos-delay="150" data-aos-duration="1000"><span class="sm-title"></span></div>
                    </div>
                    <Row className="text-center">
                        {featuresData
                        .map((item, index) => (
                            <Col sm={6} lg={4} xl={3} className="p-3 feacture-block" data-aos="fade-up" data-aos-offset="100" data-aos-delay={`${index + 5}00`} data-aos-duration="1000" key={index}>
                                <div className="feacture-block-inner">
                                    <img className="mb-4 pb-1" src={item.image} alt={item.title} />
                                    <h4 className="text-primary">{item.title}</h4>
                                    <div dangerouslySetInnerHTML={{__html:item.subTitle}}></div>
                                </div>
                            </Col>
                        ))}
                    </Row>
            </Container>
            
        </React.Fragment>
    )
} 