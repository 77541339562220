import React from "react";
import { NavLink } from "reactstrap";
  
export default (props)=>{
    return (
        <React.Fragment>
            <NavLink
                className={props.isActiveTab === props.index && 'active'}
                // onClick={() => props.setTab(props.index)}
                onClick={props.setTab}
            >
                {props.children}
            </NavLink>
        </React.Fragment>
    )
} 