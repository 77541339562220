import React, { useEffect, useState, useReducer, useRef } from "react";
import { ToWords } from 'to-words';
import Header from '../../../Components/Header';
import {Table, Button, ButtonGroup,Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardSubtitle,  Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import Logo from './../../../Assets/Images/logo.png';
import Delete from './../../../Assets/Images/delete.png';
import EyeOff from './../../../Assets/Images/eye-off.png';
import Eye from './../../../Assets/Images/eye.png';
import B from './../../../Assets/Images/B.jpg';
import G from './../../../Assets/Images/G.jpg';
import P from './../../../Assets/Images/P.jpg';
import ProfileBg from './../../../Assets/Images/profilebg.jpg';
import ProfileImage from './../../../Assets/Images/userprofile.png';
import EditTag from './../EditTag';
import { LoaderForIndividualPageDispatch } from "../../../Components/Loader/LoaderForIndividualPage.slice.reducer";
import { toast } from 'react-toastify';
import validator from 'validator';
import {useSelector, useDispatch} from "react-redux"
import { useNavigate, useLocation } from "react-router-dom";
import { changePassword, enableDisableTag, fetchActivateTagList, getOrderSummary, getQRcode, postSaveDND, updateUserProfile } from "../../../utility/service";
import ManageAddress from "../ManageAddress";
import { profileDispatch } from "../../Pro/profile.slice";
import { groupBy } from "../../../utility/helper";
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet, Image, Font  } from '@react-pdf/renderer';
import fontUrl from './../../../Assets/fonts/cambria.ttf';
import { ImageCompressor } from 'image-compressor';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const imageCompressor = new ImageCompressor;
const compressorSettings = {
    toWidth : 800,
    // toHeight : 500,
    mimeType : 'image/png',
    mode : 'strict',
    quality : 0.9,
    // grayScale : true,
    // sepia : true,
    // threshold : 127,
    vReverse : false,
    hReverse : false,
    speed : 'low'
};

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: { // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: '',
        },
      }
    }
});
  
function Profile() {
    
    let { state } = useLocation();

    let activeTab= 'profile';
    if(state && state.showOrderSummary){
        activeTab= 'summery';
    }else if(state && state.showTag){
        activeTab= 'activated-tag';
    }
    
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const profile= useSelector(state=>state.profile).details;
    const [isActiveTab, setIsActiveTab] = useState(activeTab);
    const [isShowOrderDetails, setIsShowOrderDetails] = useState({
        isShowOrderDetails: false,
        summaryDetails: {},
        index: 0
    });
    const [isEdit, setIsEdit] = useState(null);
    const [qrCodeDetials, setQrCodeDetials] = useState({});
    const [isShowQRview, setIsShowQRview] = useState(false);
    const [isShowInvoiceview, setIsShowInvoiceview] = useState(false);
    const [invoiceProducts, setInvoiceProducts] = useState([{}]);

    const setTab = (id) => {
        setIsActiveTab(id);
        setIsEdit(null);
    }

    const editTag = (id) => {
        setIsEdit(id);
    }

    const [orderSummary, setOrderSummary] = useState([])
    const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState(profile.FirstName !== ""? profile.FirstName : "")
    const [lastName, setLastName] = useState(profile.LastName !== ""? profile.LastName : "")
    const [email, setEmail] = useState(profile.Email !== ""? profile.Email : "")
    const [gender, setGender] = useState(profile.Gender !== ""? profile.Gender : "")
    const [mobileNumber, setMobileNumber] = useState(profile.Phone_Mobile !== ""? profile.Phone_Mobile : "")
   
    const [dob, setDob] = useState(profile.DOB !== ""? new Date(profile.DOB) : "")
    // const [dob, setDob] = useState(profile.DOB !== ""? new Date(profile.DOB) : new Date())
    const [date, setDate] = useState(profile.DOB);
    // const [dob, setDob] = useState(new Date())
   
   
    const [emerGencyNumber, setEmerGencyNumber] = useState(profile.Phone_LandLine !== ""? profile.Phone_LandLine : "")
    const [address, setAddress] = useState(profile.Address !== ""? profile.Address : "");
    
    const [selectedImage, setSelectedImage] = useState(profile.UserProfilePath !== ""? profile.UserProfilePath : "");
    const [reduceselectedImage, setreduceSelectedImage] = useState(profile.UserProfilePath !== ""? profile.UserProfilePath : "");

    const [titleErr, setTitleErr] = useState(false)
    const [firstNameErr, setrFirstNameErr] = useState(false)
    const [lastNameErr, setLastNameErr] = useState(false)
    const [emailNameErr, setEmailNameErr] = useState(false)
    const [genderErr, setGenderErr] = useState(false)
    const [mobileNumberErr, setMobileNumberErr] = useState(false)
    const [dobErr, setDobErr] = useState(false)
    const [emerGencyNumberErr, setEmerGencyNumberErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [activeTagList, setActiveTagList] = useState([])
    const [enableDisableTagsList, setEnableDisableTagsList] = useState([])
    const [value, onChange] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const calendarRef = useRef(null);
    const inputRef = useRef(null);
    const [calendarPosition, setCalendarPosition] = useState('bottom');
    console.log("onDateChangeHandler 7", dob);

    const handleDateChange = (newDate) => {
        console.log("onDateChangeHandler 2", newDate)
        const date = new Date(newDate);
        const formattedDate = date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0');
      
        console.log("onDateChangeHandler 4", formattedDate );
        setDate(formattedDate)
        setDob(date);
        setShowCalendar(false); // Hide calendar after date selection
    };


    useEffect(()=>{
       
        setFirstName(profile.FirstName !== ""? profile.FirstName : "");
        setLastName(profile.LastName !== ""? profile.LastName : "");
        setEmail(profile.Email !== ""? profile.Email : "");
        setGender(profile.Gender !== ""? profile.Gender : "");
        setMobileNumber(profile.Phone_Mobile !== ""? profile.Phone_Mobile : "");
        setEmerGencyNumber(profile.Phone_LandLine !== ""? profile.Phone_LandLine : "");
        setAddress(profile.Address !== ""? profile.Address : "");
        setSelectedImage(profile.UserProfilePath !== ""? profile.UserProfilePath : "");
        
        if(profile.DOB !== "" && profile.DOB !== null){
            const originalDateString = profile.DOB;
            const originalDate = new Date(originalDateString);
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-based
            const day = originalDate.getDate().toString().padStart(2, '0');
            setDob(`${year}-${month}-${day}`)
            // setDob(new Date(profile.DOB))
            // setDate(new Date(profile.DOB).toISOString().slice(0, 10))
        }else{
            setDob("")
            // setDob(new Date())
            // setDate(new Date().toISOString().slice(0, 10))
        }
    }, [profile]);

    useEffect(()=>{

        let dataObj= {
            UserID: profile.UserId,
            View: 1
        }

        async function getOrderSummaryList(){
            try{

                dispatch(LoaderForIndividualPageDispatch(true))
                let orders= await getOrderSummary(dataObj, dispatch);
                if(orders.Success !== undefined && orders.Success.length >0){
                    setOrderSummary(orders.Success);
                }
                let res= await fetchActivateTagList({userId: profile.UserId}, dispatch);
                if(res.Success && res.Success && res.Success.length >0){
                    setActiveTagList(res.Success);
                }
                dispatch(LoaderForIndividualPageDispatch(false))

            }catch (err){
            
            }
        }

        getOrderSummaryList();
    }, [profile]);

    // useEffect(()=>{
    //     async function getActivateTagList(){
    //         try{
    //             let res= await fetchActivateTagList({userId: profile.UserId}, dispatch); 
    //             console.log('fetchActivateTagListfetchActivateTagList', res)
                
    //             if(res.Success && res.Success && res.Success.length >0){
    //                 setActiveTagList(res.Success);
    //                 // res.Success.map(tag =>{})
    //             }
    //         }catch (err){
    //             if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
    //         }
    //     }
    //     getActivateTagList();
    // }, [profile])

    useEffect(()=>{
        async function checkTab(){
            const data = await JSON.parse(localStorage.getItem('editTagScreen'))
            console.log("editTagScreeneditTagScreen", data)
            if(data){
                setIsEdit(data);
                setIsActiveTab("activated-tag");
            }
        }
        checkTab()
    }, [])
    // PROFILE EDIT START

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "firstName":
                setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
                setrFirstNameErr(false)
            break;
            case "lastName":
                setLastName(e.target.value.replace(/[^a-z]/gi, ''));
                setLastNameErr(false)
            break;
            case "mobileNumber":
                if(/^\d{0,12}$/.test((e.target.value))){
                    setMobileNumberErr(false)
                    setMobileNumber(e.target.value);
                }
            break;
            case "emergencyContact":
                if(/^\d{0,12}$/.test(e.target.value)){
                    setEmerGencyNumberErr(false)
                    setEmerGencyNumber(e.target.value);
                }
            break;
            case "address":
                setAddressErr(false)
                setAddress(e.target.value);
            break;
        }
    }
    
    const selectedValue = (e) =>{
        
        switch(e.target.name){
            case "gender":
                setGender(e.target.value);
                setGenderErr(false);
              break;
          }
    }

    const onDateChangeHandler = (e) =>{

        console.log("onDateChangeHandler 1", e.target.value)
        
        setDob(e.target.value);
        setDobErr(false);
    }

    const getGenderView = (e) =>{
            return <><option value= "" selected= {gender !== ""? true: false}>Select gender</option>
            <option value= "M" selected= {gender === "M"? true: false}>Male</option>
            <option value="F" selected= {gender === "F"? true: false}>Female</option>
            <option value="O" selected= {gender === "O"? true: false}>Other</option></>
    }
    
    const applyUpdateUserProfile= async(e)=>{

        
        e.preventDefault();
        toast.dismiss()
        // alert(mobileNumber)

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }else if(firstName!== undefined && !validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setrFirstNameErr(true)
            return;
        }
         
        if(lastName!== undefined && validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(lastName!== undefined && !validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }

        // gender
        if(validator.isEmpty(gender)){
            toast.error("Please Select gender", { });
            setGenderErr(true)
            return;
        }

        if(validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Phone Number", { });
            setMobileNumberErr(true)
            return;
        }

        // dob
        if(validator.isEmpty(date)){
            toast.error("Please Enter dob", { });
            setDobErr(true)
            return;
        }

        if(emerGencyNumber ===null ||validator.isEmpty(emerGencyNumber)){
            toast.error("Please Enter emergency Number", { });
            setEmerGencyNumberErr(true)
            return;
        }

        if(validator.isEmpty(address.trim())){
            toast.error("Please Enter address Name", { });
            setAddressErr(true)
            return;
        } 

        let UserProfilePath= "";

        if(selectedImage){
            UserProfilePath= selectedImage;
        }

        let updateProfileData ={
            Title: "Mrs",
            Gender: gender,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            DOB: dob, //-->2023-08-30,  yr mo da
            Phone_Mobile: mobileNumber,
            Phone_LandLine: emerGencyNumber,
            Address: address,
            UserProfilePath: "",
            UserId: profile.UserId,
            UserProfilePath
        }

        try{
            console.log('this is try registration', "try")
            await updateUserProfile(updateProfileData, dispatch);   
            toast("Profile Updated Successfully!");
            const data = await JSON.parse(localStorage.getItem('auth'))
            if( data !== null ){
                dispatch(profileDispatch(data.ID))
            }
        }catch (err){
        
        }
        
    }

    // PROFILE EDIT END
    
    // CHANGE PASSWORD LOGIC START

    const defaultReducerValue = {
        currentPassword: {value: "", isNotValid: false, name: "currentPassword"},
        newPassword: {value: "", isNotValid: false, name: "newPassword"},
        confirmPassword: {value: "", isNotValid: false, name: "confirmPassword"},
    };
    
    const CPreducer = (state, action)=>{
        
        switch(action.type){
            case "NEW_PASSWORD":
                return {
                    ...state,
                    newPassword: {
                        ...state.newPassword,
                        value: action.payload,
                        isNotValid: false
                    }
                }
            case "CURRENT_PASSWORD":
                return {
                    ...state,
                    currentPassword: {
                        ...state.currentPassword,
                        value: action.payload,
                        isNotValid: false
                    }
                }
            case "CONFIRM_PASSWORD":
                return {
                    ...state,
                    confirmPassword: {
                        ...state.confirmPassword,
                        value: action.payload,
                        isNotValid: false
                    }
                }
            
            case "ERR":
                return {
                    ...state,
                    [action.payload]: {
                        ...state[action.payload],
                        isNotValid: true
                    }
                }
    
            case "RESET":
                return defaultReducerValue
    
            default: 
               return state
        }
    }

    const [isCurrentPasswordMaskedOPen,  setisCurrentPasswordMaskedOPen] = useState(true)
    const [isNewPasswordMaskedOPen, setisNewPasswordMaskedOPen] = useState(true)
    const [isConfirmPasswordMaskedOPen, setConIsPasswordMaskedOPen] = useState(true)
    const [changePasswordInputes, dispatchchangePasswordInputes] = useReducer( CPreducer, defaultReducerValue );

    const onInputPasswordChange = (e)=>{
    
        switch(e.target.name){
          case "currentPassword":
            dispatchchangePasswordInputes({type: "CURRENT_PASSWORD", payload: e.target.value});
            break;
          case "newPassword":
            dispatchchangePasswordInputes({type: "NEW_PASSWORD", payload: e.target.value});
            break;
          case "confirmPassword":
            dispatchchangePasswordInputes({type: "CONFIRM_PASSWORD", payload: e.target.value});
            break;
        }
    }

    async function applyUpdatePassword(e){
    
        e.preventDefault();
        toast.dismiss()

        if(!validator.isLength(changePasswordInputes.currentPassword.value, {min: 8, max: 14})){
            if(changePasswordInputes.currentPassword.value === "") toast.error("Please Enter Current Password", { });
            else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "currentPassword"});
            return;
        }

        if(!validator.isLength(changePasswordInputes.newPassword.value, {min: 8, max: 14})){
            if(changePasswordInputes.newPassword.value === "") toast.error("Please Enter New Password", { });
            else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "newPassword"});
            return;
        }

        if(validator.isEmpty(changePasswordInputes.confirmPassword.value)){
            toast.error("Please Enter Confirm Password", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "confirmPassword"});
            return;
          }else if(changePasswordInputes.newPassword.value !== changePasswordInputes.confirmPassword.value){
            toast.error("New Password and Confirm Password must be the same", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "confirmPassword"});
            return;
          } 

          let updatePasswordData = {
            PasswordHash: changePasswordInputes.newPassword.value,
            OldPasswordHash:  changePasswordInputes.currentPassword.value,
            ID: profile.UserId
        }

        try{
            await changePassword(updatePasswordData, dispatch);
            dispatchchangePasswordInputes({type: "RESET"});
            toast("Password Updated Successfully!");
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }

    }

    // CHANGE PASSWORD LOGIC END
    
    
    
    
    // ORDER LOGIC START 

    function getOrderListing(){

        if(orderSummary.length >0){
            return orderSummary.map((order, index)=>{
                return (
                    !isShowOrderDetails.isShowOrderDetails && <div className="cart-listing">
                        <div className="d-flex align-items-center">
                            <img onClick={()=>getOrderDetails(order, index)} src={order.Image} alt="product" className="img-thumb cursor-pointer" />
                            <div className="w-100">
                                <Row>
                                    <Col>
                                        <div className="" onClick={()=>getOrderDetails(order, index)}>
                                            <p className="mb-0 cursor-pointer">{order.ProductName}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="text-end">
                                            <p className="mb-0">INR : {
                                            order.QRType === "Freemium" ?
                                            0 :
                                            getFixedTotalAmount(order)
                                            }</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col sm={6}>
                                        <small className="mb-0 text-disabled">Qty: {order.QTY}<br/></small>
                                        {order.order_id &&<small className="mb-0 text-disabled">Order ID: {order.order_id}<br/></small>}
                                        {order.tracking_id && <small className="mb-0 text-disabled">Track ID: {order.tracking_id}</small>}
                                    </Col>
                                    {order.tracking_id !== null ? <Col sm={6}>
                                        <div className="text-start text-sm-end">
                                            <Button className="nowrap link-color p-0" color="link" onClick={()=>{
                                                getInVoice(order);
                                                setIsShowInvoiceview(true);
                                            }}>View invoice</Button>
                                        </div>
                                    </Col>
                                     :
                                     <Col sm={6}>
                                        <div className="text-start text-sm-end">
                                         <Button className="nowrap link-color p-0" color="link" onClick={()=>qRHandler(order.ID)} style={{"position": "relative", "left": "-11px"}}>View</Button>
                                         <Button className="nowrap link-color p-0" color="link" onClick={()=>downLoadQR(order.ID)}>Download QR</Button>
                                         </div>
                                    </Col>
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                )
            })
        }else{
           return <p className="no-content text-disabled mh-50vh">You have not purchased any tag yet.</p>
        }
    }

    function getOrderDetails(order, index){
        if(orderSummary.length >0){
        
            if(index === isShowOrderDetails.index && isShowOrderDetails.isShowOrderDetails){
                setIsShowOrderDetails({
                    isShowOrderDetails: !isShowOrderDetails.isShowOrderDetails,
                    summaryDetails: {},
                    index: 0
                });
            }else if(index !== isShowOrderDetails.index && isShowOrderDetails.isShowOrderDetails){
                setIsShowOrderDetails({
                    isShowOrderDetails: !isShowOrderDetails.isShowOrderDetails,
                    ...isShowOrderDetails
                });
                setTimeout(() => {
                    setIsShowOrderDetails({
                        isShowOrderDetails: true,
                        summaryDetails: order,
                        index: index
                    });
                }, 0);
            }else{
                setIsShowOrderDetails({
                    isShowOrderDetails: !isShowOrderDetails.isShowOrderDetails,
                    summaryDetails: order,
                    index: index
                });
            }
        }
    }

    function showOrderDetailsView(){

        let order =isShowOrderDetails.summaryDetails; 
     
        return (
            <Col xxl={4} xl={6} md={6} className=" prod-listing my-3">
                <div className="white-box p-0 align-items-center">
                    <img src={order.Image} alt="product" className="img-lg img-fluid w-100" />
                    <div className="w-100 p-3 pt-0">
                        <h6 className="ps-2 mb-3 d-flex align-items-center">{order.ProductName} <small className="ms-3 ms-4 mb-0 summeryswatch" style={{background: `${order.Color}`}}></small></h6>
                        <div className="">
                            <table >
                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td>: {order.trans_date}</td>
                                    </tr>
                                    <tr>
                                        <td>QR Number</td>
                                        <td>: {order.QRNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>QR Type</td>
                                        {/* <td>: {order.TotalAmount === "0"? "Freemium" :"Premium"}</td> */}
                                        <td>: {order.QRType}</td>
                                    </tr>
                                    <tr>
                                        <td>Size</td>
                                        <td>: {order.Size}</td>
                                    </tr>
                                    <tr>
                                        <td>Quantity</td>
                                        <td>: {order.QTY}</td>
                                    </tr>
                                    <tr>
                                        <td>MRP Price</td>
                                        <td>: INR {order.MRP}</td>
                                    </tr>
                                    <tr>
                                        <td>Offer Price</td>
                                        <td>: INR {order.OfferPrice}</td>
                                    </tr>
                                    <tr>
                                        <td>New user discount</td>
                                        <td>: INR {order.FirstPurchaseDiscount}%</td>
                                    </tr>
                                    <tr>
                                        <td>Promo code discount</td>
                                        <td>: INR {order.PromoCodeDiscount}% {order.PromocodeDiscountvalue}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Amount</td>
                                        <td>: INR {order.QRType === "Freemium" ? 0 : getFixedTotalAmount(order)}</td>
                                    </tr>
                                    {/* {order.FirstPurchaseDiscountvalue && <tr>
                                        <td>First Purchase Discount Value</td>
                                        <td>: INR {order.FirstPurchaseDiscountvalue}</td>
                                    </tr>} */}
                                    {order.PromocodeDiscountvalue && <tr>
                                        <td>Promo Code Discount Value</td>
                                        <td>: INR {order.PromocodeDiscountvalue}</td>
                                    </tr>}
                                   
                                   
                                                               
                                    <tr>
                                        <td>Promo Code</td>
                                        <td>:{order.PromoCode}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Discount</td>
                                        <td>: {order.DiscountByAdmin}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Total Amount</td>
                                        <td>: INR {order.TotalAmount}</td>
                                    </tr> */}
                                    
                                    <tr>
                                        <td >Order ID</td>
                                        <td ><span>{order.order_id}</span></td>
                                    </tr>
                                    {order.tracking_id &&<tr>
                                        <td>Track ID</td>
                                        <td>: {order.tracking_id}</td>
                                    </tr>}
                                    
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3 text-center">
                        {order.tracking_id !== null ?
                            <Button className="btn-long" color="primary" onClick={()=>{
                                window.open("https://www.delhivery.com/", '_blank');
                            }} >Track Order</Button>
                            :
                            <ButtonGroup className="">
                                <Button color="primary" onClick={()=>qRHandler(order.ID)}>View</Button>
                                <Button color="primary" onClick={()=>downLoadQR(order.ID)}>Download</Button>
                            </ButtonGroup>
                        }
                        </div>
                        
                    </div>
                </div>
            </Col>
        )
        
    }

    const downLoadQR = async (orderID) => {

        let dataObj= { orderID }
        try{
            let getQRcodeDetails= await getQRcode(dataObj, dispatch);
            if(getQRcodeDetails.Success !== undefined && getQRcodeDetails.Success.length >0){
                
                const { QRBase64 } = getQRcodeDetails.Success[0];
                console.log("getQRcodeDetails.Success[0].QRBase64",QRBase64)
                const downloadLink = document.createElement('a');
                downloadLink.href = QRBase64;
                downloadLink.download = `image_${orderID}.jpg`; // You can specify the desired file name.
            
                downloadLink.style.display = 'none';
                document.body.appendChild(downloadLink);
            
                downloadLink.click();
        
                document.body.removeChild(downloadLink);
            }
        }catch (err){
            
        }
    }

    const qRHandler = async (orderID) => {
        
        let dataObj= { orderID }
        dispatch(LoaderForIndividualPageDispatch(true))
        try{
            let getQRcodeDetails= await getQRcode(dataObj, dispatch);
            if(getQRcodeDetails.Success !== undefined && getQRcodeDetails.Success.length >0){
                setQrCodeDetials(getQRcodeDetails.Success[0]);
                setIsShowQRview(true);
            }
        }catch (err){
            
        }
        
    }

    const getFixedTotalAmount = (order) => {
        if(Math.floor(getActualTotalAmount(order)))
            return parseFloat(getActualTotalAmount(order)).toFixed(2);
            return getActualTotalAmount(order);
    }
    
    const showQRview = ()=>{
        return  <div>
            <Modal isOpen={isShowQRview} toggle={()=>{
                setIsShowQRview(false);
            }} >
                <ModalHeader >QR Code List</ModalHeader>
                <ModalBody>
                    <img src={qrCodeDetials ?qrCodeDetials.QRBase64: "qr-crode"} alt="qrCodeDetials.DownloadUrl" onLoad={()=>{
                        dispatch(LoaderForIndividualPageDispatch(false))
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>{
                        setIsShowQRview(false);
                        setQrCodeDetials(null)
                    }}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    }
    // ORDER LOGIC  END


    const handleImageUpload = (event) => {

        const file = event.target.files[0];

        if(file && file.size && ((file.size / (1024 * 1024)) > 10)){
            toast.dismiss();
            toast("File size limited to 10MB");
            return;
        }
        
        if (file) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                const base64String = e.target.result;

                if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                    imageCompressor.run(base64String, compressorSettings, proceedCompressedImage);
                }else{
                    setSelectedImage(base64String);
                }
            };
            
            reader.readAsDataURL(file);
        }
    };

    const proceedCompressedImage = (compressedSrc) => {
        // setreduceSelectedImage(compressedSrc)
        setSelectedImage(compressedSrc);
    }
    
    const removeProfilePic = () => {
        setSelectedImage("");
    }

    function getActualTotalAmount(Order){
        
        if(orderSummary.length >0){

            let local_invoiceProducts =[];
            
            let getSameOrderIdInVoice =orderSummary.filter(order => order.order_id === Order.order_id);

            let isPromoCodeDiscount =false;
            let PromoCodeDiscountValue =0;

            getSameOrderIdInVoice.forEach((element, index) => {
                if(element.PromoCodeDiscount){
                    isPromoCodeDiscount = true;
                    PromoCodeDiscountValue =element.PromoCodeDiscount;
                }
            })

            local_invoiceProducts =getSameOrderIdInVoice.map((element, index) => {

                let TotalAmountOfThisTag= 0;
                if(isPromoCodeDiscount){
                    TotalAmountOfThisTag = (element.OfferPrice)- (+parseFloat( (element.OfferPrice* (+PromoCodeDiscountValue)) / 100));
                }else{
                    TotalAmountOfThisTag = +parseFloat(element.OfferPrice);
                }

                let TotalAmountOfferPrice = getProductTotalAmount(TotalAmountOfThisTag, element);
                
                let Qty = element.QTY;
                let ProductAmount = (((TotalAmountOfferPrice/118)*100).toFixed(2) / Qty).toFixed(2);
                let GSTInPercent = element.GSTInPercent+'%';
                let Tax_Rate = parseFloat(TotalAmountOfferPrice- ((TotalAmountOfferPrice/118)*100)).toFixed(2)
                let NetAmount = (+parseFloat(TotalAmountOfferPrice) - (+(Tax_Rate))).toFixed(2);

                return {

                    sNo: index+1,
                    ProductAmount: ProductAmount,
                    Qty: Qty,
                    NetAmount: NetAmount,
                    Tax_Rate: Tax_Rate,
                    GSTInPercent: GSTInPercent,
                    TotalAmount: getProductTotalAmount(TotalAmountOfThisTag, element),
                    tagName: element.ProductName,
                    element: {...element, }
                }
                
            });

            let TotalQty= 0;
            let TotalNetAmount= 0;
            let TotalTexAmount= 0;
            let TotalAmount= 0;

            local_invoiceProducts.forEach(order => {
                TotalQty= TotalQty +order.Qty;
                TotalNetAmount= TotalNetAmount + +order.NetAmount;
                TotalTexAmount= (+TotalTexAmount + +order.Tax_Rate).toFixed(2);
                TotalAmount= (+TotalAmount + +order.TotalAmount).toFixed(2);
            })
            return TotalAmount;
        }
    }


    function getProductTotalAmount(TotalAmountOfThisTag, element){
        if(element.IsFirstPurchase){
            let totalAmountOfThisTag = TotalAmountOfThisTag - (TotalAmountOfThisTag * element.FirstPurchaseDiscount) / 100;
            console.log('totalAmountOfThisTag 1', totalAmountOfThisTag)
            return totalAmountOfThisTag
        }else{
            return TotalAmountOfThisTag
        }
    }

    function getInVoice(Order, getTotalAmount){

        if(orderSummary.length >0){

            setInvoiceProducts([]);

            let local_invoiceProducts =[];
            
            let getSameOrderIdInVoice =orderSummary.filter(order => order.order_id === Order.order_id);

            let isPromoCodeDiscount =false;
            let PromoCodeDiscountValue =0;

            getSameOrderIdInVoice.forEach((element, index) => {
                if(element.PromoCodeDiscount){
                    isPromoCodeDiscount = true;
                    PromoCodeDiscountValue =element.PromoCodeDiscount;
                }
            })

            local_invoiceProducts =getSameOrderIdInVoice.map((element, index) => {

                // if(element.FirstPurchaseDiscount){
                //     FirstPurchaseDiscountvalue= element.OfferPrice * (element.FirstPurchaseDiscount / 100)
                // }
               
                let TotalAmountOfThisTag= 0;
                if(isPromoCodeDiscount){
                    TotalAmountOfThisTag = (element.OfferPrice)- (+parseFloat( (element.OfferPrice* (+PromoCodeDiscountValue)) / 100));
                }else{
                    TotalAmountOfThisTag = +parseFloat(element.OfferPrice);
                }

                let TotalAmountOfferPrice = getProductTotalAmount(TotalAmountOfThisTag, element);
                
                let Qty = element.QTY;
                let ProductAmount = (((TotalAmountOfferPrice/118)*100).toFixed(2) / Qty).toFixed(2);
                let GSTInPercent = element.GSTInPercent+'%';
                let Tax_Rate = parseFloat(TotalAmountOfferPrice- ((TotalAmountOfferPrice/118)*100)).toFixed(2)
                let NetAmount = (+parseFloat(TotalAmountOfferPrice) - (+(Tax_Rate))).toFixed(2);

                return {
                    sNo: index+1,
                    ProductAmount: ProductAmount,
                    Qty: Qty,
                    NetAmount: NetAmount,
                    Tax_Rate: Tax_Rate,
                    GSTInPercent: GSTInPercent,
                    TotalAmount: getProductTotalAmount(TotalAmountOfThisTag, element),
                    tagName: element.ProductName,
                    element: {...element, }
                }
                
            });

            let TotalQty= 0;
            let TotalNetAmount= 0;
            let TotalTexAmount= 0;
            let TotalAmount= 0;

            local_invoiceProducts.forEach(order => {
                TotalQty= TotalQty +order.Qty;
                TotalNetAmount= TotalNetAmount + +order.NetAmount;
                TotalTexAmount= (+TotalTexAmount + +order.Tax_Rate).toFixed(2);
                TotalAmount= (+TotalAmount + +order.TotalAmount).toFixed(2);
            })

            let local_invoiceProducts_with_total= local_invoiceProducts.map(order=>{
                return{
                    ...order,
                    total: {
                        TotalQty,
                        TotalNetAmount,
                        TotalTexAmount,
                        TotalAmount,
                    }
                }
            })
            setInvoiceProducts(local_invoiceProducts_with_total);

            if(getTotalAmount){
                console.log("groupBygroupBygroupBygroupBy 2", TotalAmount );
                return TotalAmount;
            }
        }
    }

    // function getTotalAmountOfProductWithQuantity(productId){

    //     if(orderSummary.length >0){
            
    //         let product =orderSummary.find(order => order.ID === productId);
    //         console.log("getInVoicegetInVoice",orderSummary, product, productId)
    //         let TotalAmountOfThisTag = +parseFloat(product.UserPaid);
    //         let ProductAmount = ((TotalAmountOfThisTag*(100/118)).toFixed(2));
            
    //         return ProductAmount    
    //     }
    // }

    

    // setInvoiceProducts(local_invoiceProducts);
    const getOrderRow= () =>{
        console.log("groupBygroupBygroupBygroupBy 44",invoiceProducts)
        return invoiceProducts.map(order=>{
            return (
            <tr className= {order.element && invoiceProducts[invoiceProducts.length-1].element.ID === order.element.ID ? `blank-table`: ""}>
                <td>{order.sNo}</td>
                <td className="w-40 text-start">{order.element && order.element.ProductName}</td>
                <td>{order.ProductAmount}</td>
                <td>{order.Qty}</td>
                <td>{order.NetAmount}</td>
                <td>{order.GSTInPercent}</td>
                <td>{order.Tax_Rate}</td>
                <td>{order.TotalAmount}</td>
            </tr>
            )
        })
    }  

    // Register font
    Font.register({ family: 'cambria', src: fontUrl, fontWeight: 700 });

    const styles = StyleSheet.create({
        section:{
           padding: '20px', 
           fontSize: '11px',
           lineHeight: '1.5',
           fontFamily: 'cambria',
           fontWeight: '100',
        },
        table: {
          width: '100%',
          border: '0.5px solid #0005',
        },
        row: {
          display: 'flex',
          flexDirection: 'row',
        },
        bold: {
          fontWeight: '800',
        },
        title: {
            fontWeight: '800',
            display:'inline-block',
            width: '100%',
            color: '#000',
            fontSize: '12px',
        },
        pera: {
            fontWeight: '100',
            display:'inline-block',
            width: '100%',
            color: '#363636',
        },
        // So Declarative and unDRY 👌
        col1: {
          width: '100%',
          border: '0.5px solid #0005',
          padding: '8px',
        },
        col2: {
          width: '50%',
          border: '0.5px solid #0005',
          padding: '8px',
        },
        col3: {
          width: '33.33%',
          border: '0.5px solid #0005',
          padding: '8px',
        },
        col4: {
          width: '25%',
          border: '0.5px solid #0005',
          padding: '8px',
        },
        col5: {
          width: '20%',
          border: '0.5px solid #0005',
          padding: '8px',
        },
        col11: {
            width: '11%',
            border: '0.5px solid #0005',
            padding: '8px',
          },
        col10: {
            width: '10%',
            border: '0.5px solid #0005',
            padding: '8px',
        },
        col8: {
            width: '8%',
            border: '0.5px solid #0005',
            padding: '8px',
        },
        col7: {
            width: '7%',
            border: '0.5px solid #0005',
            padding: '8px',
        },
        col80: {
            width: '80%',
            border: '0.5px solid #0005',
            padding: '8px',
        },
        col36: {
            width: '36%',
            border: '0.5px solid #0005',
            padding: '8px',
        },
        outerCol: {
            border: 'none',
            padding: '0px',
        },
        endRow: {
            paddingBottom: '150px',
        },
    })

    const getOrderRowForPDF = () => { 

        return invoiceProducts.map(order=>{

            let isLastItem= order.element && invoiceProducts[invoiceProducts.length-1].element.ID === order.element.ID ? styles.endRow: {};
            
            return <View style={[styles.row  , {textAlign: 'center'}]} >
                <View style={[styles.col8, isLastItem]}>
                    <Text>{order.sNo}</Text>
                </View>
                <View style={[styles.col36, {textAlign: 'left'} , isLastItem]}>
                    <Text style={styles.bold}>{order.element && order.element.ProductName}</Text>
                </View>
                <View style={[styles.col11, isLastItem]}>
                    <Text>{order.ProductAmount}</Text>
                </View>
                <View style={[styles.col7, isLastItem]}>
                    <Text>{order.Qty}</Text>
                </View>
                <View style={[styles.col10, isLastItem]}>
                    <Text>{order.NetAmount}</Text>
                </View>
                <View style={[styles.col8, isLastItem]}>
                    <Text>{order.GSTInPercent}</Text>
                </View>
                <View style={[styles.col10, isLastItem]}>
                    <Text>{order.Tax_Rate}</Text>
                </View>
                <View style={[styles.col10, isLastItem]}>
                    <Text>{order.TotalAmount}</Text>
                </View>
            </View>
    })

    }

    const MyDoc = () => (
        <Document>
            <Page>
            
            <View style={styles.section}>
                <Image src={Logo} style={{ width: '80px', marginBottom: '5px' }} />
                <Text style={{fontSize: '15px', textAlign: 'center', marginBottom: '5px'}}>Invoice</Text>
                <View style={styles.table}>
                    <View style={[styles.row]}>
                        <View style={styles.col2}>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Seller Address:</Text>
                            <Text style={[ styles.bold, styles.title ]}>Vestins Technologies</Text>
                            <Text style={[ styles.pera ]}>Regd Address :3/83 Rajendra Nagar</Text>
                            <Text style={[ styles.pera ]}>Sahibabad</Text>
                            <Text style={[ styles.pera ]}>Ghaziabad - 201005</Text>
                            <Text style={[ styles.pera ]}>State / UT Code : 09</Text>
                        </View>
                        <View style={styles.col2}>
                            <Text style={[ styles.bold,{marginBottom: '10px'} ]}>Shipping Address:</Text>
                            <Text style={[ styles.bold, styles.title ]}>{invoiceProducts[0].element ? invoiceProducts[0].element.shipping_address: "NA"}</Text>
                        </View>
                    </View>
                    <View style={[styles.row]}>
                        <Text style={styles.col2}>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Invoice No : </Text>
                            <Text style={[ styles.pera ]}>{invoiceProducts[0].element && invoiceProducts[0].element.ID}</Text>
                        </Text>
                        <Text style={styles.col2}>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Invoice Date and time : </Text>
                            <Text style={[ styles.pera ]}>{invoiceProducts[0].element && invoiceProducts[0].element.trans_date}</Text>
                        </Text>
                    </View>
                    <View style={[styles.row]}>
                        <Text style={styles.col2}>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Order ID : </Text>
                            <Text style={[ styles.pera ]}>{invoiceProducts[0].element && invoiceProducts[0].element.order_id}</Text>
                        </Text>
                        <Text style={styles.col2}>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Payment Transaction ID : </Text>
                            <Text style={[ styles.pera ]}>{invoiceProducts[0].element && invoiceProducts[0].element.tracking_id}</Text>
                        </Text>
                    </View>

                    {/* Product heading  */}
                    <View style={[styles.row  , {textAlign: 'center'}]} >
                        <View style={styles.col8}>
                            <Text>Sl No.</Text>
                        </View>
                        <View style={[styles.col36, {textAlign: 'left'} ]}>
                            <Text style={styles.bold}>Description</Text>
                        </View>
                    
                        <View style={styles.col11}>
                            <Text>Product Amount</Text>
                        </View>
                        <View style={styles.col7}>
                            <Text>Qty</Text>
                        </View>
                        <View style={styles.col10}>
                            <Text>Net Amount</Text>
                        </View>
                        <View style={styles.col8}>
                            <Text>Tax Rate</Text>
                        </View>
                        <View style={styles.col10}>
                            <Text>Tax Amount</Text>
                        </View>
                        <View style={styles.col10}>
                            <Text>Total Amount</Text>
                        </View>
                    </View>
                    {getOrderRowForPDF()}
                    <View style={[styles.row  , {textAlign: 'center'}]} >
                        <View style={[styles.col8]}>  
                        </View>
                        <View style={[styles.col36]}>
                            <Text>Total</Text>
                        </View>
                        <View style={[styles.col11]}>
                        </View>
                        <View style={[styles.col7]}>
                            <Text>{invoiceProducts[0].total && invoiceProducts[0].total.TotalQty}</Text>
                        </View>
                         <View style={[styles.col10]}>
                            <Text>{invoiceProducts[0].total && invoiceProducts[0].total.TotalNetAmount}</Text>
                        </View>
                         <View style={[styles.col8]}>
                        </View>
                         <View style={[styles.col10]}>
                            <Text>{invoiceProducts[0].total && invoiceProducts[0].total.TotalTexAmount}</Text>
                        </View>
                    
                        <View style={[styles.col10]}>
                            <Text>{invoiceProducts[0].total && invoiceProducts[0].total.TotalAmount}</Text>
                        </View>
                    </View>

                    <View style={[styles.row]}>
                        <View style={styles.col1}>
                            <Text style={[ styles.pera, {marginTop: '2px'} ]}>Amount Chargeable (in words)</Text>
                            <Text style={[ styles.bold, styles.title,{marginBottom: '10px'} ]}>Indian Rupees {
                                        invoiceProducts[0].total && toWords.convert(+invoiceProducts[0].total.TotalAmount, { currency: true})
                                    }</Text>
                        </View>
                    </View>

                    <View style={[styles.row]}>
                        <View style={styles.col1}>
                            <Text style={[ styles.pera, {marginTop: '2px'}  ]}>PAN No: AEFFS2338G</Text>
                            <Text style={[ styles.pera ]}>GST No: 09AEFFS2338G1ZY</Text>
                            <Text style={[ styles.pera, {marginBottom: '2px'} ]}>E-Mail : hello@intellotags.com</Text>
                        </View>
                    </View>

                    <View style={[styles.row]}>
                        <View style={styles.col1}>
                            <Text style={[ styles.pera, {textDecoration: 'underline'}, {marginTop: '2px'}  ]}>Declaration:</Text>
                            <Text style={[ styles.pera, {marginBottom: '2px'} ]}>We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.</Text>
                        </View>
                    </View>
               
                </View>
            </View>
            <View>
                <Text style={[{textAlign: 'center'}, {fontSize: '10px'}, {fontFamily: 'cambria'}] }>This is a Computer-Generated Invoice</Text>
            </View>
            
          </Page>
        </Document>
    );

    const activeTagListView = (e) =>{
        
        if(activeTagList.length === 0)
            return <p class="no-content text-disabled mh-50vh">You have no activated tag.</p>
        
        return activeTagList.map(tag =>{
            return <React.Fragment>
                    <Col md={4} sm={6} className="mb-3" >
                        <Card>
                        <img
                            alt="Sample"
                            src={tag.QRCodeImage}
                            // src={B}
                        />
                            <CardBody>
                                <CardSubtitle
                                className="mb-2 font-medium"
                                tag="p"
                                >
                                    <span className="me-1">QR NO.</span>
                                    <span className="float-end">{tag.QRNumber}</span>
                                </CardSubtitle>
                                <div className="mt-2 mb-3">
                                    <FormGroup switch className="ps-0">
                                        <Label for="tag1" className="text-disabled" check>Disable/Enable</Label>
                                        <Input
                                            type="switch"
                                            checked={tag.DNDForAll}
                                            className="float-end"
                                            id="tag1"
                                            // onChange={e=>alert(e.target.value)}
                                            onClick={async() => {
                                                try{
                                                    let tagData={
                                                        DND: !tag.DNDForAll,
                                                        QRId: tag.QRNumber,
                                                        ForValue: 5
                                                    }
                                                    await postSaveDND(tagData, dispatch); 
                                                }catch (err){
                                                    if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                                }
                                                setActiveTagList(activeTagList.map(atag =>{
                                                    if(atag.Id === tag.Id){
                                                        return {
                                                            ...atag, DNDForAll: !atag.DNDForAll
                                                        }
                                                    }else{
                                                        return {...atag}
                                                    }
                                                }))
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                    <div style={{
                                        "display": "flex",
                                        "justify-content": "space-between",
                                    }}>
                                        <div className="text-center">
                                            <a href="#" className="text-black"  onClick={event => {
                                                event.preventDefault()
                                                setIsEdit(tag)
                                            }}><u>Edit Tag</u></a>
                                        </div>
                                        <div className="text-center">
                                            <a href="#" className="text-black"  onClick={event => {
                                                event.preventDefault()
                                                setIsEdit(tag)
                                                setTimeout(() => {
                                                    const section2 = document.getElementById('location-summary');
                                                    section2.scrollIntoView({ behavior: 'smooth' }); 
                                                }, 400);
                                            }}><u>Location Summary</u></a>
                                        </div>
                                    </div>
                            </CardBody>
                        </Card>
                    </Col>
            </React.Fragment>
           
        })
    }
    
    const handleInputFocus = () => {
        if (inputRef.current) {
          const inputRect = inputRef.current.getBoundingClientRect();
          const spaceBelow = window.innerHeight - inputRect.bottom;
    
          // Check if space below is less than a threshold (e.g., 300px)
          if (spaceBelow < 300) {
            setCalendarPosition('top');
          } else {
            setCalendarPosition('bottom');
          }
    
          setShowCalendar(true);
        }
      };

    return (
    <React.Fragment>
        <Header />
        {showQRview()}
        <main className="bg-light">
            <section 
                className={`${showCalendar ?'pt-4': 'pt-4 pb-4'}`}
                style={{padding: showCalendar? "100px": "65px 0"}}
            >
                <Container>
                    <Row>
                        <Col className="pt-2" lg={3}>
                            <div className="white-box text-start">
                                <Nav tabs vertical className="align-items-baseline v-nave mobile-tab-view">
                                    <NavItem>
                                        <NavLink
                                            className={isActiveTab === 'profile' && 'active'}
                                            onClick={event => setTab('profile')}
                                        >
                                            Profile
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={isActiveTab === 'change-password' && 'active'}
                                            onClick={event => setTab('change-password')}
                                        >
                                            Change Password
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={isActiveTab === 'summery' && 'active'}
                                            onClick={event => setTab('summery')}
                                        >
                                            Order Summery
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={isActiveTab === 'activated-tag' && 'active'}
                                            onClick={event => setTab('activated-tag')}
                                        >
                                            Activated Tag
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={isActiveTab === 'delivery-address' && 'active'}
                                            onClick={event => setTab('delivery-address')}
                                        >
                                            Delivery Address
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>     
                        </Col>
                        <Col lg={9} className="pt-3">
                            <div 
                                className={`white-box ${isActiveTab === 'profile' && 'p-0'}`}
                                style={{overflow: showCalendar? "visible": "hidden"}}
                            >
                                {isEdit !== null ?
                                <EditTag 
                                    tagData={isEdit} 
                                    onSetIsEdit= {()=>setIsEdit(null)}
                                /> 
                                :
                                <TabContent activeTab={isActiveTab} className={` ${isActiveTab === 'profile' && 'p-0'}`}>
                                    <TabPane tabId="profile">
                                        <img src={ProfileBg} alt="profileBg" className="profilebg img-fluid w-100" />
                                        <div className="text-center profile-center">
                                            <span className="profileImg">
                                                <div className="prof-wrap">
                                                    <img src={selectedImage? selectedImage: ProfileImage} className="w-100" alt="profile" />
                                                    <input type= "file" onChange={handleImageUpload}  accept=".jpg, .jpeg, .png"/>
                                                    <span>Change<br /> Profile Photo</span>
                                                </div>
                                                <img 
                                                    src={Delete} 
                                                    alt="delete" 
                                                    title="Delete Profile Photo"
                                                    className="cursor-pointer delete-profile ms-2"
                                                    onClick={removeProfilePic}
                                                /> 
                                            </span>
                                                
                                            <h5 className="mt-3 font-medium text-capitalize">{profile.FirstName} {profile.LastName}</h5>
                                        </div>
                                        <Row className="justify-content-center mt-3 pt-0 pt-md-3">
                                            <Col xxl={8} xl={10}>
                                            <form className="pb-0 pb-md-3 ps-3 pe-3 mb-2 mb-md-0 pt-1" onSubmit={applyUpdateUserProfile}>
                                                <Row>
                                                    <Col className="mb-3" md={6}>
                                                        <label>First Name</label>
                                                        <input 
                                                            type="text" 
                                                            className='form-control' 
                                                            name="firstName" 
                                                            placeholder="Enter first Name" 

                                                            onChange={onInputChange} value={firstName} 
                                                        />
                                                    </Col>
                                                
                                                    <Col className="mb-3" md={6}>
                                                        <label>Last Name</label>
                                                        <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="lastName" 
                                                        placeholder="Enter last Name"
                                                        value={lastName} 
                                                        onChange={onInputChange} 
                                                        />
                                                    </Col>
                                                
                                                    <Col className="mb-3" md={6}>
                                                        <label>Email</label>
                                                        <input 
                                                            type="text" 
                                                            className='form-control' 
                                                            name="email" 
                                                            placeholder="Enter email"
                                                            value={email} 
                                                            onChange={onInputChange} 
                                                            disabled
                                                        />
                                                    </Col>

                                                    <Col className="mb-3" md={6}>
                                                        <label>Gender</label>
                                                        <select className="form-control" id="gender" name="gender" onChange={selectedValue}>
                                                            {getGenderView()}
                                                        </select>
                                                    </Col>

                                                    <Col className="mb-3" md={6}>
                                                        <label>Mobile no.</label>
                                                        <input 
                                                            type="phone" 
                                                            className='form-control' 
                                                            name="mobileNumber" 
                                                            placeholder="Enter mobile no." 
                                                            value={mobileNumber} 
                                                            onChange={onInputChange} 
                                                            ref={inputRef}
                                                            onFocus={handleInputFocus}
                                                        />
                                                    </Col>
                                                
                                                    <Col className="mb-3 calendar-container" md={6} ref={calendarRef} style={{ position: 'relative' }}>
                                                        <label>DOB</label>
                                                        <input 
                                                            type="date" 
                                                            className='form-control' 
                                                            name="dob" 
                                                            placeholder="DD/MM/YY" 
                                                            value={dob}
                                                            onChange={onDateChangeHandler}
                                                        />
                                                        {/* <Calendar onChange={onDateChangeHandler} value={dob} /> */}

                                                        {/* <input
                                                            type="text"
                                                            className='form-control' 
                                                            value={dob ? dob.toDateString() : ''}
                                                            placeholder="Select a date"
                                                            onFocus={() => setShowCalendar(true)} // Show calendar when input is focused
                                                            readOnly
                                                        />
                                                        {showCalendar && (
                                                            <div 
                                                                className="calendar-popup"
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: 1000,
                                                                    top: calendarPosition === 'bottom' ? '100%' : 'auto',
                                                                    bottom: calendarPosition === 'top' ? '100%' : 'auto',
                                                                }}
                                                            >
                                                                <Calendar
                                                                    onChange={handleDateChange}
                                                                    value={dob}
                                                                    // formatLongDate ={(locale, date)=>{
                                                                    //     // console.log("onDateChangeHandler 3", date)
                                                                    // }}
                                                                />
                                                            </div>
                                                        )} */}
                                                    </Col>

                                                    <Col className="mb-3" md={6}>
                                                        <label>Emergency contact no.</label>
                                                        <input 
                                                        type="phone" 
                                                        className='form-control' 
                                                        name="emergencyContact" 
                                                        placeholder="Enter emergency contact no."
                                                        value={emerGencyNumber} 
                                                        onChange={onInputChange} 
                                                        />
                                                    </Col>

                                                    <Col className="mb-3" md={6}>
                                                        <label>Address</label>
                                                        <input 
                                                            type="text" 
                                                            className='form-control' 
                                                            name="address" 
                                                            placeholder="Enter your address"
                                                            value={address} 
                                                            onChange={onInputChange}  
                                                        />
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-4 mt-4">
                                                            <button type="submit" className="me-3 btn btn-primary">Save</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                </form>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="change-password">
                                        <Row>
                                            <Col sm="12">
                                                <h5 className="mt-2">Update your password</h5>
                                                <form className="ms-0 mw-350 pt-2 mb-3" onSubmit={applyUpdatePassword}>
                                                    <div className="mb-3 ">
                                                        <label>Old Password</label>
                                                        <div className="password-field">
                                                        <input 
                                                            // type={'password'} 
                                                            className='form-control' 
                                                            // name={'old-password'} 
                                                            placeholder="Enter your old password"

                                                            value={changePasswordInputes.currentPassword.value} 
                                                            onChange={onInputPasswordChange} 
                                                            id={changePasswordInputes.currentPassword.name} 
                                                            name={changePasswordInputes.currentPassword.name} 
                                                            type={isCurrentPasswordMaskedOPen ?'password': "Text"}
                                                            // className={classNames("form-control", {"form-error": changePasswordInputes.currentPassword.isNotValid })}
                                                        />
                                                        <span onClick={()=>setisCurrentPasswordMaskedOPen(!isCurrentPasswordMaskedOPen)}>
                                                            <img src={isCurrentPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 ">
                                                        <label>New Password</label>
                                                        <div className="password-field">
                                                        <input 
                                                            // type="password" 
                                                            // name={'new-password'} 
                                                            placeholder="Enter your new password" 
                                                            className='form-control' 

                                                            value={changePasswordInputes.newPassword.value}
                                                            onChange={onInputPasswordChange} 
                                                            id={changePasswordInputes.newPassword.name} 
                                                            // placeholder="New Password" 
                                                            name={changePasswordInputes.newPassword.name} 
                                                            type={isNewPasswordMaskedOPen ?'password': "Text"}
                                                            // className={classNames("form-control", { "form-error": changePasswordInputes.newPassword.isNotValid })}
                                                        />
                                                         <span onClick={()=>setisNewPasswordMaskedOPen(!isNewPasswordMaskedOPen)}>
                                                            <img src={isNewPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 ">
                                                        <label>Confirm Password</label>
                                                        <div className="password-field">
                                                        <input 
                                                            // type="password" 
                                                            // name={'confirm-password'} 
                                                            placeholder="Enter your confirm password" 
                                                            className='form-control' 

                                                            value={changePasswordInputes.confirmPassword.value} 
                                                            onChange={onInputPasswordChange} 
                                                            id={changePasswordInputes.confirmPassword.name} 
                                                            // placeholder="Confirm Password" 
                                                            name={changePasswordInputes.confirmPassword.name} 
                                                            type={isConfirmPasswordMaskedOPen ?'password': "Text"} 
                                                            // className={classNames("form-control", { "form-error": changePasswordInputes.confirmPassword.isNotValid })}
                                                        />
                                                        <span onClick={()=>setConIsPasswordMaskedOPen(!isConfirmPasswordMaskedOPen)}>
                                                            <img src={isConfirmPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                                        </span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="pt-3">
                                                        <button type="submit"  className="btn btn-long btn-primary me-3">Save</button>
                                                        {/* <button type="button"  className="btn btn-long btn-outline-primary border-radius-6" >Cancel</button> */}
                                                    </div>
                                                    
                                                </form>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="summery">
                                        {!isShowOrderDetails.isShowOrderDetails &&
                                        <Row>
                                            <Col sm="12">
                                                <h5 className="mt-2" >Order Summery</h5>
                                                <div className="">
                                                    {getOrderListing()}
                                                </div>
                                            </Col>
                                        </Row>
                                        }
                                        {isShowOrderDetails.isShowOrderDetails && <Col sm="12">
                                            <Row className="align-items-center">
                                                <Col xs={8}>
                                                    <h5 className="mt-2">Order Detail</h5>
                                                </Col>
                                                <Col xs={4} className="text-end">
                                                <button className="btn btn-link p-0" onClick={()=>{
                                                    setIsShowOrderDetails({
                                                        isShowOrderDetails: !isShowOrderDetails.isShowOrderDetails,
                                                        summaryDetails: {},
                                                        index: 0
                                                    });
                                                   
                                                }}  color="link"><u>Back</u></button>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                {showOrderDetailsView()}
                                            </Row>
                                        </Col>}
                                    
                                    </TabPane>
                                    <TabPane tabId="activated-tag">
                                        <Row className="tag-lists">
                                            <Col sm="12">
                                                <h5 className="mt-2">Activated Tag</h5>
                                                <Row className="mt-4">
                                                    {activeTagListView()}
                                                    {/* <Col md={4} sm={6} className="mb-3">
                                                        <Card>
                                                        <img
                                                            alt="Sample"
                                                            src={B}
                                                        />
                                                            <CardBody>
                                                                <CardSubtitle
                                                                className="mb-2 font-medium"
                                                                tag="p"
                                                                >
                                                                    <span className="me-1">QR NO.</span>
                                                                    <span className="float-end">0123364549879546</span>
                                                                </CardSubtitle>
                                                                <div className="mt-2 mb-3">
                                                                    <FormGroup switch className="ps-0">
                                                                        <Label for="tag1" className="text-disabled" check>Enable/Disable</Label>
                                                                        <Input
                                                                        type="switch"
                                                                        checked={checkedState}
                                                                        className="float-end"
                                                                        id="tag1"
                                                                        onClick={() => {
                                                                            setCheckedStateState(!checkedState);
                                                                        }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="text-center">
                                                                    <a href="#" className="text-black"  onClick={event => {
                                                                        event.preventDefault()
                                                                        setIsEdit('1')
                                                                    }}><u>Edit Tag</u></a>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                        </Row>
                                        
                                    </TabPane>
                                    <TabPane tabId="delivery-address" className="profile-address">
                                        <h5 className="mt-2">Delivery Address</h5>
                                        <Row>
                                            <Col sm="12">
                                                <ManageAddress />          
                                            </Col>
                                        </Row>
                                    </TabPane >
                                
                                </TabContent>   
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* setInvoiceProducts(local_invoiceProducts); */}

            <Modal id="invicePdf" isOpen={isShowInvoiceview} className="modal-lg invoice-modal" toggle={()=>{
                setIsShowInvoiceview(false);
            }} >
                <ModalBody>
                    <div>
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div>
                        <h6 className="text-center">Invoice</h6>
                    </div>
                    <Table bordered className="w-100 mb-0 inner-table text-center" responsive>
                                       
                        <tbody>
                            <tr>
                                <td colSpan={2} className="text-start">
                                    <div className="mb-3">
                                        <strong>Seller Address:</strong>
                                    </div>
                                    <div>
                                        <strong>Vestins Technologies</strong>
                                        <p>Regd Address :3/83 Rajendra Nagar <br />Sahibabad<br /> Ghaziabad - 201005<br />State / UT Code : 09</p>
                                    </div>
                                </td>
                                <td colSpan={6} className="text-start">
                                    <div className="mb-3">
                                        <strong>Shipping Address:</strong>
                                    </div>    
                                    <div>
                                        <strong dangerouslySetInnerHTML={{ __html: invoiceProducts[0].element ? invoiceProducts[0].element.shipping_address: "NA" }}></strong>
                                        {/* <p>{invoiceProducts[0].element && invoiceProducts[0].element.billing_address}</p> */}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="text-start"><strong>Invoice No :</strong> {invoiceProducts[0].element && invoiceProducts[0].element.ID}</td>
                                <td colSpan={6} className="text-start"><strong>Invoice Date and time :</strong> {invoiceProducts[0].element && invoiceProducts[0].element.trans_date}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="text-start"><strong>Order ID :</strong> {invoiceProducts[0].element && invoiceProducts[0].element.order_id}</td>
                                <td colSpan={6} className="text-start"><strong>Payment Transaction ID :</strong> {invoiceProducts[0].element && invoiceProducts[0].element.tracking_id}</td>
                            </tr>
                            
                            <tr>
                                <td valign="top" className="sl-no">Sl No.</td>
                                <td valign="top" className="w-40 text-start">Description</td>
                                <td valign="top" >Product Amount</td>
                                <td valign="top" >Qty</td>
                                <td valign="top" >Net Amount</td>
                                <td valign="top" >Tax Rate</td>
                                <td valign="top" >Tax Amount</td>
                                <td valign="top" >Total Amount</td>
                            </tr>

                            {getOrderRow()}
                
                            <tr>
                                <td></td>
                                <td className="text-start">Total</td>
                                <td></td>
                                <td>{invoiceProducts[0].total && invoiceProducts[0].total.TotalQty}</td>
                                <td>{invoiceProducts[0].total && invoiceProducts[0].total.TotalNetAmount}</td>
                                <td></td>
                                <td><strong>{invoiceProducts[0].total && invoiceProducts[0].total.TotalTexAmount}</strong></td>
                                <td><strong>{invoiceProducts[0].total && invoiceProducts[0].total.TotalAmount}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan={8} className="text-start pb-3 pt-3">
                                    <p className="mb-0">Amount Chargeable (in words)</p>
                                    <p className="mb-4"><strong>Indian Rupees {
                                        invoiceProducts[0].total && toWords.convert(+invoiceProducts[0].total.TotalAmount, { currency: true})
                                    }</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="bottom" colSpan={8} className="text-start pt-3 pb-3">
                                    <p className="mb-0">PAN No: AEFFS2338G</p>
                                    <p className="mb-0">GST No: 09AEFFS2338G1ZY</p>
                                    <p className="mb-0">E-Mail : <a href="mailto:hello@intellotags.com ">hello@intellotags.com </a></p>
                                </td>
                                {/* <td colSpan={4} valign="bottom" className="text-end  border-start-0">
                                    <strong>Vestins Technologies </strong>
                                </td> */}
                            </tr>
                            <tr>
                                <td colSpan={8} className="text-start pt-3 pb-3">
                                    <u>Declaration:</u><br/>
                                    We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    
                    <p className="text-center"><small>This is a Computer-Generated Invoice</small></p>
                    <div className="text-center">
                    
                    {/* <Button color="primary" onClick={()=>{
                        setIsShowInvoiceview(false);
                    }}>Download</Button> */}

                        <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download now!'
                        }
                        </PDFDownloadLink>


                    <Button className="ms-2 btn outline-light" color="link" onClick={()=>{
                        setIsShowInvoiceview(false)
                    }}>Close</Button>
                    </div>
                </ModalBody>
                
            </Modal>
        </main>
    </React.Fragment>
  );
}
  
  export default Profile;