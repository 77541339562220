import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Row, Col, Container, NavLink } from 'reactstrap'
import Header from '../../../Components/Header';
import Image from './../../../Assets/Images/payment-declined.png'
// import { useDispatch, useSelector } from 'react-redux'
// import { setScrollToOnlineStoreDispatch } from "../../Home/Slices/scrollManagment.slice";

function PaymentFailed() {
  
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // let [searchParams] = useSearchParams();
  // const scrollManagment= useSelector(state=>state.scrollManagment);

  // const [orderid] = React.useState(
  //   searchParams.get("orderid")
  // );

  // useEffect(()=>{
  //   if(orderid === null) navigate('/');
  // }, []);

  return (
    <React.Fragment>
          <Header />
        <div className=" bg-light mh-100vh v-center-content">
          <Container>
            <Row className="m-0 justify-content-center">
              <Col className="pt-4 pb-4 pt-md-5 pb-md-5" lg={6} data-aos="flip-right" data-aos-delay="200" data-aos-duration="200">
                <div className="white-box text-center">
                  <img src={Image} alt="welcome" />
                  <div className="mw-350 pt-4 pb-4">
                    <h5>Payment Failed!</h5>
                    <p className="mb-4 mt-2 font-medium">We got some bad news. Your payment has been Failed, please try again.</p>
                    
                    <button className="btn btn-primary mt-2" onClick={()=> navigate('/cart')}>Try Again</button>
                  </div> 
                </div>
              </Col>
            </Row>
          </Container>
          
        </div>
    </React.Fragment>
  );
}

export default PaymentFailed;