import React , {useState} from "react";

import { Row, Col,Container,Breadcrumb, BreadcrumbItem ,  Accordion,
    AccordionBody,
    AccordionHeader,Modal, ModalHeader, ModalBody,
    AccordionItem, } from 'reactstrap';
import CareerImg from './../../Assets/Images/career.png';
import LocationImg from './../../Assets/Images/location.png';
import  HeaderFooter from './../../HeaderFooter';

function Career() {
    
    const [modal, setModal] = useState(false);
        const [open, setOpen] = useState('1');
        const toggle = (id) => {
          if (open === id) {
            setOpen();
          } else {
            setOpen(id);
          }
        };

        const toggleModal = () => {
            window.location.href = "mailto:hello@intelllotags.com";
        };

    return (
      <React.Fragment>
          <HeaderFooter>
           <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                <img src={CareerImg} className="w-100" alt="Career" />
           </div>

           <Container>
                <div className="my-4 pt-0 pt-md-4">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <Breadcrumb> 
                                <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                <BreadcrumbItem active>Career</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
           </Container>
          
           <div className=" main-content">
          
            <Container>
                <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <h3 className="text-center text-black mb-4">Current Openings</h3>
                            <div className=" ">
                            <Accordion flush className="careerAccordion" open={open} toggle={toggle}>

                                <AccordionItem className="white-box mb-3">
                                    <AccordionHeader children="true" targetId="1" className="flex-column ps-4 pe-4">
                                        <div className="px-0 px-md-3">
                                        <h5>HR Executive</h5>
                                        <p className="mb-0"><span className="me-3"><img src={LocationImg} alt="location" className="career-location" />Delhi</span><span>4+ Years</span></p>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <div className="job-description px-0 px-md-3">
                                            <p><strong>Role: </strong>HR Executive</p>
                                            <p><strong>Location: </strong>Delhi</p>
                                            <p><strong>Expericane: </strong>4+ Years</p>
                                            <p><strong>Key Skills: </strong>Recruitment, Employee Relations, HR Compliance, Training, Onboarding</p>
                                            <p><strong>Job Category: </strong>Human Resources</p>
                                       
                                            <h6 >As an HR Executive, your primary responsibilities will include</h6>
                                            <ul>

                                                <li>Recruitment: You will be actively involved in the recruitment process, from sourcing candidates to conducting interviews and making hiring decisions.</li>

                                                <li>Employee Relations: Building and maintaining strong relationships with employees, addressing their concerns, and ensuring a positive work environment.</li>

                                                <li>HR Compliance: Ensuring compliance with labor laws and company policies, including handling documentation and legal requirements.</li>

                                                <li>Training and Development: Identifying training needs, organizing development programs, and tracking employee growth.</li>

                                                <li>Onboarding: Facilitating the onboarding process for new hires, ensuring a smooth transition into the company.</li>

                                            </ul>
                                            <div className="pt-3">
                                                <button className="btn btn-primary btn-long" onClick={toggleModal}>Apply</button>
                                            </div>
                                       </div>
                                        
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className="white-box mb-3">
                                    <AccordionHeader children="true" targetId="2" className="flex-column ps-4 pe-4">
                                        <div className="px-0 px-md-3">
                                        <h5>Sales Executive</h5>
                                        <p className="mb-0"><span className="me-3"><img src={LocationImg} alt="location" className="career-location" />Delhi</span><span>4+ Years</span></p>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    
                                    <div className="job-description px-0 px-md-3">
                                        <p><strong>Role: </strong>Sales Executive</p>
                                        <p><strong>Location: </strong>Delhi</p>
                                        <p><strong>Experience: </strong>4+ Years</p>
                                        <p><strong>Key Skills: </strong>Sales, Relationship Building, Communication, CRM Software, Negotiation</p>
                                        <p><strong>Job Category: </strong>Sales and Business Development</p>

                                        <h6>What will your role be like</h6>
                                        <ul>
                                            <li>Driving sales and revenue growth for the organization by actively engaging in selling products or services to prospective clients.</li>
                                            <li>Developing and maintaining strong relationships with clients and key accounts, building trust for long-term partnerships.</li>
                                            <li>Effective communication in presenting product features, negotiating terms, and addressing customer inquiries.</li>
                                            <li>Utilizing Customer Relationship Management (CRM) software to manage leads, track interactions, and organize sales activities.</li>
                                            <li>Skillful negotiation to reach mutually beneficial agreements with clients or partners.</li>
                                        </ul>
                                        <div className="pt-3">
                                            <button className="btn btn-primary btn-long" onClick={toggleModal}>Apply</button>
                                        </div>
                                    </div>
                                        
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className="white-box mb-3">
                                    <AccordionHeader children="true" targetId="3" className="flex-column ps-4 pe-4">
                                        <div className="px-0 px-md-3">
                                        <h5>Sales Manager</h5>
                                        <p className="mb-0"><span className="me-3"><img src={LocationImg} alt="location" className="career-location" />Delhi</span><span>4+ Years</span></p>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <div className="job-description px-0 px-md-3">
                                        <p><strong>Role: </strong>Sales Manager</p>
                                        <p><strong>Location: </strong>Delhi</p>
                                        <p><strong>Experience: </strong>6+ Years</p>
                                        <p><strong>Key Skills: </strong>Sales Strategy, Team Management, Client Relationship, Market Analysis, Revenue Growth</p>
                                        <p><strong>Job Category: </strong>Sales and Business Development</p>

                                        <h6>What will your role be like</h6>
                                        <ul>
                                            <li>Leading and managing the sales team, setting sales goals, and developing sales strategies to achieve targets.</li>
                                            <li>Building and nurturing client relationships by providing excellent service and addressing client needs.</li>
                                            <li>Analyzing market trends and competition, identifying opportunities, and adapting strategies to maximize revenue growth.</li>
                                            <li>Overseeing the development and execution of sales plans and campaigns.</li>
                                            <li>Conducting team training, setting performance metrics, and monitoring sales performance.</li>
                                        </ul>
                                        <div className="pt-3">
                                            <button className="btn btn-primary btn-long" onClick={toggleModal}>Apply</button>
                                        </div>
                                    </div>
                                    </AccordionBody>
                                </AccordionItem>
                                
                                <AccordionItem className="white-box mb-3">
                                    <AccordionHeader children="true" targetId="4" className="flex-column ps-4 pe-4">
                                        <div className="px-0 px-md-3">
                                        <h5>Telemarketing</h5>
                                        <p className="mb-0"><span className="me-3"><img src={LocationImg} alt="location" className="career-location" />Delhi</span><span>4+ Years</span></p>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                    <div className="job-description px-0 px-md-3">
                                        <p><strong>Role: </strong>Telemarketing</p>
                                        <p><strong>Location: </strong>Delhi</p>
                                        <p><strong>Experience: </strong>2+ Years</p>
                                        <p><strong>Key Skills: </strong>Phone Sales, Communication, Lead Generation, Customer Service, Product Knowledge</p>
                                        <p><strong>Job Category: </strong>Sales and Customer Support</p>

                                        <h6>What will your role be like</h6>
                                        <ul>
                                            <li>Engaging in outbound phone sales and telemarketing activities to reach potential customers.</li>
                                            <li>Generating and qualifying leads through phone calls, emails, and other communication methods.</li>
                                            <li>Explaining product or service features and benefits to prospective customers.</li>
                                            <li>Providing exceptional customer service and addressing inquiries and concerns effectively.</li>
                                            <li>Maintaining a comprehensive understanding of the products or services offered.</li>
                                            <li>Recording and tracking customer interactions and sales data.</li>
                                        </ul>
                                        <div className="pt-3">
                                            <button className="btn btn-primary btn-long" onClick={toggleModal}>Apply</button>
                                        </div>
                                    </div>
                                    </AccordionBody>
                                </AccordionItem>
                                
                            </Accordion>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            

            {/* <Modal isOpen={modal} toggle={toggleModal} className="modal-lg inner-btn-modal">
                    
                <ModalHeader className="justify-content-center" toggle={toggleModal} >
                    <h4 className="m-0 pt-1 pb-1 text-black">Apply for AI/ML Engineer</h4>
                </ModalHeader>
                <ModalBody className="p-4 m-xl-2">
                    <form className="p-1 p-md-3">
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">First Name</label>
                                    <input 
                                        type="text" 
                                        className={'form-control'} 
                                        name="firstName" 
                                        placeholder="Enter your first name" 
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Last Name</label>
                                    <input  
                                        type="text" 
                                        className={'form-control'} 
                                        name="lastName" 
                                        placeholder="Enter your last name" 
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input 
                                        type="text" 
                                        className={'form-control'} 
                                        name="email" 
                                        placeholder="Enter your email"
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Mobile Number</label>
                                    
                                    <input
                                    type="text" 
                                    className="form-control"
                                    name="phoneNumber" placeholder="Enter your mobile number" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label">Expericane</label>
                                    <input
                                    rows={5}
                                    type="text" 
                                    className="form-control"
                                    name="experiance" placeholder="Enter your total expericane" />
                                </div>
                            </Col>
                            <Col className="mb-3" md={6}>
                                <div className="uploadFile">
                                    <label for="document">Document</label>
                                    <span>
                                        <input type="file" id="document" className='form-control' name="document" />      
                                        <label for="document" className="upload-label text-disabled">Upload your CV</label>
                                    </span>
                                    <div className="mt-2"><small className="xsmall">File size shouldn't be more than 5 MB. File type can be PDF, DOC, DOCX, PPT, PPTX, IMAGE or ZIP</small></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="text-center mb-1 mt-4">
                                    <button className="btn btn-primary">Apply Now</button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal> */}
            </div>
            </HeaderFooter>
      </React.Fragment>
    );
  }
  export default Career;