import React, {useEffect, useState} from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { Row, Col } from 'reactstrap'

 import Logo from './../../../Assets/Images/logo.png';

import { toast } from 'react-toastify';
import validator from 'validator';
import { useDispatch } from 'react-redux'
import { forgetPassword } from "../../../utility/service";
import classNames from 'classnames';
import LoginSlider from "../../../Components/LoginSlider";

  
function ResetPassword() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [emailNameErr, setEmailNameErr] = useState(false);
    
    const applyForgotPassword = async (e) =>{
      e.preventDefault() 
      toast.dismiss()
      // validation goes here
      if(!validator.isEmail(email)){
          if(email === "") toast.error("Please Enter Email", { });
          else toast.error("Please Enter valid Email", { });
          setEmailNameErr(true)
          return
      }

      try{
          let res= await forgetPassword({"email": email}, dispatch);
          console.log('this is try applyForgotPassword', res)
          navigate('/email-sent', { state: {"email": email}});
        }catch (err){
          if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          if(err!=undefined && err.status!= undefined && err.status ==="404") toast.error("Please provide valid Email id", { });
      }
  }

    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6}>
            
                <div className="signup-wrap ">
                  {/* Logo  */}
                  <div className="text-center pb-5 mt-3" data-aos="zoom-in-up" data-aos-delay="200" data-aos-duration="200">
                    <a href="/"><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    
                    <h5>Forgot Password</h5>

                    <form className=" pb-5 mb-2 mb-md-0 pt-2" onSubmit={applyForgotPassword} >
                      <div className="mb-3">
                          <input 
                            type={'text'} 
                            // className='form-control' 
                            // name={'email'} 
                            // placeholder="Email"
                            name="email" 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e)=>{
                              setEmailNameErr(false)
                              setEmail(e.target.value)
                            }}
                            className={classNames("form-control", { "form-error": emailNameErr })} 
                          />
                      </div>
                    
                      <button type="submit"  className="btn btn-primary w-100" >Next</button>
                    
                    <div className="text-center mt-4 pt-2">
                        Already have an account? <NavLink to="/login"><u>Login</u></NavLink>
                    </div>
                  </form>
                  </div>
                </div>

                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  export default ResetPassword;