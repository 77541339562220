import { createSlice } from '@reduxjs/toolkit'

import product1 from './../../../Assets/Images/product1.jpg';
import product2 from './../../../Assets/Images/product2.jpg';
import product3 from './../../../Assets/Images/product3.jpg';
import product4 from './../../../Assets/Images/product4.jpg';
import product5 from './../../../Assets/Images/product5.jpg';
import product6 from './../../../Assets/Images/product6.jpg';
import { BASE_URL, PRODUCTS, PRODUCTS_LIST } from '../../../utility/apiRoutes';
import { getData } from '../../../utility/rootService';

const initialState = {
    Products:  [  
        
    ],
    isResGet: false
}

export const productsListing = createSlice({
  name: 'productsListing',
  initialState: {
    ...initialState
  },
  reducers: {
    setProductsList: (state, action)=>{
      state.Products = action.payload;
      state.isResGet = true;
    },
    resetProductsList: (state, action)=>{
        return {...initialState}
    }
  }
})
export const { setProductsList, resetProductsList } = productsListing.actions

async function productDispatchListFetch(
    // token, 
    dispatch,
    CategoryID,
    ProductID =-1,
    Pageindex =1,
    Pagesize =10,
    SearchProducts =-1
    ){
  
      try{
        // let url= API.BASE_URL+API.GET_PROFILE_DETAILS;
        let url= BASE_URL+PRODUCTS+`CategoryID=${CategoryID}&ProductID=${ProductID}&Pageindex=${Pageindex}&Pagesize=${Pagesize}&SearchProducts=${SearchProducts}`;
       
        let headerConfig = {
          "Content-Type": "application/json",
          // "Authorization": "Bearer " +token
        }
  
        const fetchProduct =await getData({url, headerConfig}, dispatch)
      
      return fetchProduct;
      
    }catch (err){
  
      console.log("this is a fetchProduct catch")
      throw (err)
    }
  
  }
  
  export const getProductListDispatch = (
    CategoryID,
    ProductID,
    Pageindex,
    Pagesize,
    SearchProducts
    ) => {
  
    return async(dispatch, getState) => {
        
        // if(getState().authToken.payload === "") return;
        
        try{
            // let token = getState().authToken.payload.accessToken;
            const resProduct =await productDispatchListFetch(
                // token, 
                dispatch,
                CategoryID,
                ProductID,
                Pageindex,
                Pagesize,
                SearchProducts
            )
        console.log('getProductDispatch 1', resProduct) 
                
          dispatch(setProductsList(resProduct.Success.PagedList));
         
        }catch (err){
          console.log("this is a resProduct catch pp", err)
          if(err!= undefined && err.isError){
          }
      }
    }
  }


export const resetProductsDispatch = obj => {
    return async(dispatch, getState) => {
        dispatch(resetProductsList())
    }
}

export default productsListing.reducer