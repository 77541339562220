import React from "react";
  
export default (props)=>{


    return (
        <React.Fragment>
            <div 
                className={props.classes? props.classes: `rating`}
            >
                {
                    Array.isArray(props.rating)? props.rating.map((image, index) => (
                        <img 
                            src={image} 
                            alt="star"  
                            key={index} 
                            width={props.width? props.width: `20`} 
                            height={props.height? props.height: `20`} 
                        />
                    )) :
                    <img 
                        src={props.image} 
                        alt="star"  
                        key={props.index} 
                        width={props.width? props.width: `20`} 
                        height={props.height? props.height: `20`} 
                    />
                }
                {props.totalReviews && <small className="text-disabled ms-2 fw-500">(150 Reviews)</small> }
            </div>
        </React.Fragment>
    )
} 