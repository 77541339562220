import React, {useEffect, useState } from "react";
import {Col, Row, Container, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardTitle, CardText, Button, CardBody, Badge } from 'reactstrap';

import Nav_UI from "../../../../Components/UI/Nav/Nav_UI";
import NavItem_UI from "../../../../Components/UI/Nav/NavItem_UI";
import Badge_UI from "../../../../Components/UI/Badge/Badge_UI";
import ProductItemImage from "../../../../Components/Blocks/Home/ProductItemImage";
import Rating from "../../../../Components/Blocks/Home/Rating";
import NoProductItems from "../../../../Components/Blocks/Home/NoProductItems";
// import ProductItem from "./ProductItem";


import ProductsList from "./ProductsList";
import ProductCategory from "./ProductCategory";
import {useSelector, useDispatch} from "react-redux"
import SectionTitle from "../../../../Components/SectionTitle";
import { getProductCategoryDispatch } from "../../Slices/ProductCategory.slice";
import { getProductListDispatch } from "../../Slices/ProductsListing";
import {useNavigate} from "react-router-dom";

export default (props)=>{
    
    const dispatch= useDispatch();
    const navigate = useNavigate()
    const [isActiveTab, setIsActiveTab] = useState(0);
    const productCategories= useSelector(state=>state.ProductCategory);
    const ProductsListing= useSelector(state=>state.ProductsListing.Products);
    const scrollManagment= useSelector(state=>state.scrollManagment);
    const Product= useSelector(state=>state.Product);
    
    const setTab = (id, cat) => {
        let {CategoryID}= cat;
        setIsActiveTab(id);
        dispatch(getProductListDispatch(CategoryID, -1, 1, 10, -1));
    }
    
    useEffect(()=>{
        if(scrollManagment.CategoryID){
            setIsActiveTab(scrollManagment.CategoryID);
            return
        }
        
        setIsActiveTab(productCategories.catagories.length>0? productCategories.catagories[0].CategoryID: 0)
        
    }, [productCategories.catagories.length, scrollManagment.CategoryID]);
    
    useEffect(()=>{
        dispatch(getProductCategoryDispatch(scrollManagment.CategoryID));
    }, [scrollManagment.CategoryID]);
 
    const productCategoriesListView = () => {
        return productCategories.catagories.map((cat) =>{
            return<NavItem_UI><ProductCategory
                    isActiveTab= {isActiveTab}
                    index= {cat.CategoryID}
                    // index= {(index+1).toString()}
                    setTab= {()=>setTab(cat.CategoryID, cat)}
                    {...cat}
                >
                    {cat.Name}
                </ProductCategory></NavItem_UI>
        })
    }

    const productListView = (item) => {
        console.log("productListViewproductListView", item);
        return(
            <ProductsList 
                item={item}
                onClick= {()=>getProductDetails(item)}
            />
            )
    }

    const getProductDetails = (item) => {
        // console.log('productDispatchFetch 1', item);
        // dispatch(getProductDispatch(item, 1308, 1, 10, -1));
        navigate('/product-details', {state: item})
        // navigate("product-details");
    }

    return (
        <React.Fragment>
            <Container>
                <SectionTitle >
                    <h3 className="section-title"  data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"><span>Online</span> Store</h3>
                    <div data-aos="zoom-in-up" data-aos-delay="150" data-aos-duration="1000"><span class="sm-title"></span></div>
                </SectionTitle>
                
                <Nav_UI data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000">
                    {productCategoriesListView()}
                </Nav_UI>

                <TabContent activeTab={isActiveTab}>
                    <TabPane tabId={isActiveTab} className="mt-4">
                        <Row>
                            {ProductsListing
                            // .filter(item => (item.categoryId === isActiveTab))
                            .map((item, index) => { 
                                return <Col sm={6} md={4} lg={3} 
                                // className={` ${isShow && 'showItem1 '}`}
                                  data-aos="zoom-in-up" data-aos-delay="200" data-aos-duration="1000" key={index}>
                                    {productListView(item)}
                                </Col>    
                            } )}
                            {/* {ProductsListing.filter(item => (item.categoryId === isActiveTab)).length === 0 &&
                            //  <p className="text-disabled text-center p-4"></p>
                            <NoProductItems>No product in this category. {isActiveTab}</NoProductItems>
                            } */}
                        </Row>
                    </TabPane>
                </TabContent>
            </Container>
        </React.Fragment>
    )
} 