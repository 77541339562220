import React, { useState }  from "react";
import HeaderFooter from "../../HeaderFooter";
import { Breadcrumb, BreadcrumbItem,  Container } from 'reactstrap';
import DelhiNews from './../../Assets/Images/post/10.DelhiNews- 5.5.2023.jpeg';
import ReactImageMagnify from 'react-image-magnify';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { setScrollToNewsDispatch, setScrollToOnlineStoreDispatch } from "../Home/Slices/scrollManagment.slice";
import { useSelector, useDispatch } from 'react-redux'

export default (props)=>{

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { state } = useLocation();
    let item= state.item;
    console.log("Delhi NewsDelhi News", state);
    
    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos="zoom-in-up" data-aos-delay="100">{item.title}</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-4 mt-2 pb-3" data-aos="fade-in-right" data-aos-delay="100">
                    <Container>
                        <Breadcrumb>
                            <BreadcrumbItem
                                 onClick={()=>{
                                    navigate('/');
                                    dispatch(setScrollToOnlineStoreDispatch(0));
                                 }}
                            ><a href="#/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem
                                 onClick={()=>{
                                    navigate('/');
                                    dispatch(setScrollToNewsDispatch());
                                }}
                            ><a href="#/">News and Beyond</a></BreadcrumbItem>
                            <BreadcrumbItem active>{item.title}</BreadcrumbItem>
                        </Breadcrumb>
                    </Container>
                </section>
                <section className="pt-0">
                    <Container className="text-center">
                        <div className="white-box p-0 mt-2 d-inline-block minw-500">
                            <img src={item.image} alt={item.title} className="img-fluid w-100" />
                            
                        </div>
                    </Container>
                </section>
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 