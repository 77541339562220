import { createSlice, current } from '@reduxjs/toolkit'
import { BASE_URL, GET_CART_PRODUCT, POST_ADD_PRODUCT_TO_CART, PRODUCTS, REMOVE_PRODUCT_FROM_CART } from '../../../utility/apiRoutes';
import { getData, postData } from '../../../utility/rootService';
import { LoaderForIndividualPageDispatch } from '../../../Components/Loader/LoaderForIndividualPage.slice.reducer';

const initialState = {
   item: [],
   isResponseGet: false
}

export const cart = createSlice({
  name: 'cart',
  initialState: {
    ...initialState
  },
  reducers: {

    // Executs on first time load
    setCartItems: (state, action)=>{    
      console.log("item: action.payload", action.payload)
        return {
            ...state,
            isResponseGet: true,
            item: action.payload.sort((a, b) => {
              // Use localeCompare for case-insensitive sorting
              return a.ProductName.localeCompare(b.ProductName);
            })
        }
    
    },

    // increamentCartItem: (state, action)=>{

    //     console.log("getColorsgetColors 2", action.payload );
    //     return {
    //         ...state,
    //         item: action.payload
    //     }
    // },

    // decreamentCartItem: (state, action)=>{

    //     console.log("getColorsgetColors 2", action.payload );
    //     return {
    //         ...state,
    //         item: action.payload
    //     }
    // },

    resetCartItem: (state, action)=>{
        return {...initialState}
    }
  }
})
export const { setCartItems,
    increamentCartItem,
    decreamentCartItem,
    resetCartItem } = cart.actions;


// GET CART LIST

async function getCartItemsFetch(UserID, dispatch){
  
    try{

        // let url= BASE_URL+PRODUCTS+`CategoryID=${CategoryID}&ProductID=${ProductID}&Pageindex=${Pageindex}&Pagesize=${Pagesize}&SearchProducts=${SearchProducts}`;
        let url= BASE_URL+GET_CART_PRODUCT+`UserID=${UserID}`;

        let headerConfig = {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " +token
        }

        const fetchCartItems =await getData({url, headerConfig}, dispatch)
        
        return fetchCartItems;
        
    }catch (err){
        console.log("this is a fetchCartItems catch")
  
      throw (err)
    }
  
  }
  
  export const getCartItemsDispatch = () => {
  
    return async(dispatch, getState) => {
        
        try{
          // let UserID = getState().auth.data.ID;
          const data = await JSON.parse(localStorage.getItem('auth'))
          const resCartItems =await getCartItemsFetch(data.ID, dispatch)
          dispatch(LoaderForIndividualPageDispatch(false))
          dispatch(setCartItems(resCartItems.Success));
         
        }catch (err){
          if(err!= undefined && err.isError){
          }
      }
    }
  }


  // INCREAMENT DECREAMENT CART ITEM

  export const inDecreamentCartItems =async (data, dispatch=null) =>{
  
      try{
        
        // let url= BASE_URL+REGISTRATION;
        let url= BASE_URL+POST_ADD_PRODUCT_TO_CART;
        let dataObje= {...data}; 
        let headerConfig = {
          "Content-Type": "application/json"
        }
        console.log("cartcart", data);

        dispatch && dispatch(LoaderForIndividualPageDispatch(true))
        const resPostAddToCart =await postData({url, headerConfig, dataObje})
        
        return resPostAddToCart;
      
    }catch (err){
  
      throw (err)
    }
  
  }
  
  export const inDecreamentCartItemsDispatch = ({
        CategoryID,
        ProductID,
        QTY,
        Color,
        Size,
        LastSlectedProductType
    }) => {
      
    return async(dispatch, getState) => {
        
        try{
            // let UserID = getState().auth.data.ID;
            const data = await JSON.parse(localStorage.getItem('auth'));
            let dataObje= {
              UserID: data.ID,
              CategoryID:  CategoryID,
              ProductID: ProductID,
              QTY: QTY,
              Color: Color,
              Size: Size,
              LastSlectedProductType
            }

            dispatch(LoaderForIndividualPageDispatch(true))
            dispatch(resetCartItem())
            await inDecreamentCartItems(dataObje, dispatch)
            dispatch(getCartItemsDispatch());
            
         
        }catch (err){
          if(err!= undefined && err.isError){
          }
      }
    }
  }


//  REMOVE CART ITEM

export const removeCartItem =async (data) =>{
  
    try{
      
      // let url= BASE_URL+REGISTRATION;
      let url= BASE_URL+REMOVE_PRODUCT_FROM_CART;
      let dataObje= {...data}; 
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const removeCartItem =await postData({url, headerConfig, dataObje})
      
      return removeCartItem;
    
  }catch (err){

    throw (err)
  }

}

export const removeCartItemDispatch = (
    ID
  ) => {

  return async(dispatch, getState) => {
      
      try{
          // let UserID = getState().auth.data.ID;
          const data = await JSON.parse(localStorage.getItem('auth'));
          let dataObje= {
            UserID: data.ID,
            ID
          }
          dispatch(LoaderForIndividualPageDispatch(true))
          const resCartItems =await removeCartItem(dataObje)
          dispatch(resetCartItem())
          dispatch(getCartItemsDispatch());
          //  dispatch(setCartItems(resCartItems.Success));
          console.log("setProductsetProductsetProduct", resCartItems.Success)
       
      }catch (err){
        if(err!= undefined && err.isError){
        }
    }
  }
}

export const resetCartItemDispatch = obj => {
    return async(dispatch, getState) => {
        dispatch(resetCartItem())
    }
}

export default cart.reducer