import React, { useEffect, useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container} from 'reactstrap';
import Logo from './../../Assets/Images/logo.png';
import Cart from './../../Assets/Images/cart.png';
import User from './../../Assets/Images/headeruser.png';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetAuth } from "../../Screen/Authentications/Login/slice.reducer";
import { getCartItemsDispatch, resetCartItem, resetCartItemDispatch } from "../../Screen/Home/Slices/cart.slice";
import { resetProduct } from "../../Screen/Home/Slices/Product.slice";
import { resetProductCategory, resetProductsDispatch } from "../../Screen/Home/Slices/ProductCategory.slice";
import { resetProductsList } from "../../Screen/Home/Slices/ProductsListing";
import { resetProfile } from "../../Screen/Pro/profile.slice";
import { resetscrollManagment, setScrollToOnlineStoreDispatch } from "../../Screen/Home/Slices/scrollManagment.slice";

export default ({ direction, ...args })=>{

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cart= useSelector(state=>state.cart);
    const toggleNavbar = () => setCollapsed(!collapsed);
    const auth = useSelector(state => state.auth)
    const [collapsed, setCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const scrollManagment= useSelector(state=>state.scrollManagment);
    const profile= useSelector(state=>state.profile).details;

    const clearEveryThings =async () => {
        dispatch(resetAuth());
        dispatch(resetCartItem());
        dispatch(resetCartItemDispatch());
        dispatch(resetProduct());
        dispatch(resetProductCategory());
        dispatch(resetProductsDispatch());
        dispatch(resetProductsList());
        dispatch(resetProfile());
        dispatch(resetscrollManagment());
        localStorage.clear();
    }

    useEffect(()=>{

        if(auth.data){
            dispatch(getCartItemsDispatch());
        }

    }, [auth.data])

    const toggle = () => setDropdownOpen((prevState) => !prevState);
      
    return (
        <React.Fragment>
            <header id="header">
            <span onClick={toggleNavbar} className={`navbarShadow ${!collapsed && 'actMenu'}`} ></span>
               <Container>
                    <Navbar className="fixed-top1 navbar-expand-lg" color="faded" light>
                        <NavbarBrand href="/" className="me-auto">
                            <img src={Logo} alt="logo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="d-block d-lg-none me-2" />
                        <Collapse isOpen={!collapsed} navbar>
                            <Nav navbar className="ms-auto me-auto">
                                {/* <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Intello Tags
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>Option 1</DropdownItem>
                                        <DropdownItem>Option 2</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}
                                <NavItem>
                                    <NavLink 
                                        onClick={()=>{
                                            setCollapsed(true);
                                            navigate('/');
                                            dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                                        }} href="#"
                                    >Buy Tags</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={()=>{
                                        navigate('/activate-tag')
                                    }} href="#">Activate Tag</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink 
                                        onClick={()=>{
                                            navigate('/edit-tags')
                                        }}
                                    href="#">Edit Tag</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                         onClick={()=>{
                                            navigate('/claim-revard');
                                        }}
                                    href="#">Claim Reward</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="me-0 ms-0 align-items-center">
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/cart')}>
                                        <span className="header-cart">
                                            <img src={Cart} alt="Cart" />
                                            {cart.item.length >0 &&<span className="cart-count"> {cart.item.length}</span>}
                                        </span>
                                    </NavLink>
                                </NavItem>
                               {auth.data === null && <NavItem>
                                    {/* <NavLink href="#" className="btn btn-outline-primary" onClick={()=> navigate('/login')}>Login</NavLink> */}
                                    <Dropdown className="header-user" isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                                    <DropdownToggle color="btn-outline-primary" className="btn btn-outline-primary">
                                        Login
                                    </DropdownToggle>
                                    <DropdownMenu {...args}>
                                        <DropdownItem onClick={()=> navigate('/login')}>Login</DropdownItem>
                                        <DropdownItem onClick={()=> window.open(" https://admin.intellotags.com/#/login", '_blank')}>Corporate Login</DropdownItem>
                                        <DropdownItem onClick={()=> navigate('/signup')}>Signup</DropdownItem>
                                    
                                    </DropdownMenu>
                                </Dropdown>
                                </NavItem>}

                                {auth.data !== null && <Dropdown className="header-user" isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                                    <DropdownToggle className="btn btn-link p-0">
                                        <img src={User} alt="User" />
                                    </DropdownToggle>
                                    <DropdownMenu {...args}>
                                    <DropdownItem header className="text-primary">Hello <span className="text-capitalize">{profile.FirstName}</span>!</DropdownItem>
                                    {/* <DropdownItem divider /> */}
                                    <DropdownItem onClick={()=> navigate('/profile')}>Profile</DropdownItem>
                                    <DropdownItem onClick={()=>{
                                        clearEveryThings();
                                        navigate('/login')
                                    }}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>}
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </header>
        </React.Fragment>
    )
} 