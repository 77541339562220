import React, { useEffect, useState }  from "react";
import HeaderFooter from "../../HeaderFooter";
import {Card, CardTitle, CardText, Button, CardBody, Badge,  Accordion, AccordionBody, AccordionHeader, AccordionItem, Breadcrumb, BreadcrumbItem, Row, Col, Container, FormGroup, Label, Input } from 'reactstrap';

import LargeImage from './../../Assets/Images/intelligence.png';
import Rating from "./../../Components/Blocks/Home/Rating";
import Star from './../../Assets/Images/star.png';
import lightStar from './../../Assets/Images/star-light.png';
import Slider from "react-slick";
import ReactImageMagnify from 'react-image-magnify';
import Plus from './../../Assets/Images/Plus.png';
import Minus from './../../Assets/Images/Minus.png';
import Pin from './../../Assets/Images/location.png';

import { toast } from 'react-toastify';
import validator from 'validator';
import { useLocation, useNavigate } from "react-router-dom";
import { getProductDispatch, resetProduct, setPickedColorProducts, setProductToBeMain } from "../Home/Slices/Product.slice";
import {useDispatch, useSelector} from "react-redux"
import { checkPromoCode, fetchCityFromPinCode, postAddToCart } from "../../utility/service";
import { setAuth } from "../Authentications/Login/slice.reducer";
import { updateProfile } from "../Pro/profile.slice";
import { deviceType } from 'react-device-detect';

import product1 from './../../Assets/Images/product1.jpg';
import product2 from './../../Assets/Images/product2.jpg';
import product3 from './../../Assets/Images/product3.jpg';
import product4 from './../../Assets/Images/product4.jpg';
import product5 from './../../Assets/Images/product5.jpg';
import product6 from './../../Assets/Images/product6.jpg';

import Cart from './../../Assets/Images/cart.png';
import ProductsList from "../Home/Section/OnlineStore/ProductsList";
import { setScrollToOnlineStoreDispatch } from "../Home/Slices/scrollManagment.slice";
import { magnify } from "../../utility/helper";

export default ()=>{
    
    const [enableCartButton, setEnableCartButton] = useState(false);  // <-- color to selecte
    const [isColor, setIsColor] = useState("");  // <-- color to selecte
    const [isLabel, setIsLabel] = useState("Standard Size");  // <-- size
    let [count, setCount] = useState(1);
    let [pinCode, setPincode] = useState();
    const [open, setOpen] = useState('1');
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
    // const nav1=React.useRef();
    // const nav2=React.useRef();    

    const [isActiveTab, setIsActiveTab] = useState('1');
    
    const [isShow, setIsShow] = useState(true);

    const navigate = useNavigate();
    const dispatch= useDispatch();
    const profile= useSelector(state=>state.profile);
    const product= useSelector(state=>state.Product);
    let productCategories= useSelector(state=>state.ProductCategory);
    const auth = useSelector(state => state.auth)
    const scrollManagment= useSelector(state=>state.scrollManagment);
    const [productCategorie, setProductCategorie] = useState({});
    const [childProducts, setChildProducts] = useState([]);
    const [productDetails, setProductDetails] = useState({});
    const [colorWithImages, setColorWithImages] = useState([]);
    const [cityFromPinCode, setCityFromPinCode] = useState("");
    
    const [isPromoAvailiable, setIsPromoAvailiable] = useState(false);
    const [Coupon, setCoupon] = useState('')
    const [userStatus, setUserStatus] = useState({
        isPremium: false,
        isFreemium: false,
        isNormal: false,
    })

    let {state}  = useLocation();
    
    const [productInfo, setProductInfo] = useState(state);
    const [freePre, setFreePre] = useState("");
    const [whichTypeIsAvailiable, setWhichTypeIsAvailiable] = useState({
        isPremium: false,
        isFremium: false
    });
    const cart= useSelector(state=>state.cart);
    const [totalQuantityOfCardProduct, setTotalQuantityOfCardProduct] = useState(0);

    useEffect(()=>{

        setTimeout(() => {
            async function storeItemInlocalStorage(){

                let item = JSON.parse(localStorage.getItem("item"));
                let getLocalproductCategories = JSON.parse(localStorage.getItem("productCategories"));

                if(item !== null && productInfo === null){
                    await localStorage.setItem("item", JSON.stringify({
                        ...item
                    }));
                    setProductInfo(item);
                    // productCategories =getLocalproductCategories;
                    setProductCategorie(getLocalproductCategories.catagories.filter(cat=>cat.CategoryID === item.CategoryID)[0]);
                    // console.log("getLocalItemgetLocalItem 2", getLocalproductCategories)
                }else{
                    // console.log("getLocalItemgetLocalItem 3", productInfo)
                    await localStorage.setItem("item", JSON.stringify({
                        ...productInfo
                    }))
                    await localStorage.setItem("productCategories", JSON.stringify({
                        ...productCategories
                    }))
                }
            }    
            storeItemInlocalStorage();
        }, 0);
        console.log('itemitem 2', cart) 
    }, []);  

    useEffect(()=>{
        let totalQantity= 0;
        if(cart && cart.item.length >0){
            cart.item.forEach(item=>{
                totalQantity= totalQantity+item.QTY
            });
        }
        setTotalQuantityOfCardProduct(totalQantity);
    }, [cart.item.length])
    // console.log("productCategorie 22", productCategorie)
    console.log("getLocalIddtemgetLocalItem 8", productCategorie, productDetails, productInfo)
    
    useEffect(()=>{
        dispatch(getProductDispatch(productInfo, 1, 10, -1));
    },[productInfo]);
    
    console.log("setWhichTypeIsAvailiable 8", whichTypeIsAvailiable)
    
    useEffect(()=>{
        if(product.isResponseGet){
            if(product.pagedList!== undefined && product.pagedList.length >0){
                setChildProducts(product.childList);  // --> This is child products
                setProductDetails(product.pagedList[0]);  // --> This is main product
                
                setWhichTypeIsAvailiable({
                    isPremium: product.pagedList[0].AvailablePreemiumCount !== null && product.pagedList[0].AvailablePreemiumCount !== "0",
                    isFremium: product.pagedList[0].AvailableFreemiumCount !== null && product.pagedList[0].AvailableFreemiumCount !== "0"
                })

                console.log("setWhichTypeIsAvailiable 8", product.pagedList[0].AvailablePreemiumCount !== null)

                // if(product.pagedList[0].Colors.split(",").length >0){
                //     setIsColor(product.pagedList[0].Colors.split(",")[0]);
                // }
                let getLocalproductCategories = JSON.parse(localStorage.getItem("productCategories"));
                if(getLocalproductCategories === null){
                    console.log("getLocalItemgetLocalItem 5", productCategories, product.pagedList[0])
                    setProductCategorie(productCategories.catagories.filter(cat=>cat.CategoryID === product.pagedList[0].CategoryID)[0]); // --> To get Category of this product
                }else{
                    if(productCategories.catagories.length >0){
                        console.log("getLocalItemgetLocalItem 8", productCategories, product.pagedList[0])
                        setProductCategorie(productCategories.catagories.filter(cat=>cat.CategoryID === product.pagedList[0].CategoryID)[0]);
                    }
                }

                if(product.pagedList[0].ColorWithImages!== undefined && product.pagedList[0].ColorWithImages.length >0){
                    setColorWithImages(product.pagedList[0].ColorWithImages); // --> To get all slider products
                }
            }
        }
    }, [product]);   
   
    useEffect(()=>{
        if(product.pagedList !== undefined && product.pagedList[0].Colors.split(",").length >0){
            setIsColor(product.pagedList[0].Colors.split(",")[0]); // --> To get color selected only first time
        }
    }, [product.pagedList[0].Colors]);
    
    const getColors = () => {
        if(productDetails.Colors !== undefined && productDetails.Colors !== ""){
            return productDetails.Colors.split(",");
        }
        return []
    }

    useEffect(()=>{
        if(product.pickedSliderProducts.length >0){

            if(deviceType === "browser"){
                magnify(product.pickedSliderProducts[0].Image, 2);
                setTimeout(() => {
                    const magnifyDiv = document.getElementById(product.pagedList[0].Image+"glass");
                    if(magnifyDiv) document.getElementById(product.pagedList[0].Image+"glass").style.visibility = 'hidden';
                }, 200);
            }
        }
    }, [product.pickedSliderProducts.length]);
    
    const onMouseLeavveFromSlider = () => {
        const magnifyDiv = document.getElementById(product.pagedList[0].Image+"glass");
        if(magnifyDiv) document.getElementById(product.pagedList[0].Image+"glass").style.visibility = 'hidden';
    }
    
    const onMouseMoveFromSlider = () => {
        const magnifyDiv = document.getElementById(product.pagedList[0].Image+"glass");
        if(magnifyDiv) document.getElementById(product.pagedList[0].Image+"glass").style.visibility = 'visible';
    }

    useEffect(()=>{
        
        if(deviceType === "browser"){
            const upperSliderContainer = document.getElementById('upper-slider_container');
            if(product.pickedSliderProducts.length >0){
                
                upperSliderContainer.addEventListener('mouseleave', onMouseLeavveFromSlider);
                upperSliderContainer.addEventListener('mousemove', onMouseMoveFromSlider);
                
                return ()=>{
                    upperSliderContainer.removeEventListener('mouseenter', onMouseLeavveFromSlider);
                    upperSliderContainer.removeEventListener('mousemove', onMouseMoveFromSlider);
                }
            }
        }

    }, [product])

    useEffect(()=>{
        return ()=> {
            dispatch(resetProduct());
        }
    }, []);
    
    function handleInputChange(event) {
        
        const magnifyDiv = document.getElementById(product.pagedList[0].Image+"glass");
        if(magnifyDiv) magnifyDiv.remove();
        
        let getColoredProduct= [];
        
        setTimeout(() => {
          
            setIsColor(event.target.value);
            getColoredProduct =colorWithImages.filter(CWI=> CWI.Color === `#${event.target.value}`);
            dispatch(setPickedColorProducts({getColoredProduct, currentSliderindex: currentSliderIndex})) // This will change the main product, and slider product   
            
            if(deviceType === "browser"){
                setTimeout(() => {
                    magnify(getColoredProduct[currentSliderIndex].Image, 2);
                    const magnifyDiv = document.getElementById(getColoredProduct[currentSliderIndex].Image+"glass");
                    if(magnifyDiv) document.getElementById(getColoredProduct[currentSliderIndex].Image+"glass").style.visibility = 'hidden';
                }, 400);
            }

        }, 200);
        
    }
    
    function onSliderHandler(index) {

        setCurrentSliderIndex(index);
        let getThisIndexProdut =[];
        getThisIndexProdut= product.pickedSliderProducts.filter((product, idx)=> idx === index);

        if(deviceType === "browser"){
            setTimeout(() => {
                magnify(getThisIndexProdut[0].Image, 2);
                const magnifyDiv = document.getElementById(getThisIndexProdut[0].Image+"glass");
                if(magnifyDiv) document.getElementById(getThisIndexProdut[0].Image+"glass").style.visibility = 'hidden';
            }, 400);
        }

        dispatch(setProductToBeMain(getThisIndexProdut));

    }
    
    const getUpperSlider = () => {

        if(product.pickedSliderProducts.length >0){
           return product.pickedSliderProducts.map((img, index)=>{
                return <div>
                            {/* <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: 'Wristwatch by Versace',
                                        isFluidWidth: true,
                                        src: img.Image,
                                        
                                    },
                                    largeImage: {
                                        src: img.Image,
                                        width: 1426,
                                        height: 2000
                                    },
                                }}
                            /> */}
                            <div class="img-magnifier-container" >
                                <img id={img.Image} src={img.Image} width="600" height="600" onLoad={()=>onloaddd(img)}/>
                            </div>
                    </div> 
            })
        }
    }

    const onloaddd = (img) => {
        console.log("magnifyDivmagnifyDiv 8",img);
    }
    
    const getBottomSlider = () => {
        if(product.pickedSliderProducts.length >0){
            return product.pickedSliderProducts.map((img, index)=>{
                return  <div className="thumb-item" key={index}>
                <img src={img.Image} alt="product" className="w-100 img-fluid" />
            </div>
            })
        }
    }

    const getChildProductsListingView = () => {
        
        return childProducts.map((CP, index)=>{
            return <Col sm={6} md={4} lg={3} className={`wow  animate__zoomIn ${isShow && 'showItem'}`} data-wow-duration="2s"  key={index}>
                 <ProductsList
                    item={CP}
                    onClick={async()=>{
                        
                        setCurrentSliderIndex(0);
                        dispatch(resetProduct());
                        dispatch(getProductDispatch(CP, 1, 10, -1));
                        window.scrollTo(0, 0)
                        await localStorage.setItem("item", JSON.stringify({
                            ...CP
                        }))
                    }}
                />
        </Col>  
        })

    }

    function handlePincode(event) {
        setPincode(parseInt(event.target.value));
    }
    
    const addToCart = async() => {

        console.log('addToCartaddToCart 1', productDetails)

        toast.dismiss();
        if(auth.data === null){
            toast.error("Please login first", { });
            return;
        }

        // if(!enableCartButton){
        //     toast.error("Please Verify the pin code", { });
        //     return;
        // }

        if(isLabel!== undefined && validator.isEmpty(isLabel.trim())){
            toast.error("Please Select size", { });
            return;
        }
        
        if(freePre === ""){
            toast.error("Please Select Type", { });
            return;
        }

        if(freePre === "Preemium" && productDetails.AvailablePreemiumCount < (count)){
            toast.dismiss();
            toast(`Only ${productDetails.AvailablePreemiumCount == 1 ?  productDetails.AvailablePreemiumCount+' tag is': productDetails.AvailablePreemiumCount+' tags are'} available`, { });
            return
        }
        console.log("incrementCountffff 1", totalQuantityOfCardProduct)
        if((totalQuantityOfCardProduct ) >= 9){
            toast.dismiss();
            toast('You cannot purchase more than 9 tags per order', { });
            return
        }
        
        let addTocartData ={
            UserID: auth.data.ID,
            CategoryID: productDetails.CategoryID,
            ProductID: productDetails.ProductID,
            QTY: count,
            Color: productDetails.Color,
            Size: productDetails.Sizes,
            PromoCode: Coupon,
            // DestinationPinCode: pinCode
            DestinationPinCode: "456010",
            LastSlectedProductType: freePre,
        }
        
        try{
            let res= await postAddToCart(addTocartData, dispatch);
            
            if(res.HTTPStatusCode !== undefined && res.HTTPStatusCode ===400){
                toast.error(res.Error.Message, { });
                return;
            }

            if(res.Success.TextMessage !== null){
                toast.error(res.Success.TextMessage, { });
                return;
            }

            navigate("/cart", {state: {freePre}});
        }catch (err){ 
        }
    }
    
    const checkPinCodeAndGetCity = async() => {

        if(cityFromPinCode){
            setCityFromPinCode("");
            setPincode("");
            setEnableCartButton(false)
            return;
        }

        if(!pinCode){
            toast.dismiss()
            toast.error("Please Enter pincode", { });
            return;
        }
        
        let data ={
            pincode: pinCode
        }
        try{
            let getCityFromPinCode= await fetchCityFromPinCode(data, dispatch);
            console.log("getCityFromsdsdPinCode 2", getCityFromPinCode);

            if(getCityFromPinCode !== undefined && getCityFromPinCode.results !== undefined && getCityFromPinCode.results[pinCode] !== undefined && getCityFromPinCode.results[pinCode].length>0){
                setCityFromPinCode(getCityFromPinCode.results[pinCode][0].city)
                setEnableCartButton(true);
                toast("Congratulations! we are delighted to serve at your location");
                dispatch(updateProfile({pinCode: getCityFromPinCode.results[pinCode][0].city}))
            }else{
                toast.warning("Not deliverable!")
            }
            
        }catch (err){ 
        }
        
    }
    
    const applyPromoCodeHandler =async (e)=>{
        
        e.preventDefault();
        console.log('preventDefaultpreventDefault', Coupon)
       
        toast.dismiss()

        if(auth.data === null){
            toast.error("Please login first", { });
            return;
        }

        if(cityFromPinCode === ""){
            toast.error("Please Enter Valid Pin Code First", { });
            return;
        }

        // if(!Coupon){
        //     toast.error("Please Enter Valid Promo code", { });
        //     return;
        // }

        // if(isPromoAvailiable){
            let addTocartData ={
                UserID: profile.details.UserId,
                CategoryID: productDetails.CategoryID,
                ProductID: productDetails.ProductID,
                QTY: count,
                Color: productDetails.Color,
                Size: productDetails.Sizes,
                // PromoCode: Coupon
            }
            try{
                let res= await postAddToCart(addTocartData, dispatch);

                if(res.HTTPStatusCode !== undefined && res.HTTPStatusCode ===400){
                    toast.error(res.Error.Message, { });
                    return;
                }

                console.log("res.Success[0] is res 1", res.Success)

                if(res.Success.TextMessage !== null && res.Success.TextMessage !== "Cogratulation you have purchased Freemium tag."){
                    toast.error(res.Success.TextMessage, { });
                    return;
                }

                // if(userStatus.isFreemium){
                //     navigate('/profile', {state: {showOrderSummary: true}});
                // }else{
                    navigate("/cart")
                // }

                return;
            }catch (err){ 
            }
        // }



        // here we are checking if promo-code availiable or not

        // let promoCodeData ={
        //     PromoCode: Coupon,
        //     ProductID: productDetails.ProductID,
        //     CategoryID: productDetails.CategoryID,
        //     Color: productDetails.Color
        // }

        // try{
        //     let checkIsPromoAvailiable= await checkPromoCode(promoCodeData, dispatch);

        //     if(checkIsPromoAvailiable.HTTPStatusCode !== undefined && checkIsPromoAvailiable.HTTPStatusCode ===400){
        //         toast.error(checkIsPromoAvailiable.Error.Message, { });
        //         return;
        //     }

        //     console.log("res.Success[0] is res 2", checkIsPromoAvailiable.Success[0])

        //     if(checkIsPromoAvailiable.Success[0].TextMessage !== null && checkIsPromoAvailiable.Success[0].TextMessage !== "Valid Promo Code"){
        //         toast.error("Invalid Coupon Code", { });
        //         return;
        //     }

        //     if(checkIsPromoAvailiable.Success.length >0){
        //         checkIspremiumOrFreemium(checkIsPromoAvailiable.Success[0])
        //     }
        //   }catch (err){
            
        //   }
    }

    const checkIspremiumOrFreemium = (res)=>{

        console.log("checkIspremiumOrFreemiumcheckIspremiumOrFreemium", res)

        if(res.IsCodeFreemium === "true"){
            setUserStatus({...userStatus, isFreemium: true})
            setIsPromoAvailiable(true);
        }else if(res.IsCodePreemium === "true"){
            setUserStatus({...userStatus, isPremium: true})
            setIsPromoAvailiable(true);
        }else{
            setUserStatus({...userStatus, isNormal: true})
        }

    }
    
    const onInputChange = (e)=>{
        
        switch(e.target.name){
            case "Coupon":
                setCoupon(e.target.value);
                break;
                default:   
            }
    }
    
    var productSlider = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 1,
        speed: 500,
        slidesToScroll: 1
    }
    
    var thumbSlider = {
        dots: true,
        infinite: false,
        arrows: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        speed: 500,
        focusOnSelect:true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
              }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    }
    
    const getRating = (data) => {
        var numberOfCards = 5;
        var cards = [];
        if (data <= numberOfCards) {
            for (var i = 0; i < numberOfCards; i++) {
                if(i < data){
                    cards[i] = (Star);
                }else{
                    cards[i] = (lightStar);
                }
            }
        }
        return cards;
    }
 
    function handleInputChangeLabel(event) {
        setIsLabel(event.target.value);
    }
    function incrementCount() {

        if(freePre === ""){
            toast.dismiss();
            toast.error("Please Select Type", { });
            return;
        }
        if(freePre === "Freemium" && count +1 > 1){
            toast.dismiss();
            toast('You cannot purchase more than 1 Freemium tags', { });
            return
        }
        console.log("incrementCount 1", totalQuantityOfCardProduct, count +1)
        if((totalQuantityOfCardProduct + (count +1)) > 9){
            toast.dismiss();
            toast('You cannot purchase more than 9 tags per order', { });
            return
        }
        if(productDetails.AvailablePreemiumCount < (count +1)){
            toast.dismiss();
            toast(`Only ${productDetails.AvailablePreemiumCount == 1 ?  productDetails.AvailablePreemiumCount+' tag is': productDetails.AvailablePreemiumCount+' tags are'} available`, { });
            return
        }
        setCount(count = count + 1);
    }
    function handleQtyChange(event) {
        setCount(parseInt(event.target.value));
    }

    useEffect(()=>{
        if(productDetails.AvailableQTY === 0) return;
        if(productDetails.AvailableQTY < count){
            toast.dismiss()
            toast.error(`Only ${productDetails.AvailableQTY} stock(s) available!`, { });
        }
    }, [count])

    function decrementCount() {
        if(count > 1){
            setCount(count=> count - 1);
        }
    } 

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos-offset="200" data-aos="zoom-in-up" data-aos-delay="20">Products</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-4 mt-2 pb-3" data-aos-offset="200" data-aos="fade-in-right" data-aos-delay="20">
                    <Container>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/" 
                                onClick={(e)=>{
                                    e.preventDefault();
                                    navigate('/');
                                    dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1, productDetails.CategoryID));
                                }}
                            >Home</a></BreadcrumbItem>
                            {/* <BreadcrumbItem><a href="#" onClick={()=> navigate('/products')}>Products</a></BreadcrumbItem> */}
                            <BreadcrumbItem active>{productDetails.Name !== undefined && productDetails.Name}</BreadcrumbItem>
                        </Breadcrumb>
                    </Container>
                </section>
                <section className="pt-0">
                    <Container>
                        <Row>
                            {product.pickedSliderProducts.length >0 && <Col lg={6} xlg={7} className="pb-5 pb-lg-0">
                                <div id="upper-slider_container" data-aos="zoom-in-right" data-aos-delay="100" className="product-main-image">
                                    <Slider 
                                        asNavFor={nav2} 
                                        // ref={nav1}
                                        ref={(slider1) =>{
                                            console.log("refref 1", nav1)
                                            setNav1(slider1)
                                        }}
                                        afterChange={(index)=>{
                                            onSliderHandler(index);
                                        }}
                                        beforeChange={(oldIndex)=>{
                                            const magnifyDiv = document.getElementById(product.pickedSliderProducts[oldIndex].Image+"glass");
                                            if(magnifyDiv) magnifyDiv.remove();
                                        }}
                                        {...productSlider}
                                    >
                                        {getUpperSlider()}
                                    </Slider>
                                </div>
                                <div className="product-thumbnail">
                                <Slider 
                                    asNavFor={nav1}
                                    // ref={nav2}
                                    ref={(slider2) => {
                                        console.log("refref 2", nav2)
                                        setNav2(slider2)
                                    }}
                                    {...thumbSlider}
                                >
                                        {getBottomSlider()}
                                    </Slider>
                                </div>
                            </Col>}
                            
                            <Col lg={6} xl={5} className=" ps-lg-5 product-detail-content"  data-aos="zoom-in-left" data-aos-delay="200">
                                <h4>{productDetails.Name !== undefined && productDetails.Name}</h4>
                                <div data-aos="zoom-in-left" data-aos-delay="250">
                                    <Rating
                                        classes= "rating"
                                        // rating= {getRating(product.rating)}
                                        rating= {getRating(4)}
                                        totalReviews= {150}
                                    /> 
                                </div>
                                
                                <div className="product-price mt-2 fw-500">
                                    <span className="sale-price">INR : {productDetails.OfferPrice !== undefined && productDetails.OfferPrice}</span>
                                    <span className="text-decoration-line-through text-disabled ms-3">INR : {productDetails.MRP !== undefined && productDetails.MRP}</span>
                                </div>
                                
                                {/* still yet to complete */}
                                <div className="mt-3">
                                    <label className="product-detail-label">Color</label>
                                    <FormGroup tag="fieldset" className="d-flex flex-wrap color-options">
                                        {getColors().map((item, index) => (
                                            <FormGroup check key={index}>
                                                <Label className={isColor === item && 'active'} check style={{background: `#${item}`}}>
                                                    <Input 
                                                        type="radio" 
                                                        name="color" 
                                                        value={item}
                                                        checked={isColor === item}
                                                        // onChange={()=>handleInputChange(item) } 
                                                        onChange={handleInputChange} 
                                                    />
                                                </Label>
                                            </FormGroup>
                                        ))
                                    }
                                        {/* {productDetails.Image} */}
                                    </FormGroup>
                                </div>

                                {/* still yet to complete */}
                                <div className="mt-3">
                                    <label className="product-detail-label">Size</label>
                                    <FormGroup tag="fieldset" className="d-flex flex-wrap label-options">
                                        {productDetails.Sizes !==undefined && productDetails.Sizes.split().map((item, index) => (
                                        // {variables.size.map((item, index) => (
                                            <FormGroup check key={index}>
                                                <Label className={isLabel === item && 'active'} check >
                                                    <Input
                                                        type="radio" 
                                                        name="size" 
                                                        value={item}
                                                        checked={isLabel === item}
                                                        onChange={handleInputChangeLabel} 
                                                    />
                                                    <span className="fw-500">{item}</span>
                                                </Label>
                                            </FormGroup>
                                        ))
                                        }
                                        
                                    </FormGroup>
                                </div>

                                <div className="mt-3">
                                    <label className="product-detail-label">Category</label>
                                    <div className="text-disabled text-black fw-500">{productCategorie !== undefined && productCategorie.Name!==undefined&& productCategorie.Name}</div>
                                </div>

                                <div className="mt-3">
                                    <label className="product-detail-label">Quantity</label>
                                    <div className="d-flex flex-wrap ">
                                        <div className="quantity-box d-flex mt-2 me-3">
                                            <button className="sub" onClick={decrementCount}>
                                                <img src={Minus} alt="minus" />
                                            </button>
                                            {/* <Input 
                                               
                                            /> */}
                                            <input  
                                                type="number" 
                                                name="quantity" 
                                                value={count}
                                                onChange={handleQtyChange } 
                                            />
                                            <button className="add" onClick={incrementCount}>
                                                <img src={Plus} alt="plus" />
                                            </button>
                                        </div>
                                       {/* {(!isPromoAvailiable && productDetails.AvailableQTY> 0) && (productDetails.AvailableQTY+1 > count) && <div className="p-0 coupon-form mt-2">
                                            <form className="" 
                                                onSubmit={applyPromoCodeHandler}
                                            >
                                                <input type={'text'} className={'form-control'} name={'Coupon'} placeholder="PROMO CODE" value={Coupon}
                                                    onChange={onInputChange} disabled={isPromoAvailiable} />
                                                 <button 
                                                    type="submit"  
                                                    className="btn btn-primary"
                                                >Apply</button>
                                            </form>
                                        </div>} */}
                                    </div>
                                </div>


                                {(whichTypeIsAvailiable.isFremium || whichTypeIsAvailiable.isPremium) && <div className="mt-3">
                                    <label className="product-detail-label">Select Type</label>
                                    <div className="text-disabled text-black fw-500">
                                        <div>
                                            <input type="radio" name="freePre" value={"Freemium"} onChange={(e)=>{
                                                if(!whichTypeIsAvailiable.isFremium){
                                                    toast("Freemium is not availiable", {});
                                                    return;
                                                }
                                                setCount(1)
                                                setFreePre(e.target.value)
                                            }} checked={freePre== "Freemium"} /> <span>Freemium </span>
                                        </div>

                                        <div>
                                            <input type="radio" name="freePre" value={"Preemium"} onChange={(e)=> {
                                                 if(!whichTypeIsAvailiable.isPremium){
                                                    toast("Premium is not availiable", {});
                                                    return;
                                                }
                                                setFreePre(e.target.value)
                                            }} checked={freePre== "Preemium"} /> <span>Premium</span>
                                        </div>
                                    </div>
                                </div>}


                                {isPromoAvailiable && productDetails.AvailableQTY> 0 && <div className="white-box mt-3">
                                    <Row>
                                        <Col className="text-success">
                                            {Coupon} promocode applied successfully!
                                        </Col>
                                    </Row>
                                </div>}
                               
                                {productDetails.AvailableQTY === 0 && <div className="white-box mt-3">
                                    <Row>
                                        <Col style={{color: "red"}}>
                                                Out of stock!
                                        </Col>
                                    </Row>
                                </div>}

                                {(!userStatus.isFreemium && productDetails.AvailableQTY> 0) && (productDetails.AvailableQTY +1 > count) && <div className="mt-3">
                                    <label className="product-detail-label">Deliver to</label>
                                    <div className="d-flex flex-wrap ">
                                        <div className="pin-box d-flex mt-2 me-3">
                                            <button>
                                                <img src={Pin} alt="pin" />
                                            </button>
                                            {/* <Input 
                                               
                                            /> */}

                                            <input  
                                                //  type="number" 
                                                 type={cityFromPinCode ?"text": "number"}
                                                 disabled={cityFromPinCode ?true: false}
                                                 name="pincode" 
                                                 placeholder="Enter Pin Code"
                                                 onChange={ handlePincode } 
                                                //  value={ pinCode } 
                                                 value={ cityFromPinCode ?cityFromPinCode: pinCode } 
                                            />
                                        </div>
                                        <button 
                                            className="btn btn-outline-secondary mt-2 bw-2" 
                                            onClick={checkPinCodeAndGetCity}
                                        >{cityFromPinCode ? "Change" :"Check"}</button>
                                    </div>
                                </div>}

                                {(!userStatus.isFreemium && productDetails.AvailableQTY> 0) && (productDetails.AvailableQTY +1 > count) && <div className="mt-3">
                                    <button 
                                        className="btn btn-outline-secondary mt-2 me-3 bw-2"
                                        onClick={addToCart}
                                        // disabled= {!enableCartButton}
                                    >Add to cart</button>
                                </div>}

                                {userStatus.isFreemium && <div className="mt-3">
                                    <button 
                                        className="btn btn-primary mt-2 bw-2"
                                        onClick={applyPromoCodeHandler}
                                    >Buy Now</button>
                                </div>}
                                
                               


                                {/* <button 
                                    className="btn btn-primary mt-2 bw-2"
                                >Buy Now</button> */}
                                {/* <div className="mt-3">
                                    <label className="product-detail-label">Deliver to</label>
                                    <div className="d-flex flex-wrap ">
                                        <div className="pin-box d-flex mt-2 me-3">
                                            <button>
                                                <img src={Pin} alt="pin" />
                                            </button>

                                            <input  
                                                //  type="number" 
                                                 type={cityFromPinCode ?"text": "number"}
                                                 disabled={cityFromPinCode ?true: false}
                                                 name="pincode" 
                                                 placeholder="Enter Pin Code"
                                                 onChange={ handlePincode } 
                                                //  value={ pinCode } 
                                                 value={ cityFromPinCode ?cityFromPinCode: pinCode } 
                                            />
                                        </div>
                                        <button 
                                            className="btn btn-outline-secondary mt-2 bw-2" 
                                            onClick={checkPinCodeAndGetCity}
                                        >{cityFromPinCode ? "Change" :"Check"}</button>
                                    </div>
                                </div> */}

                                <div className="mt-3">
                                    <Accordion flush open={open} toggle={toggle}>
                                        <AccordionItem>
                                            <AccordionHeader targetId="1">Product Description</AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <div dangerouslySetInnerHTML={{__html: productDetails.Description !==undefined && productDetails.Description}}></div>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="2">Features</AccordionHeader>
                                            <AccordionBody accordionId="2">
                                                <div dangerouslySetInnerHTML={{__html: productDetails.Features !==undefined && productDetails.Features}}></div>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="pt-0">
                    <Container>
                        <hr className="bw-2 mb-4" />
                        <div className="related-title" data-aos="zoom-in-left" data-aos-delay="110">
                            <span className="fw-600">Related Items</span>
                        </div>
                       <div className="mt-4 mt-md-5">
                       <Row>
                            {getChildProductsListingView()}
                            
                            {childProducts.length ===0 && <p className="text-disabled text-center p-4">No product in this category</p> }
                        </Row>
                       </div>
                    </Container>
                </section>
                
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 