import React, { useState ,useEffect} from "react";
import {  Nav, NavItem, NavLink, Row, Col, Container} from 'reactstrap';
import Logo from './../../Assets/Images/logo.png';

import { useLocation } from 'react-router-dom';
import Visa from './../../Assets/Images/visa.png';
import Master from './../../Assets/Images/master.png';
import PayPal from './../../Assets/Images/paypal.png';

import App from './../../Assets/Images/app.png';
import Play from './../../Assets/Images/google-play.png';

import Facebook from './../../Assets/Images/fb.png';
import Instagram from './../../Assets/Images/ins.png';
import Twitter from './../../Assets/Images/tw.png';
import Youtube from './../../Assets/Images/YT.png';
import { useNavigate } from 'react-router-dom'

export default ()=>{
    
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    return (
        <React.Fragment>
            <footer className="text-start"> 
               <Container>
                    <Row>
                        <Col md={6} lg={3} data-aos="zoom-in" data-aos-delay="50">
                            <a href="/">
                                <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                            </a>
                            <div className="footer-payment">
                                <h5 className="mt-4 mb-1">We Accept</h5>
                                <img src={Visa} className="m-1 img-fluid" alt="Visa" />
                                <img src={Master} className="m-1 img-fluid" alt="Master" />
                                <img src={PayPal} className="m-1 img-fluid" alt="PayPal" />
                            </div>
                        </Col>
                        <Col md={6} lg={3} data-aos="zoom-in" data-aos-delay="100">
                            <h5>Products & Services</h5>
                            <Nav className="me-0 ms-0 flex-column">
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/innovation')}>Why This Innovation</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/become-a-franchise')}>Become a Franchise </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/business-associate-program')}>Business Associate Program</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/career')}>Careers</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md={6} lg={3} data-aos="zoom-in" data-aos-delay="150">
                            <h5>Important Links</h5>
                            <Nav className="me-0 ms-0 flex-column">
                                <NavItem>
                                    <NavLink href="#"  onClick={()=> navigate('/privacy-policy')}>Privacy Policy</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"  onClick={()=> navigate('/terms-of-use')}>Terms of Use</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/membership')}>Membership Plans</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={()=> navigate('/support')}>Support</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md={6} lg={3} data-aos="zoom-in" data-aos-delay="200">
                            <div className="footer-apps">
                                <h5 className="mb-2">Download our App</h5>
                                <a href="#" target="_blank">
                                    <img src={App} className="img-fluid" alt="App Store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.intello.tagss&pli=1" target="_blank">
                                    <img src={Play} className="img-fluid" alt="Play Store" />
                                </a>
                            </div>
                            <div className="footer-Social">
                                <h5 className="mt-4 mb-2">Follow us @intellotags</h5>
                                <a href="https://www.facebook.com/IntelloTagss?mibextid=ZbWKwL" target="_blank">
                                    <img src={Facebook} className="img-fluid" alt="Facebook" />
                                </a>
                                <a href="https://www.instagram.com/intellotagss/?igshid=OGQ5ZDc2ODk2ZA==" target="_blank">
                                    <img src={Instagram} className=" img-fluid" alt="Instagram" />
                                </a>
                                <a href="https://twitter.com/IntelloTagss?t=wqblBO9twhXWRWGeNRPsww&s=08" target="_blank">
                                    <img src={Twitter} className=" img-fluid" alt="Twitter" />
                                </a>
                                <a href="https://youtube.com/@IntelloTagss" target="_blank">
                                    <img src={Youtube} className="img-fluid" alt="Youtube" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <div className="copyright bg-primary text-center p-4 text-light" > 
                <p className="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p>
            </div>
        </React.Fragment>
    )
} 