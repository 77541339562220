import React, { useEffect, useState } from "react"; 
import Header from './../../../Components/Header';
import {Container, Row, Col,Breadcrumb, BreadcrumbItem, NavLink} from 'reactstrap'
import Image from './../../../Assets/Images/product1.jpg'
import Plus from './../../../Assets/Images/Plus.png';
import Minus from './../../../Assets/Images/Minus.png';
import {useDispatch, useSelector} from "react-redux"
import { getCartItemsDispatch, inDecreamentCartItemsDispatch, removeCartItemDispatch, resetCartItem } from "../../Home/Slices/cart.slice";
import { checkPromoCode, checkAvailibilityOfProduct, fetchOTP, placeOrder, postAddToCart } from "../../../utility/service";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useNavigate, useLocation } from "react-router-dom";
import ManageAddress from "../ManageAddress";
import { toast } from 'react-toastify';
import { setScrollToOnlineStoreDispatch } from "../../Home/Slices/scrollManagment.slice";

function Cart() {
    
    let {state}  = useLocation();
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const cart= useSelector(state=>state.cart);
    const profile= useSelector(state=>state.profile);
    const [OTPmodal, setOTPModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [shippingAdd, setShippingAdd] = useState("");
    const [pinCode, setPinCode] = useState(profile.details.pinCode);
    const [billingAdd, setBillingAdd] = useState("");
    const [addType, setAddType] = useState("Home");
    const [OTP, setOTP] = useState("");
    const [otherInfo, setOtherInfo] = useState({
        TotalAmount: 0,
        TotalAmountMinusingFirstPurchaseDiscount: 0,
        TotalQTY: 0,
    });

    const [isPromoAvailiable, setIsPromoAvailiable] = useState(false);
    const [Coupon, setCoupon] = useState('')
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [promoCodeRes, setPromoCodeRes] = useState("")
    const [shouldBuy, setShouldBuy] = useState(true)
    const [userStatus, setUserStatus] = useState({
        isPremium: false,
        isFreemium: false,
        isNormal: false,
    })
    const scrollManagment= useSelector(state=>state.scrollManagment);
    // const [isPromoAvailiable, setIsPromoAvailiable] = useState(false);

    let isFreemium= false;
    if(state && state.freePre && state.freePre === "Freemium"){
        isFreemium= true;
    }
    const [totalQuantityOfCardProduct, setTotalQuantityOfCardProduct] = useState(0);
    
    const getPromoCodeAmount= ()=>{
        let value= 0;
        console.log("promoCodeRes.PromoCodeOf",promoCodeRes.PromoCodeOf, promoCodeRes)
        return 5;
    }
    
    const CreateToastView= ({item})=>{
        return <div>{item.ProductName} with color <div style={{background: item.Color, height: '15px', width: '15px', display: 'inline-block' }}></div> Out of stock, Please remove this item.</div>
    }

    const toggle=async ()=>{

        // if(isFreemium){
        //     toast.dismiss()
        //     toast("Please Enter Valid Promo-Code", { });
        //     return;
        // }

        let isCountZero= false;

        console.log('itemitem 4', cart)

        for (let i = 0; i < cart.item.length; i++) {
            const item = cart.item[i];

            if (item.AvailableFreemiumCount === '0' && item.AvailablePreemiumCount === '0') {
                isCountZero = true;
                toast.dismiss();
                toast(<CreateToastView item= {item}/>);
                break; // Exits the loop
            }
        
            if (item.AvailableFreemiumCount === '0' && item.LastSlectedProductType === 'Freemium') {
                isCountZero = true;
                toast.dismiss();
                toast(<CreateToastView item= {item}/>);
                break; // Exits the loop
            }
        
            if (item.AvailablePreemiumCount === '0' && item.LastSlectedProductType === 'Preemium') {
                isCountZero = true;
                toast.dismiss();
                toast(<CreateToastView item= {item}/>);
                break; // Exits the loop
            }
        }

        if(isCountZero){
            return;
        }

        let totalPremiumCount= 0;
        for (let i = 0; i < cart.item.length; i++) {
            const item = cart.item[i];
            totalPremiumCount= totalPremiumCount+ parseInt(item.AvailablePreemiumCount)
        }

        if(Coupon !== "FreeIntelloTag" && totalPremiumCount===0){
            toast.dismiss();
            toast('Premium tag is out of stock.', { });
            return;
        }

        let res= await checkPromoCodedd();
        
        if(res && Coupon !== ""){
            console.log("res= awaitres= await 11", res, Coupon)
            applyPromoCodeHandler();
        }else if(res){
            
            if(Coupon === ""){
                console.log("res= awaitres= await 10", )
                navigate("/manage-address", {state: {TotalAmountMinusingFirstPurchaseDiscount: otherInfo.TotalAmountMinusingFirstPurchaseDiscount, promoCode: Coupon}})
            }
           
        }
        
    }

    useEffect(()=>{
        if(cart.item.length > 0){
            
        const initialValue = 0;
        let sumOfTotalDiscount = 0;

        if(cart.item.length > 0 && cart.item[0].IsFirstPurchase !== "False"){
            sumOfTotalDiscount = (cart.item.reduce(
                (accumulator, currentValue) => {
                    if(currentValue.FirstPurchaseDiscountvalue){
                        return parseFloat(accumulator) + parseFloat(currentValue.FirstPurchaseDiscountvalue)
                    }else{
                        return 0;
                    }
                },
                initialValue,
            )).toFixed(2);
            setTotalDiscount(sumOfTotalDiscount)
        }

        const sumOfFreemiumCount = cart.item.reduce(
            (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.AvailableFreemiumCount),
            0,
        )

        const sumOfPreemiumCount = cart.item.reduce(
            (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.AvailablePreemiumCount),
            0,
        )

        console.log("sumOfTotalDiscountsumOfTotalDiscount", sumOfTotalDiscount)
        } 
    })
    
    useEffect(()=>{
        dispatch(resetCartItem())
        dispatch(getCartItemsDispatch());
    }, []);

    useEffect(()=>{
        let totalQantity= 0;
        if(cart && cart.item.length >0){
            cart.item.forEach(item=>{
                totalQantity= totalQantity+item.QTY
            });
        }
        setTotalQuantityOfCardProduct(totalQantity);
    }, [cart.item.length])

    useEffect(()=>{

        if(cart.isResponseGet){
            let TotalAmount = 0;
            let TotalQTY = 0;
            if(cart.item.length >0){
                cart.item.forEach(cart => {
                    TotalAmount= (+TotalAmount)+(+cart.OfferPrice);
                    TotalQTY= (+TotalQTY)+(+cart.QTY);
                });

                setOtherInfo({
                    ...otherInfo,
                    TotalAmount: parseFloat(TotalAmount).toFixed(2),
                    TotalAmountMinusingFirstPurchaseDiscountStatic: Math.round(parseFloat(TotalAmount).toFixed(2)- parseFloat(totalDiscount).toFixed(2)),
                    TotalAmountMinusingFirstPurchaseDiscount: Math.round(parseFloat(TotalAmount).toFixed(2)- parseFloat(totalDiscount).toFixed(2)),
                    TotalQTY: TotalQTY,
                })
            }
        }
    }, [cart.isResponseGet, totalDiscount]);
    
    const decrementCount=(item)=>{

        console.log('itemitem 1', cart);

        let qty =item.QTY -1;
        let data= {
            CategoryID:  parseInt(item.CategoryID),
            ProductID: parseInt(item.ProductID),
            QTY: parseInt(qty),
            Color: item.Color,
            Size: item.Size,
            LastSlectedProductType: item.LastSlectedProductType
        }
        
        dispatch(inDecreamentCartItemsDispatch(data))

        setIsPromoAvailiable(false)
        setCoupon('')
        setPromoCodeRes("")
    }

    const incrementCount= async (item)=>{

        console.log('itemitem 2', item, item.QTY, item.AvailablePreemiumCount)

        if(totalQuantityOfCardProduct > 9){
            toast.dismiss();
            toast('You cannot purchase more than 9 tags per order', { });
            return
        }

        if(item.AvailableFreemiumCount === '0' && item.AvailablePreemiumCount === '0'){
            toast.dismiss();
            toast('Out of stock', { });
            return;
        }else if(item.AvailableFreemiumCount === '0' && item.LastSlectedProductType ==='Freemium'){
            toast.dismiss();
            toast('Out of stock', { });
            return;
        }else if(item.AvailablePreemiumCount === '0' && item.LastSlectedProductType ==='Preemium'){
            toast.dismiss();
            toast('Out of stock', { });
            return;
        }

        if(item.LastSlectedProductType ==='Preemium' && item.QTY >= item.AvailablePreemiumCount){
            toast.dismiss();
            toast('You can\'t purchase more than availiable quantity ', { });
            return;
        }
        
        let qty =item.QTY+1;

        if(item.LastSlectedProductType ==='Freemium' && qty > 1){
            toast.dismiss();
            toast('You can\'t purchase more than 1 quantity ', { });
            return;
        }

        let data= {
            CategoryID:  parseInt(item.CategoryID),
            ProductID: parseInt(item.ProductID),
            QTY: parseInt(qty),
            Color: item.Color,
            Size: item.Size,
            LastSlectedProductType: item.LastSlectedProductType
        }

        // let isAvailiableInStock =await checkAvailibilityOfProduct(data, dispatch);
        console.log("incrementCountincrementCount", item.AvailiableStock)

        // if(!isAvailiableInStock.Success){
        if(item.AvailiableStock < qty ){
            alert("Stock Unavailable!");
            return;
        }
        dispatch(inDecreamentCartItemsDispatch(data))

        setIsPromoAvailiable(false)
        setCoupon('')
        setPromoCodeRes("")
    }

    const removeItemFromCart=(item)=>{
        dispatch(removeCartItemDispatch(item.ID))
        setIsPromoAvailiable(false)
        setCoupon('')
        setPromoCodeRes("")
    }
        
    const getCartItemsView = ()=>{
        if(cart.item.length >0){

            return cart.item.map((item)=>{ 
                return <tr>
                <td>
                    <div className="d-flex align-items-center">
                        <img src={item.Image} alt="product" className="img-thumb" />
                        <div>
                            <p className="mb-0">{item.ProductName}</p>

                            <div style={{
                                    "font-size": "10px",
                                    "margin-top": "5px",
                                    "color": "green",
                            }}>
                                <p className="mb-0">Available Freemium Count {item.AvailableFreemiumCount}</p>
                                <p className="mb-0">Available Preemium Count {item.AvailablePreemiumCount}</p>
                            </div>
                            <p className="mb-0 d-md-none d-table-cell">INR {item.TotalAmount}</p>
                        </div>
                    </div>
                </td>
                <td style={{padding: 0}}>
                    <small className="ms-3 ms-4 mb-0 summeryswatch" style={{background: `${item.Color}`}}></small>
                </td>
                <td className="d-md-table-cell d-none">
                    INR {item.OfferPrice}
                </td>
                <td>
                    <div className="quantity-box d-flex">
                       <button className="sub" disabled= {item.QTY === 1 || false} onClick={()=>{
                            decrementCount(item)
                       }}>
                            <img src={Minus} alt="minus" />
                        </button>
                        <input 
                            type="number" 
                            name="quantity" 
                            value={item.QTY}
                            // onChange={()=>(item)} 
                            disabled={true}
                        />
                        <button className="add" onClick={()=>{
                            incrementCount(item)
                        }}>
                            <img src={Plus} alt="plus" />
                        </button>
                    </div>
                </td>
                <td>
                    <div 
                        className="d-flex align-items-center justify-content-end cart-close-icon" 
                        style={{cursor: "pointer"}}
                        onClick={()=>removeItemFromCart(item)}
                    >
                        <img src={Plus} alt="plus" /> <span className="ms-1 d-md-block d-none">Remove</span>
                    </div>
                </td>
            </tr>
            })
           
        }
        
    }

    const getOTP = async()=>{
        
        let dataObje={
            MobileNumber: mobileNumber,
            UserID: profile.details.UserId
        }
        console.log("placePayment getOTP 6", profile.details)
        let res= await fetchOTP(dataObje, dispatch);

        
        if(!res.Success){

        }else{
            toggle()
            setAddressModal(!addressModal)
        }
    }

    const placeCartItemOrder = async()=>{

        let dataObj= {
            CheckedOutCart: cart.item,
            MobileNumber: mobileNumber,
            OTPNumber: 5040,
            address_type: addType,  // --> radio button
            pincode: pinCode,
            customername: profile.details.FirstName ,
            email: profile.details.Email,
            phone: profile.details.Phone_Mobile,
            add: shippingAdd,
            billing_address: billingAdd,
            UserID: profile.details.UserId,
            Address: shippingAdd,
            Promocode: Coupon
        }
        try{
            let res= await placeOrder(dataObj, dispatch); 
            console.log("placeOrderplaceOrder", res);
            setAddressModal(false)

        }catch(err){

        }
    }

    const checkPromoCodedd = async(e)=>{
        
        // here we are checking if promo-code availiable or not
        e && e.preventDefault();

        if((Coupon.toLowerCase()  === 'freeintellotag') && (Coupon  !== 'FreeIntelloTag')){
            toast('Please Enter Valid Promo Code')
            return;
        }
        
        let promoCodeData ={
            UserID: profile.details.UserId,
            PromoCode: Coupon,
            allCartItems: cart
            // Address: shippingAdd
        }

        try{
            let checkIsPromoAvailiable= await checkPromoCode(promoCodeData, dispatch);

            if(checkIsPromoAvailiable.HTTPStatusCode !== undefined && checkIsPromoAvailiable.HTTPStatusCode ===400){
                toast.error(checkIsPromoAvailiable.Error.Message, { });
                setIsPromoAvailiable(false);
                return false;
            }

            if( checkIsPromoAvailiable.Success[0].ID <= 0){
                toast.error(checkIsPromoAvailiable.Success[0].TextMessage, { });
                
                setIsPromoAvailiable(false);
                return false;
            }

            // if(checkIsPromoAvailiable.Success[0].TextMessage !== null && checkIsPromoAvailiable.Success[0].TextMessage !== "Valid Promo Code"){
            //     toast.error(checkIsPromoAvailiable.Success[0].TextMessage, { });
            //     setIsPromoAvailiable(false);
            //     return false;
            // }

            if( checkIsPromoAvailiable.Success[0].ID > 0){
                setIsPromoAvailiable(true);
                setPromoCodeRes(checkIsPromoAvailiable.Success[0])
            
                setOtherInfo({
                    ...otherInfo,
                    TotalAmountMinusingFirstPurchaseDiscount: Math.round((otherInfo.TotalAmountMinusingFirstPurchaseDiscount - ( ((parseFloat(otherInfo.TotalAmountMinusingFirstPurchaseDiscount) * parseInt(5))/100).toFixed(2) )).toFixed(2)),
                })

                return true;
            }

        }catch (err){
            
        }

    }

    // const checkIspremiumOrFreemium = (res)=>{

    //     if(res.IsCodeFreemium === "true"){
    //         setUserStatus({...userStatus, isFreemium: true})
    //         setIsPromoAvailiable(true);
    //         return true;
    //     }else if(res.IsCodePreemium === "true"){
    //         setUserStatus({...userStatus, isPremium: true})
    //         setIsPromoAvailiable(true);
    //         return true;
    //     }else{
    //         setUserStatus({...userStatus, isNormal: true})
    //         return true;
    //     }

    // }

    const applyPromoCodeHandler =async ()=>{
        
        toast.dismiss()

        if(isPromoAvailiable){
            let addTocartData ={
                UserID: profile.details.UserId,
                CategoryID: cart.item[0].CategoryID,
                ProductID: cart.item[0].ProductID,
                QTY: cart.item[0].QTY,
                Color: cart.item[0].Color,
                Size: cart.item[0].Size,
                PromoCode: Coupon,
                LastSlectedProductType: cart.item[0].LastSlectedProductType
            }
            try{
                let res= await postAddToCart(addTocartData, dispatch);

                if(res.HTTPStatusCode !== undefined && res.HTTPStatusCode ===400){
                    toast.error(res.Error.Message, { });
                    return;
                }
                
                if(res.Success.TextMessage !== null && res.Success.TextMessage === "You have already used Freemium code!"){
                    toast.error(res.Success.TextMessage, { });
                    return;
                }

                if(Coupon === "FreeIntelloTag" && isPromoAvailiable){
                    console.log("res= awaitres= await 6", )
                    navigate('/profile', {state: {showOrderSummary: true}});
                }else if(Coupon !== "" && Coupon !== "FreeIntelloTag" && isPromoAvailiable){
                    console.log("res= awaitres= await 7", )
                    navigate("/manage-address", {state: {TotalAmountMinusingFirstPurchaseDiscount: otherInfo.TotalAmountMinusingFirstPurchaseDiscount, promoCode: Coupon}})
                }

                return;
            }catch (err){ 
            }
        }
    }

    const onInputChange = (e)=>{
        
        switch(e.target.name){
            case "Coupon":
                setCoupon(e.target.value);
                break;
                default:   
            }
    }

    console.log("addTypeaddType", addType)

    const placeOrderAndShowOTPview = ()=>{
        return  <div>
            <Modal isOpen={OTPmodal} toggle={setOTPModal} >
                <ModalHeader toggle={setOTPModal}>Please verify your details before proceeding</ModalHeader>
                <ModalBody>
                
                    <FormGroup>
                        <Input 
                            type="text" 
                            placeholder="Enter Mobile number" 
                            value={mobileNumber}
                            onChange={(e)=>{
                                console.log("placePayment getOTP 1", e.target.value);
                                console.log(e);
                                setMobileNumber(e.target.value);
                            }}
                        />
                    </FormGroup>
                
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={getOTP}>Get OTP</Button>
                </ModalFooter>
            </Modal>
        </div>
    }
    
    const placeOrderAndShowAddressview = ()=>{
        return  <div>
            <Modal isOpen={addressModal} toggle={()=>setAddressModal(false)} >
                <ModalHeader toggle={()=>setAddressModal(false)}>Please verify your details before proceeding</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input 
                                type="text" 
                                placeholder="Enter Mobile number" 
                                value={mobileNumber}
                                onChange={(e)=>{
                                    setMobileNumber(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                type="text" 
                                value={OTP}
                                onChange={(e)=>{
                                    setOTP(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary" onClick={getOTP}>resend OTP</Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="primary" 
                        onClick={placeCartItemOrder}
                    >Place order</Button>
                </ModalFooter>
            </Modal>
        </div>
    }


    const getPlaceOrderText = ()=>{

        if(Coupon === "FreeIntelloTag"){
            return<div className="">
                <button 
                    type="submit" 
                    className="btn btn-primary mt-4 btn-lg" 
                    onClick={toggle}
                >Buy Now</button>
            </div>
        }else{ 
            return<div style={{
                "display": "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
            }}>
                <div className="">
                    <button 
                        type="submit" 
                        className="btn btn-primary mt-4 btn-lg" 
                        onClick={toggle}
                    >Place Order</button>
                </div>
               
                <div className="text-center mt-4" style={{"margin-right": 20}}>
                    <NavLink 
                         onClick={()=>{
                            navigate('/');
                            dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                        }} 
                        href="#"
                    ><u style={{
                        color: 'rgba(var(--bs-link-color-rgb)'
                    }}>Continue Shopping</u></NavLink>
                </div>
            </div>
        } 

    }

    const discountView= () =>{
        if(isPromoAvailiable && Coupon === "FreeIntelloTag")
        return null;
        return <React.Fragment>
            {cart.item.length > 0 && cart.item[0].IsFirstPurchase !== "False" && cart.item[0].IsFirstPurchase !== null && <Row>
                <Col>First Purchased Discount</Col>
                <Col className="text-end text-success">{cart.item[0].FirstPurchaseDiscount}% ({Math.round(totalDiscount)})</Col>
            </Row>}

            {isPromoAvailiable  && <Row>
                <Col>Promo Applied</Col>
                <Col className="text-end text-success">{getPromoCodeAmount()}% 
                    ({ Math.round(((parseFloat(otherInfo.TotalAmountMinusingFirstPurchaseDiscountStatic) * parseInt(getPromoCodeAmount()))/100).toFixed(2)) })
                </Col>
            </Row>}
        </React.Fragment>
    }
    
    return (
    <React.Fragment>
        <Header />
        {placeOrderAndShowOTPview()}
        {placeOrderAndShowAddressview()}
        <main className="bg-light">
            <section className="cart-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <div className="mb-3">
                                <h4>Cart</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Cart</BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            {cart.item.length >0 ?   
                            <Row>
                                <Col lg={8} className="pt-2"> 
                                    <div className="address-sec">
                                    <table className="cart-listing">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>color</th>
                                                    <th className=" d-md-table-cell d-none">Price</th>
                                                    <th>Quantity</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getCartItemsView()}
                                                
                                                {/* <tr className=" border-bottom-0">
                                                    <td className="pb-0" colSpan={2}>Total Quantity</td>
                                                    <td className="pb-0 d-md-table-cell d-none"></td>
                                                    <td className="pb-0 text-end"><strong> {otherInfo.TotalQTY}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td className="pt-1" colSpan={2}>Total Amount</td>
                                                    <td className="pt-1 d-md-table-cell d-none"></td>
                                                    <td className="pt-1 text-end"><strong> {otherInfo.TotalAmount}</strong></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        
                                        {getPlaceOrderText()}

                                    </div>
                                </Col>
                                <Col className="pt-2" lg={4}>
                                <div className="price-box">
                                    <Row>
                                        <Col>Sub Price</Col>
                                        <Col className="text-end">{Math.round(otherInfo.TotalAmount)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Total Quantity</Col>
                                        <Col className="text-end text-success">{otherInfo.TotalQTY}</Col>
                                    </Row>
                                    {discountView()}
                                    

                                    <Row>
                                        <Col><sRowong>Total Amount</sRowong></Col>
                                        <Col className="text-end">
                                            <sRowong>INR{' '}
                                                { (isPromoAvailiable && Coupon === "FreeIntelloTag" ) ?
                                                    0:
                                                    otherInfo.TotalAmountMinusingFirstPurchaseDiscount
                                                }
                                            </sRowong>
                                        </Col>
                                        { (isPromoAvailiable && Coupon === "FreeIntelloTag" ) &&
                                            'You Applied FreeIntelloTag Promocode'
                                        }
                                    </Row>
                                </div>

                                {!isPromoAvailiable && <div className="p-0 coupon-form mt-2">
                                        <form className="" 
                                            onSubmit={(e)=>{
                                                e.preventDefault();
                                                if(Coupon == ""){
                                                    toast.error("Please Enter Promo Code", { });
                                                    return;
                                                }

                                                let isCountZero= false;

                                                console.log('itemitem 4', cart)
                                        
                                                for (let i = 0; i < cart.item.length; i++) {
                                                    const item = cart.item[i];
                                        
                                                    if (item.AvailableFreemiumCount === '0' && item.AvailablePreemiumCount === '0') {
                                                        isCountZero = true;
                                                        toast.dismiss();
                                                        toast(<CreateToastView item= {item}/>);
                                                        break; // Exits the loop
                                                    }
                                                
                                                    if (item.AvailableFreemiumCount === '0' && item.LastSlectedProductType === 'Freemium') {
                                                        isCountZero = true;
                                                        toast.dismiss();
                                                        toast(<CreateToastView item= {item}/>);
                                                        break; // Exits the loop
                                                    }
                                                
                                                    if (item.AvailablePreemiumCount === '0' && item.LastSlectedProductType === 'Preemium') {
                                                        isCountZero = true;
                                                        toast.dismiss();
                                                        toast(<CreateToastView item= {item}/>);
                                                        break; // Exits the loop
                                                    }
                                                }
                                                
                                                if(isCountZero){
                                                    return;
                                                }

                                                let totalPremiumCount= 0;
                                                for (let i = 0; i < cart.item.length; i++) {
                                                    const item = cart.item[i];
                                                    totalPremiumCount= totalPremiumCount+ parseInt(item.AvailablePreemiumCount)
                                                }
                                        
                                                if(Coupon !== "FreeIntelloTag" && totalPremiumCount===0){
                                                    toast.dismiss();
                                                    toast('Premium tag is out of stock.', { });
                                                    return;
                                                }
                                                
                                                checkPromoCodedd()
                                            }}
                                        >
                                            <input type={'text'} className={'form-control'} name={'Coupon'} placeholder="PROMO CODE" value={Coupon}
                                                onChange={onInputChange} disabled={isPromoAvailiable} />
                                                <button 
                                                    type="submit"  
                                                    className="btn btn-primary"
                                                    // disabled={isPromoAvailiable}
                                            >Apply</button>
                                        </form>
                                    </div>}
                                    
                                    {isPromoAvailiable && <div className="white-box mt-3">
                                        <Row>
                                            <Col className="text-success">
                                                {Coupon} promocode applied successfully!
                                            </Col>
                                            <Col >
                                                <div 
                                                    className="d-flex align-items-center justify-content-end cart-close-icon" 
                                                    style={{cursor: "pointer"}}
                                                    onClick={()=>{
                                                        setIsPromoAvailiable(false);
                                                        setPromoCodeRes("");
                                                        setCoupon('')
                                                        if(cart.isResponseGet){
                                                            let TotalAmount = 0;
                                                            let TotalQTY = 0;
                                                            if(cart.item.length >0){
                                                                cart.item.forEach(cart => {
                                                                    TotalAmount= (+TotalAmount)+(+cart.OfferPrice);
                                                                    TotalQTY= (+TotalQTY)+(+cart.QTY);
                                                                });

                                                                setOtherInfo({
                                                                    ...otherInfo,
                                                                    TotalAmount: parseFloat(TotalAmount).toFixed(2),
                                                                    TotalAmountMinusingFirstPurchaseDiscountStatic: Math.round(parseFloat(TotalAmount).toFixed(2)- parseFloat(totalDiscount).toFixed(2)),
                                                                    TotalAmountMinusingFirstPurchaseDiscount: Math.round(parseFloat(TotalAmount).toFixed(2)- parseFloat(totalDiscount).toFixed(2)),
                                                                    TotalQTY: TotalQTY,
                                                                })
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <img src={Plus} alt="plus" /> <span className="ms-1 d-md-block d-none">Remove</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>}

                                    {isPromoAvailiable && <div className="mt-3">
                                    <button 
                                        className="btn btn-primary mt-2 bw-2"
                                        onClick={async()=>{
                                            let res= await checkPromoCodedd();
                                            console.log("res= awaitres= await 2", res)
                                            if(res && Coupon !== ""){
                                                applyPromoCodeHandler();
                                            }else if(res){
                                                if(Coupon === "" && isPromoAvailiable){
                                                    console.log("res= awaitres= await 8", )
                                                    navigate("/manage-address", {state: {TotalAmountMinusingFirstPurchaseDiscount: otherInfo.TotalAmountMinusingFirstPurchaseDiscount, promoCode: Coupon}})
                                                }
                                            }
                                            
                                        }}
                                    >Buy Now</button>
                                </div>}
                            </Col>
                            </Row>
                            :
                           <div className="text-center py-5">
                             <p className="text-disabled">No Items in the cart</p>
                             <a onClick={(e)=>{
                                e.preventDefault()
                                navigate("/")
                             } } className="btn btn-primary">Shop Now</a>
                           </div>
                        }
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        
        </main>
    </React.Fragment>
    );
}
  
  export default Cart;