import { createSlice, current } from '@reduxjs/toolkit'
import { BASE_URL, PRODUCTS } from '../../../utility/apiRoutes';
import { getData } from '../../../utility/rootService';

const initialState = {
    childList: [],
    pagedList: [{
        "UserID": "",
        "ProductID": "",
        "CategoryID": "",
        "AvailableQTY": "",
        "Name": "",
        "Image": "",
        "Description": "",
        "Colors": "",
        "Currency": "",
        "Sizes": "",
        "Images": "",
        "Rating": "",
        "MRP": "",
        "OfferPrice": "",
        "Discount": "",
        "TotalRating": "",
        "Features": "",
        "ColorWithImages": []
         
    }],
    totalRecords: 0,
    isResponseGet: false,
    pickedSliderProducts: []
}
export const product = createSlice({
  name: 'product',
  initialState: {
    ...initialState
  },
  reducers: {

    // Executs on first time load
    setProduct: (state, action)=>{

      // These are the parameter for the first time load tag(product)
      let getColor= `#${action.payload.PagedList[0].Colors.split(",")[0]}`;
      let getFirstOccurance= action.payload.PagedList[0].ColorWithImages.length>0 ? action.payload.PagedList[0].ColorWithImages.filter(tag=> tag.Color === getColor): [];
      let getQuantityFromChilds=  getFirstOccurance.length >0? getFirstOccurance[0].AvailableQTY: action.payload.PagedList[0].AvailableQTY;

      state.childList = action.payload.ChildList;
      // state.pagedList = action.payload.PagedList;
      state.pagedList = [{
        ...action.payload.PagedList[0], 
        Color: `#${action.payload.PagedList[0].Colors.split(",")[0]}`,
        AvailableQTY: getQuantityFromChilds,
        ...getFirstOccurance[0]
      }];
      state.totalRecords = action.payload.TotalRecords;
      state.pickedSliderProducts= state.pagedList[0].ColorWithImages.filter(CWI=> CWI.Color === `#${state.pagedList[0].Colors.split(",")[0]}`)
      state.isResponseGet= true;
    },

    setPickedColorProducts: (state, action)=>{
      console.log("magnifyDivmagnifyDiv 10",action.payload);
      return {
        ...state,
        pagedList: [
          {
            ...state.pagedList[0],
            // ...action.payload.getColoredProduct.filter(colorProduct=> colorProduct.IsMainImage)[action.payload.currentSliderindex]
            ...action.payload.getColoredProduct[action.payload.currentSliderindex]
          }
        ],  // --> this could be the main product.
        pickedSliderProducts: action.payload.getColoredProduct   // --> Slider Products will also be changed, bcs we changed color
      }
    },

    setProductToBeMain: (state, action)=>{

      return {
        ...state,
        pagedList: [{...state.pagedList[0] ,...action.payload[0]}],  // --> this could be the main product.
        pickedSliderProducts: state.pickedSliderProducts  // --> Slider remain unchanged. bcs we didn't change color only slide
      }
    },

    resetProduct: (state, action)=>{
        return {...initialState}
    }
  }
})
export const { setProduct, resetProduct, setPickedColorProducts, setProductToBeMain } = product.actions

async function productDispatchFetch(
    // token, 
        dispatch,
        CategoryID,
        ProductID =-1,
        Pageindex =1,
        Pagesize =10,
        SearchProducts =-1
    ){
  
      try{

        let url= BASE_URL+PRODUCTS+`CategoryID=${CategoryID}&ProductID=${ProductID}&Pageindex=${Pageindex}&Pagesize=${Pagesize}&SearchProducts=${SearchProducts}`;
    
        let headerConfig = {
          "Content-Type": "application/json",
          // "Authorization": "Bearer " +token
        }
  
        const fetchProduct =await getData({url, headerConfig}, dispatch)
        
        return fetchProduct;
        
    }catch (err){
        console.log("this is a fetchProduct catch")
  
      throw (err)
    }
  
  }
  
  export const getProductDispatch = (
        item,
        Pageindex,
        Pagesize,
        SearchProducts
    ) => {
  
    return async(dispatch, getState) => {
        
        // if(getState().authToken.payload === "") return;
        
        try{
            // let token = getState().authToken.payload.accessToken;
            const resProduct =await productDispatchFetch(
                // token, 
                dispatch,
                item.CategoryID,
                item.ProductID,
                Pageindex,
                Pagesize,
                SearchProducts
            )
        
                
          dispatch(setProduct(resProduct.Success));
          console.log("setProductsetProductsetProduct", resProduct.Success)
         
        }catch (err){
          if(err!= undefined && err.isError){
          }
      }
    }
  }


export const resetProductsDispatch = obj => {
    return async(dispatch, getState) => {
        dispatch(resetProduct())
    }
}

export default product.reducer