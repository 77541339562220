import React from "react";
import HeaderFooter from "../../HeaderFooter";
import { Container, Row, Col } from 'reactstrap';
import activateTag from './../../Assets/Images/activateTag.jpg';
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

export default ()=>{
    const navigate = useNavigate();    
    const auth = useSelector(state => state.auth)
    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="primary-page-title">
                        <Container>
                            <h2 className="text-center" data-aos-duration="1000" data-aos="zoom-in-up" data-aos-delay="50">Embrace Brilliance</h2>
                            <p className="text-center" data-aos-duration="1000" data-aos="zoom-in-up" data-aos-delay="100">IntelloTags Activation Guide</p>
                        </Container>
                    </div>
                </section>
                <section className="text-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={9} onClick={()=>{

                                if(auth.data === null){
                                    navigate('/login');
                                    toast("Please login first", { });
                                }else{
                                    navigate("/profile", {state: {showTag: true}})
                                }
                                
                            }}>
                                <NavLink >
                                    <img src={activateTag} className="img-fluid" data-aos="fade-in-right" data-aos-delay="100" data-aos-duration="1000" alt="activateTag" />
                                </NavLink>
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 