import React, { useEffect} from "react";

 import Slide from './../../Assets/Images/Slide1.jpg';
 import Slide2 from './../../Assets/Images/Slide2.jpg';
 import Slide3 from './../../Assets/Images/Slide3.jpg';

import Slider from "react-slick";


var productSlider = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  speed: 500,
  slidesToScroll: 1
}

export default ()=>{

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <React.Fragment>
        <Slider
            {...productSlider}>
            <div className="signupItem">
                <img src={Slide} className="signup-img" alt="" /> 
                <div className="signupImgCaption"><h3>A Statement of Smartness and Uniqueness</h3></div>
            </div>
            <div className="signupItem">
                <img src={Slide2} className="signup-img" alt="" /> 
                <div className="signupImgCaption"><h3>A Future of Tag is on your Bag</h3></div>
            </div>
            <div className="signupItem">
                <img src={Slide3} className="signup-img" alt="" /> 
                <div className="signupImgCaption"><h3>Feel the Luxury of being ahead with Intello Tags</h3></div>
            </div>
        </Slider>
  </React.Fragment>
  );
}