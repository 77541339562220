import React from "react";
  
export default (props)=>{

    return (
        <React.Fragment>
            <div 
                className={props.classes? props.classes: `text-center section-heading wow animate__zoomIn`}
                data-wow-duration={props.wowDuration? props.wowDuration: `2s`} 
            >
                {props.children}
            </div>
        </React.Fragment>
    )
} 