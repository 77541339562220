import React from "react";
  
export default (props)=>{


    return (
        <React.Fragment>
            <a onClick={(e)=>e.preventDefault()}>
                <img src={props.src} alt={props.alt} className={props.clasess} {...props}/>
            </a>
        </React.Fragment>
    )
} 