import React, { useState }  from "react";
import HeaderFooter from "../../HeaderFooter";
import {Col, Row, Breadcrumb, BreadcrumbItem,  Container, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardTitle, CardText, Button, CardBody, Badge, CardHeader } from 'reactstrap';

import product1 from './../../Assets/Images/product1.jpg';
import product2 from './../../Assets/Images/product2.jpg';
import product3 from './../../Assets/Images/product3.jpg';
import product4 from './../../Assets/Images/product4.jpg';
import product5 from './../../Assets/Images/product5.jpg';
import product6 from './../../Assets/Images/product6.jpg';

import Cart from './../../Assets/Images/cart.png';
import Star from './../../Assets/Images/star.png';
import lightStar from './../../Assets/Images/star-light.png';
// products 
const products = [
    {image: product1, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '5', categoryId: '1' },
    {image: product2, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '4', categoryId: '1' },
    {image: product3, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '3', categoryId: '1' },
    {image: product4, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '4', categoryId: '1' },
    {image: product5, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '5', categoryId: '2' },
    {image: product6, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '4', categoryId: '2' },
    {image: product1, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '5', categoryId: '1' },
    {image: product2, title: "Auto Tag", price: 'Rs. 3,000', comparePrice: 'Rs. 6,000', url:"#", rating: '4', categoryId: '1' },
]
export default ()=>{
   
    const [isActiveTab, setIsActiveTab] = useState('1');
    
    const [isShow, setIsShow] = useState(true);

    const getRating = (data) => {
        var numberOfCards = 5;
        var cards = [];
        if (data <= numberOfCards) {
            for (var i = 0; i < numberOfCards; i++) {
                if(i < data){
                    cards[i] = (Star);
                }else{
                    cards[i] = (lightStar);
                }
            }
        }
        return cards;
    }

    const setTab = (id) => {
        setIsShow(false);
        setIsActiveTab(id);
        setTimeout(function(){
            setIsShow(true);
        },300)

    }

    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos-offset="200" data-aos="zoom-in-up" data-aos-delay="100">Products</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-4 mt-2 pb-3" data-aos-offset="200" data-aos="fade-in-right" data-aos-delay="100">
                    <Container>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Products</BreadcrumbItem>
                        </Breadcrumb>
                    </Container>
                </section>
                <section className="pt-0" id="online-store">
                    <Container>
                    <Nav tabs className=" wow animate__fadeInUp" data-wow-duration="2s" >
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '1' && 'active'}
                                        onClick={event => setTab('1')}
                                    >
                                        Automobile Tags
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '2' && 'active'}
                                        onClick={event => setTab('2')}
                                    >
                                        Belongings Tags
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '3' && 'active'}
                                        onClick={event => setTab('3')}
                                    >
                                        Fauna Tags
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '4' && 'active'}
                                        onClick={event => setTab('4')}
                                    >
                                        Gadget Tags
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '5' && 'active'}
                                        onClick={event => setTab('5')}
                                    >
                                        Kids Tags
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={isActiveTab === '6' && 'active'}
                                        onClick={event => setTab('6')}
                                    >
                                        Medical Tags
                                    </NavLink>
                                </NavItem>
                                
                            </Nav>
                            <TabContent activeTab={isActiveTab}>
                                <TabPane tabId={isActiveTab} className="mt-4">
                                    <Row>
                                        {products
                                        .filter(item => (item.categoryId === isActiveTab))
                                        .map((item, index) => (
                                            <Col sm={6} md={4} lg={3} className={`wow  animate__zoomIn ${isShow && 'showItem'}`} data-wow-duration="2s"  key={index}>
                                                <Card className="product-item">
                                                    <div className="image-wrap">
                                                        <Badge color="primary" className="badge badge-primary"> {item.title}</Badge>
                                                        <a href={item.url}>
                                                            <img src={item.image} alt={item.title} className="w-100" />
                                                        </a>
                                                    </div>
                                                    <CardBody>
                                                        <CardTitle className="mb-0">
                                                        <a href={item.url}>{item.title}</a>
                                                        </CardTitle>
                                                        <CardText>
                                                            <div className="rating">
                                                                {getRating(item.rating).map((image, index) => (
                                                                    <img src={image} alt="star"  key={index} width="20" height="20" />
                                                                ))}
                                                            </div>
                                                            <h6 className="price">{item.price} <span className="text-decoration-line-through">{item.comparePrice}</span></h6>
                                                        </CardText>
                                                        <Button color="link">
                                                            <img src={Cart} alt="Cart" className="me-2" />
                                                            Add to Cart
                                                        </Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>    
                                        ) )}
                                        {products.filter(item => (item.categoryId === isActiveTab)).length === 0 && <p className="text-disabled text-center p-4">No product in this category</p> }
                                    </Row>
                                </TabPane>
                                
                            </TabContent>
                    </Container>
                </section>
                
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 