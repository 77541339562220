import React, { useEffect, useState } from "react";
import TagImg from './../../../../Assets/Images/P.jpg'
import {Container, Row, Col } from 'reactstrap';
import App from './../../../../Assets/Images/app.png';
import Play from './../../../../Assets/Images/google-play.png';
import Logo from './../../../../Assets/Images/logo.png';
import { useSearchParams } from "react-router-dom";
import { checkQrCodeDetails } from "../../../../utility/service";
import {useDispatch} from "react-redux"
import { toast } from 'react-toastify';
import { useNavigate, NavLink, useParams } from 'react-router-dom'

function TagNotActiveForParams() {
    
    const navigate = useNavigate()
    const dispatch= useDispatch();
    const {qrCode}= useParams();

    console.log("qrCodeqrCode", qrCode)
    
    useEffect(()=>{

         async function getQrCodeDetails(){

            let activateData = {
                qrCode
            }

            try{
                let res= await checkQrCodeDetails(activateData, dispatch); 
                console.log("res.HTTPStatusCoderes.HTTPStatusCode", res.Success.length)
                if( res && res.Success && res.Success.EmergencyNumber && !res.Success.IsExpired ){
                    navigate('/welcome-qr', {state: {qrCodeDetails: res.Success}});
                }else if(res && res.Success && res.Success.EmergencyNumber && res.Success.IsExpired){
                    navigate('/membership-expired');
                }else if(res && res.Success && Array.isArray(res.Success) && res.Success.length == 0){
                    navigate('/login');
                }
            }catch (err){
                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                return;
            }
        }

        getQrCodeDetails();

    }, [])
    
    return (
    <React.Fragment>
        <main className="bg-light qr-screen h-100vh">
            <header className="header px-3 px-sm-4">
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                        <a href="/">
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section>
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col xl={6}>
                            <div className="mb-2 text-primary text-uppercase">
                                <h6 className="light-title">Welcome to</h6>
                                <h3>Intello Tags</h3>
                            </div>
                            <div className="tagNotActivated">
                                <img src={TagImg} className="img-fluid" alt="Tag" />
                                <button className="btn btn-primary px-3 btn-sm">Tag is not<br/>Active!</button>
                            </div>
                            <div className="mb-5">
                                <button className="btn btn-primary btn-rounded btn-lg" onClick={()=> navigate('/activate', {state: {qrCode: qrCode}})}>Click here to Activate the tag!</button>
                            </div>
                            <p>if you don't have account please signup 
                               {" "} 
                            <NavLink 
                                to="https://www.intellotags.com/#/signup" 
                                target="_blank"><u>here </u>
                            </NavLink>

                            </p>
                            <div className="footer-apps">
                                <h5 className="mb-2">Download our App</h5>
                                <a href="#" className="w-100 me-0" target="_blank">
                                    <img src={App} className="img-fluid" alt="App Store" />
                                </a><br/>
                                <a  className="w-100 ms-0" href="https://play.google.com/store/apps/details?id=com.intello.tagss&pli=1" target="_blank">
                                    <img src={Play} className="img-fluid" alt="Play Store" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        
        </main>
    </React.Fragment>
    );
}
  
  export default TagNotActiveForParams;