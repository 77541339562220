import { Route, Routes, Navigate } from "react-router-dom";
import Home from './../Screen/Home'
import ProductDetails from "../Screen/ProductDetails";
import Login from "../Screen/Authentications/Login";
import CorporateLogin from "../Screen/Authentications/CorporateLogin";
import Registration from "../Screen/Authentications/Registration";
import Cart from "../Screen/Authentications/Cart";
import Profile from "../Screen/Authentications/Profile";
import ManageAddress from "../Screen/Authentications/ManageAddress";
import EmailVerification from '../Screen/Authentications/EmailVerification';
import EmailVerify from '../Screen/Authentications/EmailVerify';
import EmailSent from '../Screen/Authentications/EmailSent';
import PasswordChanged from '../Screen/Authentications/PasswordChanged';
import ResetPassword from '../Screen/Authentications/ResetPassword';
import ForgotPassword from '../Screen/Authentications/ForgotPassword';
import WelcomeSignup from '../Screen/Authentications/WelcomeSignup';
import OrderConfirmation from "../Screen/Authentications/OrderConfirmation";
import MarchantOrderResponse from "../Screen/Authentications/OrderConfirmation/marchantOrderResponse";

import PrivacyPolicy from "../Screen/PrivacyPolicy";
import TermsOfUse from "../Screen/TermsOfUse";
import Innovation from "../Screen/Innovation";
import ProductListing from "../Screen/ProductListing";
import Blog from "../Screen/Blog";
import ActivateTag from "../Screen/ActivateTag";
import ClaimRevard from "../Screen/ClaimRevard";
import EditTags from "../Screen/EditTags";
import Career from "../Screen/Career";
import Support from "../Screen/Support";
import BecomeFranchise from './../Screen/BecomeFranchise';
import BusinessAssociateProgram from './../Screen/BusinessAssociateProgram';
import Membership from "../Screen/Membership";
import PaymentFailed from "../Screen/Authentications/PaymentFailed";
import TagNotActive from '../Screen/Authentications/ScanQR/TagNotActivate';
import MembershipExpired from '../Screen/Authentications/ScanQR/MembershipExpired';
import TagActivated from '../Screen/Authentications/ScanQR/TagActivated';
import WelcomeQR from '../Screen/Authentications/ScanQR/WelcomeQR';
import ClaimReward from '../Screen/Authentications/ScanQR/ClaimReward';
import FillTheForm from '../Screen/Authentications/ScanQR/FillTheForm';
import MedicalHistory from '../Screen/Authentications/ScanQR/MedicalHistory';
import EditTag from "../Screen/Authentications/EditTag";
import TagNotActiveForParams from "../Screen/Authentications/ScanQR/TagNotActivate/TagNotActiveForParams";

export default ()=> ( 
    <Routes>
        {/* HOME */}
        <Route path="/" exact element={<Home />} />
        <Route path="/product-details" element={<ProductDetails /> } />
        <Route path="/login" element={<Login /> } />
        <Route path="/corporate-login" element={<CorporateLogin /> } />
        <Route path="/signup" element={<Registration /> } />
        <Route path="/email-varification" element={<EmailVerification /> } />
        <Route path="/email-verify" element={<EmailVerify /> } />  {/* will be redirected here through mail */}
        
        {/* FORGET PASSWORD */}
        <Route path="/forgot-password" element={<ForgotPassword /> } />
        <Route path="/email-sent" element={<EmailSent /> } />
        <Route path="/reset-password" element={<ResetPassword /> } />
        <Route path="/password-changed" element={<PasswordChanged /> } />
        <Route path="/confirmemail" element={<WelcomeSignup /> } /> {/* need to ask */}
        <Route path="/cart" element={<Cart /> } />
        <Route path="/profile" element={<Profile /> } />
        <Route path="/manage-address" element={<ManageAddress /> } />
        <Route path="/thank-you" element={<OrderConfirmation /> } />
        <Route path="/thank-you-marchant" element={<MarchantOrderResponse /> } />
        <Route path="/payment-failed" element={<PaymentFailed /> } />
        <Route path="/privacy-policy" element={<PrivacyPolicy /> } />
        <Route path="/terms-of-use" element={<TermsOfUse /> } />
        <Route path="/innovation" element={<Innovation /> } />
        <Route path="/products" element={<ProductListing /> } />
        <Route path="/blog" element={<Blog /> } />
        <Route path="/activate-tag" element={<ActivateTag /> } />
        <Route path="/edit-tags" element={<EditTags /> } />
        <Route path="/claim-revard" element={<ClaimRevard /> } />
        <Route path="/career" element={<Career /> } />
        <Route path="/support" element={<Support /> } />
        <Route path="/become-a-franchise" element={<BecomeFranchise /> } />
        <Route path="/business-associate-program" element={<BusinessAssociateProgram /> } />
        <Route path="/membership" element={<Membership /> } />
        
        <Route path="/qrtoaction/:qrCode" element={<TagNotActiveForParams />} />
        <Route path="/tag" element={<TagNotActive />} />
        <Route path="/edit-tag" element={<EditTag />} />
        <Route path="/activate" element={<FillTheForm />} />
        <Route path="/tag-activated" element={<TagActivated />} />
        <Route path="/membership-expired" element={<MembershipExpired />} />
        <Route path="/welcome-qr" element={<WelcomeQR />} />
        <Route path="/claim-reward" element={<ClaimReward />} />
        <Route path="/medical-history" element={<MedicalHistory />} />
        
        {/* WILD CARD URL(if url not founded) */}
        <Route path="*" element={<Navigate to="/"/>} />
    </Routes>
  );