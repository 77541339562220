import React from "react";
import { Badge } from 'reactstrap';
  
export default (props)=>{

    return (
        <React.Fragment>
           <Badge color="primary" className="badge badge-primary" {...props}>{props.children}</Badge>
        </React.Fragment>
    )
} 