import { createSlice } from '@reduxjs/toolkit'
import { BASE_URL, LOG_IN } from '../../../utility/apiRoutes'
import { postData } from '../../../utility/rootService'
import { profileDispatch } from '../../Pro/profile.slice'
import { toast } from 'react-toastify';

const initialState = {
  data: null,
  isLogedIn: false
}

export const auth = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    setAuth: (state, action) => {
      console.log('this is our login submit data >>', action)
      return {...state, data: {...action.payload}}
    },
    resetAuth: (state, action)=>{
      return {...initialState}
    },
    setIsLogedIn: (state, action)=>{
      state.isLogedIn = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuth, resetAuth, setIsLogedIn } = auth.actions

const loginFetch =async (data, dispatch) =>{
    
  console.log("this is a loginFetch try" , data)

    try{

      let url= BASE_URL+LOG_IN;
      let dataObje= {
        Email: data.email,
        Password: data.password
      }
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const loginRes =await postData({url, headerConfig, dataObje}, dispatch)

      console.log("this is a loginDispatch try", loginRes)
    
    return loginRes;
    
  }catch (err){

    console.log("this is a loginFetch catch")
    throw (err)
  }

}

export const loginDispatch = (data) => {

  return async(dispatch, getState) => {
    try{
      
        const resloginFetch =await loginFetch(data, dispatch) 
        
        if(resloginFetch.HTTPStatusCode === 400){
          throw (resloginFetch.Error.Message)
        }
        
        dispatch(setAuth(resloginFetch.Success))
        dispatch(profileDispatch(resloginFetch.Success.ID))

        localStorage.setItem("auth", JSON.stringify({
          ...resloginFetch.Success
        }))
        dispatch(setIsLogedIn(true))
      }catch (err){
        toast.dismiss();
        if(err !== "") toast.error(err, { });
        dispatch(setIsLogedIn(false))
        
    }
  }
}

export const logoutDispatch = () => {
    
    return async(dispatch, getState) => {
        try{
            localStorage.clear()
            dispatch(resetAuth())

            // Add context api to pass info its has been logout to all pages
        }catch (err){
          throw (err)
        }
    }
}

export default auth.reducer