import React from "react";

import { Row, Col,Container,Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import InnovationImg from './../../Assets/Images/innovation.png';
import  HeaderFooter from './../../HeaderFooter';

function Innovation() {
    
  
    return (
      <React.Fragment>
          <HeaderFooter>
           <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                <img src={InnovationImg} className="w-100" alt="Innovation" />
           </div>

           <Container>
                <div className="my-4 pt-0 pt-md-4">
                    <Breadcrumb>
                        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                        <BreadcrumbItem active>Why this Innovation</BreadcrumbItem>
                    </Breadcrumb>
                </div>
           </Container>
          
           <div className=" main-content">
          
            <Container>
                <div >
                    <p>Two decades ago a fatal car crash shattered the life of my friend and ruined her family's happiness forever.</p>
                    <p>It was a cold dark stormy & rainy night when we both were making our way back home from Jim Corbett national park. We were passing through a small town having dense forest on both sides of the road, All of a sudden a Nilgai appeared in front of me and I lost control and the car hit the tree, skid, turned upside down and stopped automatically after a few meters.</p>
                    <p>I woke up in a Government hospital after 2 days having multiple injuries but no threats to my life but my dearest friend remained in coma for a fortnight and then survived as a double amputee.</p>
                    <p>Police couldn't inform our parents because they were unable to trace our whereabouts. All our belongings & valuables went missing after the accident; Cops were trying to get some information through transport authority but failed to connect the dots because contact information was out-dated.</p>
                    <p>Neither our parents nor Police had any clue about us until I regained my consciousness and shared relevant information to contact our family and report this incident.</p>
                    <p>With this misshaping I thought it would be great if there was a way that a person or family could be contacted at the time of distress and this is why we at Intello Tags developed a technology for all sorts of vehicles through smart tags having a unique Quick Response Code (QR Code). Which will help people and directly connect user's near & dear through encrypted calls, Email & SMS in the case of an accident, unprecedented and emergency situations.</p>
                    <p>So it is just the Beginning of after.........</p>
                </div>
            </Container>
            </div>
            </HeaderFooter>
      </React.Fragment>
    );
  }
  export default Innovation;