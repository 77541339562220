import React, {useEffect, useState} from "react";
import './animation.css';

import { useDispatch, useSelector } from 'react-redux'

function WelcomeAnimation() {

  const [showAnimation, setAhowAnimation] = useState(true)
  const dispatch = useDispatch()
  const loader = useSelector(state => state.loader)

  setTimeout(() => {
    setAhowAnimation(false);
}, 10000);

    return (
      <React.Fragment>
        {!loader.open &&
        <div class={`animation-container ${showAnimation ? 'visible' : 'hide'}`}>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="balloon"></i>
            <i class="curved"></i>
            <i class="curved"></i>
            <i class="curved"></i>
            <i class="curved"></i>
            <i class="curved"></i>
            <i class="curved"></i>
            <i class="curved"></i>
        </div>
        }
      </React.Fragment>
    );
  }
  export default WelcomeAnimation;