import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import { Row, Col,Container } from 'reactstrap'
import Header from './../../../Components/Header';
import Image from './../../../Assets/Images/welcome.png'
import { verifyEmail } from "../../../utility/service";
import {useSelector, useDispatch} from "react-redux"
import WelcomeAnimation from './../../../Components/WelcomeAnimation';

function Wecome() {

  const dispatch= useDispatch();
  let [searchParams] = useSearchParams();

  const [email] = React.useState(
    searchParams.get("email")
  );

  useEffect(()=>{
    window.scrollTo(0, 0);
    async function verifyEmailHandler(){
      try{
        console.log('this is try verifyEmailHandler', "try")
        await verifyEmail({email}, dispatch);
      }catch (err){

      }
    }
    verifyEmailHandler()

  }, [])
  
    return (
      <React.Fragment>
          <Header />
          <div className=" bg-light mh-100vh v-center-content">
            <Container>
              <Row className="m-0 justify-content-center">
                <Col className="pt-4 pb-4 pt-md-5 pb-md-5" lg={6} data-aos="zoom-in" data-aos-delay="100" data-aos-duration="100">
                  <div className="white-box text-center">
                    <img src={Image} alt="welcome" />
                    <div className="mw-450 pt-4 pb-4">
                      <h5>Congratulations on your successful registration!!</h5>
                      {/* <p className="mb-1 mt-4">Your propocode : <strong>MERINTELLO211</strong></p> */}
                      <p><strong>Let's the selling begin!</strong></p>
                      <button className="btn btn-primary mt-3">Login</button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <WelcomeAnimation />
          </div>
      </React.Fragment>
    );
  }
  export default Wecome;