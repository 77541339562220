import React from "react";
import { NavItem } from "reactstrap";

export default (props)=>{


    return (
        <React.Fragment>
            <NavItem
                className={props.classes && props.classes}
            >
                {props.children}
            </NavItem>
        </React.Fragment>
    )
} 