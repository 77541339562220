import React from "react";
import { Nav } from 'reactstrap';
  
export default (props)=>{

    return (
        <React.Fragment>
           <Nav 
                tabs 
                className={props.classes? props.classes: "wow animate__fadeInUp"}
                data-wow-duration={props.dataWowDuration? props.dataWowDuration: "2s" }
                {...props}
            >
                {props.children}
            </Nav>
        </React.Fragment>
    )
} 