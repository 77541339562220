import React, { useEffect, useState } from "react";
import EmailCard from './../../../../Assets/Images/email-card.png';
import CallCard from './../../../../Assets/Images/call-card.jpg';

import ClaimRewardCard from './../../../../Assets/Images/claimReward.png';
import EmergencyCard from './../../../../Assets/Images/emergency.png';
import MedicalHistoryCard from './../../../../Assets/Images/medicalHistory.png';

import MedicalEmergency from './../../../../Assets/Images/medical-emergency.png';
import MedicalHistory from './../../../../Assets/Images/medical-history.png';
import Logo from './../../../../Assets/Images/logo.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from "react-router-dom";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Button, Container, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap'
import { EffectCards, Autoplay } from 'swiper/modules';
import { activeTag, postSaveLastScan, sendEmailToOwner } from "../../../../utility/service";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import validator from 'validator';

function Cart() {
    
    const navigate = useNavigate()
    const dispatch= useDispatch();
    const {state} = useLocation();
    const [isPremium, setIsPremium] = useState(false)
    const [sendEmailModal, setSendEmailModal] = useState(false)
    const [delay, setDelay] = useState(3000)
    const [latLng, setLatLng] = useState({lat: "", lng: ""})

    const [yourName, setYourName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    
    const [yourNameErr, setYourNameErr] = useState("")
    const [emailErr, setEmailErr] = useState("")
    const [messageErr, setMessageErr] = useState("")

    console.log("statestatestate wel", state)

    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async(position) =>{
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setLatLng({lat: latitude, lng: longitude})
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            })
        }
    }, [])

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "yourName":
                setYourName(e.target.value);
                setYourNameErr(false)
            break;
            case "email":
                setEmail(e.target.value);
                setEmailErr(false)
            break;
            case "message":
                setMessage(e.target.value);
                setMessageErr(false)
            break;
        }
    }

    const sendEmail = async (e)=>{

        e.preventDefault();
        toast.dismiss();

        if(yourName!== undefined && validator.isEmpty(yourName.trim())){
            toast.error("Please Enter Your Name", { });
            setYourNameErr(true)
            return;
        }

        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailErr(true)
            return
        }

        if(message!== undefined && validator.isEmpty(message.trim())){
            toast.error("Please Enter Message", { });
            setMessageErr(true)
            return;
        }
        
        let mailData = {
            Name: yourName,
            ToEmail: email,
            Description: message,
            Email: state.qrCodeDetails.Email
        } 

        console.log("activateDataactivateData", mailData)
          
        try{

            let coOrdinates = {
                QRNumber: state.qrCodeDetails.QRNumber,
                lat: latLng.lat,
                long: latLng.lng,
                email: email,
                name: yourName
            } 
            await postSaveLastScan(coOrdinates, dispatch); 
            let res= await sendEmailToOwner(mailData, dispatch); 
            toast("Email Sent Successfully", { });
            console.log("res.HTTPStatusCoderes.HTTPStatusCode", res)
            setSendEmailModal(false);

            setYourName("");
            setEmail("");
            setMessage("");  
        }catch (err){
            if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            return;
        }

    }
    
    useEffect(()=>{

        if(state && state.qrCodeDetails.QRType === "Preemium")
            setIsPremium(true);
        else
            setIsPremium(false);

    }, [])

    function getNumber(){
        if(state.qrCodeDetails.DNDForCall){
            return "#";
        }else{
            if(state && isPremium)
                return `tel:080-47360576,${state.qrCodeDetails.Id}`
            else if(state)
                return `tel:${state.qrCodeDetails.Contact_No}`
        }
    }

    function getEmergencyNumber(){
        if(state.qrCodeDetails.DNDForEnargencyCall){
            return "#";
        }else{
            if(state && isPremium)
                return `tel:02248931252,${state.qrCodeDetails.Id}`
            else if(state)
                return `tel:${state.qrCodeDetails.EmergencyNumber}`
        }
    }

    const sendEmailModalView = ()=>{
        return  <div>
            <Modal isOpen={sendEmailModal} toggle={()=>{
                setSendEmailModal(false);
                setYourName("");
                setEmail("");
                setMessage(""); 
            }} >
                <ModalHeader >Email</ModalHeader>
                {/* <Row className="justify-content-center text-center">
                        <Col xl={6}> */}
                        <ModalBody>
                            <div className="qr-from text-start p-3 p-sm-4">
                                <div className="white-box">
                                    <h6 className="text-center mb-0 pt-2">Fill the form</h6>
                                    <form className="p-3" onSubmit={sendEmail}>
                                    
                                        <Row className="justify-content-center">
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label className="form-label">Your Name</label>
                                                    <input 
                                                        type="text" 
                                                        className={'form-control'}
                                                        placeholder="Enter full name"
                                                        name="yourName" 
                                                        value={yourName}
                                                        onChange={onInputChange} 
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input 
                                                        type="text" 
                                                        className={'form-control'} 
                                                        name="email" 
                                                        value={email}
                                                        onChange={onInputChange} 
                                                        placeholder="Enter email"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label className="form-label">Massage</label>
                                                    <input
                                                        type="text" 
                                                        className="form-control"
                                                        name="message" 
                                                        value={message}
                                                        onChange={onInputChange} 
                                                        placeholder="Enter Message" 
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn btn-primary btn-rounded" type= "submit" >Submit</button>
                                                <button type= "button" style={{"margin-left": "20px"}} className="btn btn-outline-primary btn-rounded" onClick={()=>{
                                                    setSendEmailModal(false)
                                                    setYourName("");
                                                    setEmail("");
                                                    setMessage(""); 
                                                }} style={{"margin-left": "11px"}}>Cancel</button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                            </ModalBody>
                        {/* </Col>
                    </Row> */}
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </div>
    }
    
    return (
    <React.Fragment>
        {sendEmailModalView()}
        <main className="bg-light qr-screen h-100vh">
            <header className="header px-3 px-sm-4">
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                        <a href="/">
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section>
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col xl={6}>
                            <div className="mb-2 ">
                                <h6 className="light-title text-uppercase text-primary">Welcome to</h6>
                                <h3 class="text-uppercase text-primary">Intello Tags</h3>
                                <p className="mw-250 small mb-0">Choose an option to reach the tag's owner and claim your reward!</p>
                            </div>
                            <div className="mb-5 mt-5">
                                
                               <Swiper
                                    autoplay={{
                                        delay: delay,
                                        disableOnInteraction: false,
                                        // pauseOnMouseEnter: true
                                    }}
                                    loop={true}
                                    effect={'cards'}
                                    grabCursor={true}
                                    modules={[EffectCards, Autoplay]}
                                    className="mySwiperCard px-4"
                                    allowSlideNext= {true}
                                    
                                    // sliderMove={()=>alert()}
                                >
                                   <SwiperSlide>
                                        <div className="Swipe-card" onClick={()=>setDelay(60000)}>
                                            <img src={EmailCard} className="img-fluid w-100" alt="Tag" />
                                            <a href="#" className="btn btn-primary " onClick={(e)=>{
                                                e.preventDefault()
                                                // Swiper.stop()
                                                if( !state.qrCodeDetails.DNDForAll || state.qrCodeDetails.DNDForEmail == 1){
                                                    toast.dismiss()
                                                    toast("User Denied This Permission", { });
                                                    return;
                                                }else{
                                                    setSendEmailModal(true);
                                                }
                                            }}>Mail Me</a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="Swipe-card" onClick={()=>setDelay(60000)}>
                                            <img src={CallCard} className="img-fluid w-100" alt="Tag" />
                                            <a href={getNumber()} className="btn btn-primary px-3 btn-sm"
                                            onClick={(e)=>{
                                                if(!state.qrCodeDetails.DNDForAll || state.qrCodeDetails.DNDForCall == 1){
                                                    e.preventDefault();
                                                    toast.dismiss()
                                                    toast("User Denied This Permission", { });
                                                    return;
                                                }
                                            }}
                                            >Call Me</a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="Swipe-card" onClick={()=>setDelay(60000)}>
                                            <img src={EmergencyCard} className="img-fluid w-100" alt="Tag" />
                                            <a href={getEmergencyNumber()} className="btn btn-primary px-3 btn-sm"
                                            onClick={(e)=>{
                                                if(!state.qrCodeDetails.DNDForAll || state.qrCodeDetails.DNDForEnargencyCall == 1){
                                                    e.preventDefault();
                                                    toast.dismiss()
                                                    toast("User Denied This Permission", { });
                                                    return;
                                                }
                                            }}
                                            >Emergency Number</a>
                                        </div>
                                    </SwiperSlide>
                                    {/* <SwiperSlide>
                                        <div className="Swipe-card">
                                            <img src={EmailCard} className="img-fluid w-100" alt="Tag" />
                                            <a href="mailto:test@yopmail.com" className="btn btn-primary ">Mail Me</a>
                                        </div>
                                    </SwiperSlide> */}
                                    <SwiperSlide>
                                        <div className="Swipe-card" onClick={()=>setDelay(60000)}>
                                            <img src={ClaimRewardCard} className="img-fluid w-100" alt="Tag" />
                                            <a href="#" className="btn btn-primary px-3 btn-sm" onClick={(e)=>{
                                                e.preventDefault();
                                                navigate('/claim-reward', {state: state});
                                            }}>Claim Reward</a>
                                        </div>
                                    </SwiperSlide>
                                   <SwiperSlide>
                                        <div className="Swipe-card" onClick={()=>setDelay(60000)}>
                                            <img src={MedicalHistoryCard} className="img-fluid w-100" alt="Tag" />
                                            <a href="#" className="btn btn-primary px-3 btn-sm" onClick={(e)=>{
                                                e.preventDefault();
                                                // if(state.qrCodeDetails.DNDMedicalHistory == 1){
                                                //     toast("User Denied This Permission", { });
                                                //     return;
                                                // }else{
                                                //     navigate('/medical-history', {state: state});
                                                // }
                                                navigate('/medical-history', {state: state});
                                            }}>Medical history</a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
        
        </main>
    </React.Fragment>
    );
}
  
  export default Cart;