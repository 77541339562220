import React from "react";
import img1 from './../../../../Assets/Images/post/1Artboard.jpg'
import img2 from './../../../../Assets/Images/post/sandhyaVeerArjun-10.4.2023.jpeg'
import img3 from './../../../../Assets/Images/post/Artboard3.jpg'
import img4 from './../../../../Assets/Images/post/4.VirArjun-29.4.2023.jpeg'
import img5 from './../../../../Assets/Images/post/5.Loksatya-2.5.2023.jpeg'
import img6 from './../../../../Assets/Images/post/6.UdayPrakash-3.5.2023.jpg'
import img7 from './../../../../Assets/Images/post/Artboard7.jpg'
import img8 from './../../../../Assets/Images/post/MetroMedia1_page-0001.jpg'
import img9 from './../../../../Assets/Images/post/10.DelhiNews- 5.5.2023.jpeg'
import img10 from './../../../../Assets/Images/post/virat.jpg' 

import { Container, Card, CardTitle,CardBody,  } from 'reactstrap';
import SectionTitle from "../../../../Components/SectionTitle";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { useNavigate } from "react-router-dom";

import { EffectCoverflow, Pagination,  Autoplay } from 'swiper/modules';

const posts = [
    {image: img9, date: '05 May 2023', title: "Delhi news", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img3, date: '11 Apr 2023', title: "Blog", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img1, date: '30 Mar 2023', title: "Etv Bharat", description: 'ा कभी ऐसा आ हैिक आपनेअपनेमहपूण दावेज़, चािबयां, ेिडट/ डेिबट काडया पासपोट खो िदया हो और सोचा हो,िक काश आपकेपास उ वापस पानेका कोई तरीका हो?',  url: '/blog' },
    {image: img2, date: '10 Apr 2023', title: "Sandhya Veer Arjun", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img4, date: '29 Apr 2023', title: "Vir Arjun", description: 'ा कभी ऐसा आ हैिक आपनेअपनेमहपूण दावेज़, चािबयां, ेिडट/ डेिबट काडया पासपोट खो िदया हो और सोचा हो,िक काश आपकेपास उ वापस पानेका कोई तरीका हो?',  url: '/blog' },
    {image: img5, date: '02 May 2023', title: "Lok Satya", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img6, date: '03 May 2023', title: "Uday Prakash", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img7, date: '04 May 2023', title: "Hindi Sanskaran", description: 'ा कभी ऐसा आ हैिक आपनेअपनेमहपूण दावेज़, चािबयां, ेिडट/ डेिबट काडया पासपोट खो िदया हो और सोचा हो,िक काश आपकेपास उ वापस पानेका कोई तरीका हो?',  url: '/blog' },
    {image: img8, date: '04 May 2023', title: "Metro Media", description: 'महपूणदावेज हो डेिबट काड. अगर आपका कुछ भी खो गया हैतो उसेआप तक पंचाएगा इंटेलो टै. बताया जा रहा हैिक देश का पहला लॉ एंड फाउंड ीन टैग है, ',  url: '/blog' },
    {image: img10, date: '05 May 2023', title: "Virat Vaibhav", description: 'ा कभी ऐसा आ हैिक आपनेअपनेमहपूण दावेज़, चािबयां, ेिडट/ डेिबट काडया पासपोट खो िदया हो और सोचा हो,िक काश आपकेपास उ वापस पानेका कोई तरीका हो?',  url: '/blog' },
]

export default ()=>{
    // SwiperCore.use([Autoplay]);

    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Container>
            <SectionTitle >
                <h3 className="section-title" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"><span>News and</span> Beyond</h3>
                <div data-aos="zoom-in-up" data-aos-delay="150" data-aos-duration="1000"><span class="sm-title"></span></div>
            </SectionTitle>
            
            </Container>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                activeIndex={3}
                slideToClickedSlide={true}
                coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                loop={true}
                pagination={false}
                modules={[EffectCoverflow,Autoplay, Pagination]}
                className="mySwiper"
            >
        
                {posts
                    .map((item, index) => (
                        <SwiperSlide>
                            <Card className="post-item " key={index} >
                                {/* <div className="image-wrap"> */}
                                    <img src={item.image} alt={item.title} className="w-100" />
                                {/* </div> */}
                                <CardBody className="text-start">
                                    <CardTitle className="mb-0">
                                        <h5>{item.title}</h5>
                                    </CardTitle>
                                    <div className=""><small>{item.date}</small></div>
                                    
                                    {/* <CardText>{item.description}</CardText> */}
                                    <div className="">
                                        <a className="p-0 btn btn-link text-light" onClick={()=> {
                                navigate('/blog', { state: {"item": item}})
                            }} color="primary">
                                        <u>Read More</u>
                                        </a>
                                    </div>
                                </CardBody>
                            </Card>
                    </SwiperSlide>
                    ))}    
                </Swiper>
        </React.Fragment>
    )
} 