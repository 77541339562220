import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    scrollToOnlineStore: 0,
    CategoryID: null,
    scrollToNews: 0,
}


export const scrollManagment = createSlice({
  name: 'scrollManagment',
  initialState: {
   ...initialState
  },
  reducers: {
    setScrollToOnlineStore: (state, action)=>{
      state.scrollToNews= 0;
      if(typeof action.payload === "object"){
          state.CategoryID = action.payload.CategoryID;
          state.scrollToOnlineStore = action.payload.number;
        }else{
            state.scrollToOnlineStore = action.payload;
            state.CategoryID = null;
      }
    },
   
    setScrollToNewsStore: (state, action)=>{
      return {
        ...initialState,
        scrollToNews: initialState.scrollToNews+1
      }
    },

    resetscrollManagment: (state, action)=>{
      return {...initialState}
  }
  }
})

// Action creators are generated for each case reducer function
export const { setScrollToOnlineStore, resetscrollManagment, setScrollToNewsStore } = scrollManagment.actions;

export const setScrollToOnlineStoreDispatch = (number, CategoryID) => {
    
    return async(dispatch, getState) => {
        if(CategoryID!== undefined){
          dispatch(setScrollToOnlineStore({number, CategoryID}));
        }else{
          dispatch(setScrollToOnlineStore(number));
        }
    }
}

export const setScrollToNewsDispatch = () => {
    return async(dispatch, getState) => {
      dispatch(setScrollToNewsStore());
    }
}


export default scrollManagment.reducer