import React from "react";
import Badge_UI from "../../../../Components/UI/Badge/Badge_UI";
import ProductItemImage from "../../../../Components/Blocks/Home/ProductItemImage";
import Rating from "../../../../Components/Blocks/Home/Rating";
import { Card, CardTitle, CardText, Button, CardBody } from 'reactstrap';
import Cart from './../../../../Assets/Images/cart.png';
import Star from './../../../../Assets/Images/star.png';
import lightStar from './../../../../Assets/Images/star-light.png';

export default (props)=>{

    let product= props.item;

    const getRating = (data) => {
        var numberOfCards = 5;
        var cards = [];
        if (data <= numberOfCards) {
            for (var i = 0; i < numberOfCards; i++) {
                if(i < data){
                    cards[i] = (Star);
                }else{
                    cards[i] = (lightStar);
                }
            }
        }
        return cards;
    }

    return(
        <>
            <Card className="product-item" onClick={(props.onClick)}>
                <div className="image-wrap">
                    <Badge_UI >
                        {product.Discount}%
                    </Badge_UI>
                    <ProductItemImage 
                        href="#"
                        src={product.Image} 
                        alt={product.Name} 
                        clasess={"w-100"} 
                    />
                </div>
                
                <CardBody>
                    <CardTitle className="mb-0">
                        {/* <a href="#">{product.title}</a> */}
                        <a onClick={(e)=>e.preventDefault()}>{product.Name}</a>
                    </CardTitle>
                    <CardText>
                        <Rating 
                            classes= "rating"
                            // rating= {getRating(product.rating)}
                            rating= {getRating(Math.floor(product.Rating))}
                        />
                         <h6 className="price">{product.OfferPrice} <span className="text-decoration-line-through">
                            {product.MRP}
                            </span></h6>
                    </CardText>
                    <Button color="link">
                        <img src={Cart} alt="Cart" className="me-2" />
                        Add to Cart 
                    </Button>
                </CardBody>
            
            </Card>
        </>
    )
} 