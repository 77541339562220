import React from 'react';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import {Container} from 'reactstrap';

import Qrcode from './../Assets/Images/marg_img.jpg';
import Circle from './../Assets/Images/circle.png';
import Barcode from './../Assets/Images/barcode.png';


const Sticky2 = () => (
    <section className="logoZoom text-center p-0">
        <Container>
            {/* <div className="main-title text-center">
                <h2 className="text-uppercase" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="100" ><span>INDIA'S FIRST LOST & FOUND</span> EMERGENCY GREEN TAG</h2>
                <p  data-aos="zoom-in" data-aos-duration="100" data-aos-delay="100" >Redefining safety with the most advanced<br />web/location enabled smart tags</p>
            </div> */}
            <div className="zoom-banner">
               
                <Controller >
                    <Scene
                        classToggle="zapkk"
                        triggerHook="onLeave"
                        duration="500%"
                        pin="#trigger" 
                        triggerElement="#trigger"
                        offset={-100}
                        
                        translatex='-50%'
                        translatey='-50%'
                    >
                        {(progress) => (
                            <div id="trigger" className={`sticky ${progress > 0.2 && 'hide-circle'} ${progress > 0 && 'animation-start'} ${ progress.toFixed(0) === '1' && 'richEnd' }`}>
                                <div className='col-md-12 col-lg-7'>
                                        <Tween    
                                            from={{
                                                opacity:1,
                                            }}           
                                            to={{
                                                opacity:0,
                                                translateY :'-5000',
                                            }}       
                                            ease="Power4.easeInOut"
                                            totalProgress={progress}
                                            paused
                                        > 
                                           <div className="main-title text-center">
                                                <h2 className="text-uppercase" ><span>INDIA'S FIRST LOST & FOUND</span> EMERGENCY GREEN TAG</h2>
                                                <p  data-aos="zoom-in" >Redefining safety with the most advanced<br />web/location enabled smart tags</p>
                                            </div>
                                        </Tween>
                                        </div>
                                <div className='qr-wrap'>
                                    {/* <Tween    
                                        from={{
                                            // x:0,
                                            // scale:0, 
                                            // translatex :'-50%',
                                            // translatey :'-50%',
                                        }}           
                                        to={{
                                            // x:0,
                                            // scale:0, 
                                            // translatex :'-50%',
                                            // translatey :'-50%',
                                        }}  
                                        ease="Power4.easeInOut"
                                        totalProgress={progress}
                                        paused
                                    > 
                                        <div className={`inr-ttl ${progress > 0.9 && 'visiblity-visible' }`}>
                                            <h3 class="section-title"><span>Never lose </span> Anything</h3>
                                        </div>
                                    
                                    </Tween> */}
                                        <div className='col-md-12 col-lg-7'>
                                        <Tween    
                                            from={{
                                                scale:0, 
                                                translatex :'-50%',
                                                translatey :'-50%',
                                            }}           
                                            to={{
                                                scale:1, 
                                                translatex :'-50%',
                                                translatey :'-50%',
                                            }}  
                                            ease="Power4.easeInOut"
                                            totalProgress={progress}
                                            paused
                                            // offset={30000}
                                        > 
                                           <div className={`neverLoose ${progress > 0.8 && 'vsible'}`} id={progress} style={{transform: `scale(${progress > 0.8 ? progress : 0})`, opacity: progress > 0.8 ? progress : 0}}>
                                           <h3 class="section-title"><span>Never lose </span> Anything</h3>
                                           </div>
                                        </Tween>
                                        </div>
                                    <div className='row justify-content-center pt-3 pb-3 pt-lg-4 pb-lg-4'>
                                        <div className='col-md-12 col-lg-7'>
                                        <Tween    
                                            from={{
                                                opacity:0,
                                                scale:300, 
                                                translatex :'-50%',
                                                translatey :'-50%',
                                            }}           
                                            to={{
                                                opacity:1,
                                                scale: 1.00128,
                                                translatex :'-50%',
                                                translatey :'-50%',
                                            }}       
                                            ease="Power4.easeInOut"
                                            totalProgress={progress}
                                            paused
                                        > 
                                            <div className="animate-logo">
                                                
                                                <img src={Qrcode} alt="barcode" />
                                            </div>
                                        </Tween>
                                        </div>
                                    </div>
                                </div>
                               <div className='row justify-content-center'>
                                    <div className='col-md-6 col-lg-5'>
                                        <div className='circle_box'>
                                            <Tween    
                                                from={{
                                                    opacity:1,
                                                    rotate: 0,
                                                    scale:1,
                                                    translatex :'-50%',
                                                    translatey :'-50%',
                                                }}           
                                                to={{
                                                    opacity:0,
                                                    rotate: 360,
                                                    scale:0,
                                                    translatex :'-50%',
                                                    translatey :'-50%',
                                                }}       
                                                ease="Strong.easeOut"
                                                totalProgress={progress}
                                                paused
                                            > 
                                                <div>
                                                    <div className="circle-wrap">
                                                        <img src={Circle} alt="circle" />
                                                    </div>
                                                </div>
                                            </Tween> 
                                            <div className='zoomLg'>
                                                <Tween            
                                                    to={{
                                                    opacity:0,
                                                    scale: 32,
                                                    translatex :'-50%',
                                                    translatey :'-50%',
                                                    }}       
                                                    ease="Power4.easeInOut"
                                                    totalProgress={progress}
                                                    paused
                                                >
                                                    <div className="barcode-wrap">
                                                        <img src={Barcode} alt="barcode" />
                                                    </div>        
                                                </Tween>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        )}
                    </Scene>
               
                </Controller>
                
                
            </div>
        </Container>
    </section>

);

export default Sticky2;