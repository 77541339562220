import React  from "react";
import HeaderFooter from "../../HeaderFooter";
import { Container, Row, Col } from 'reactstrap';
import ClainRevard from './../../Assets/Images/claimRevard.jpg';
export default ()=>{
   
    
    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="primary-page-title">
                        <Container>
                            <h2 className="text-center" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="50">Claiming Your Reward</h2>
                            <p className="text-center" data-aos-duration="1000" data-aos="zoom-in-up" data-aos-delay="100">A Guide for First Responders</p>
                        </Container>
                    </div>
                </section>
                <section className="text-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={9}>
                                <img src={ClainRevard} className="img-fluid" data-aos="fade-in-right" data-aos-delay="100" data-aos-duration="1000" alt="activateTag" />
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 