import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
  data: {}
}

export const dummy = createSlice({
  name: 'dummy',
  initialState: {
   ...initialState
  },
  reducers: {
    setLoaderState: (state, action)=>{
      state.data = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setLoaderState } = dummy.actions


export const dummyDispatch = obj => {
    return async(dispatch, getState) => {
        dispatch(setLoaderState(obj))
    }
}


export default dummy.reducer