
import React, { useEffect, useState } from "react";
import { Card, CardText, CardBody, Badge, CardHeader, CardFooter} from 'reactstrap';

import Tick from './../../Assets/Images/right.png';
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setScrollToOnlineStoreDispatch } from "../../Screen/Home/Slices/scrollManagment.slice";
export default (props)=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const scrollManagment= useSelector(state=>state.scrollManagment);
    const auth = useSelector(state => state.auth)
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
        
    return (
        <React.Fragment>
            <div className="priceTable">
                <Card className="border-0">
                    <CardHeader className="p-0 border-0">
                        <img src={props.image} className="w-100" alt="" />
                    </CardHeader>
                    <CardBody className="border-0 px-0">
                        
                        
                            {props.data
                                .map((item, index) => (
                                    <CardText key={index}><img src={Tick} alt="Tick" /> {item}</CardText>
                                ))}
                        
                        <CardFooter  className="border-0 text-center">
                            <a 
                            // href={props.action}
                             onClick={()=>{
                                navigate('/');
                                dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                            }}
                                className="btn-lg btn btn-outline-primary">Shop Now</a>
                        </CardFooter>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
} 