import React from "react";

import { Row, Col,Container, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import  HeaderFooter from './../../HeaderFooter';

import TermsImage from './../../Assets/Images/termsofuse.png';

function TermsOfUse() {
    
  
    return (
      <React.Fragment>
            <HeaderFooter>
           <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="100">
                <img src={TermsImage} className="w-100" alt="Privacy" />
           </div>

           <Container>
                <div className="my-4 pt-0 pt-md-4">
                    <Breadcrumb>
                        <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                        <BreadcrumbItem active>Terms of Use</BreadcrumbItem>
                    </Breadcrumb>
                </div>
           </Container>
          <div className=" main-content heading-medium">
          
            <Container>
              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Terms of use</h4>
                <p>By purchasing, registering or activating <strong>Intello Tags</strong> products on our website, mobile application & through authorised franchise, the Customer acknowledges that they have read, understood and agree with the present <strong>Terms of Use (TOU) and Privacy Policy (PP)</strong>. If you do not agree to these TOU, you shall not subscribe for, or use, the services or the website / Application.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Terminology</h4>
                <p><strong>Intello Tags</strong> operates these websites ("<a href="https://intellotags.com" target="_blank">https://intellotags.com</a> & <a target="_blank" href="https://www.sarthii.me/">https://www.sarthii.me</a>"), including all information, documents, content, materials, software and services (the "Content") available on <a target="_blank" href="https://intellotags.com">https://intellotags.com</a> & <a target="_blank" href="https://www.sarthii.me">https://www.sarthii.me</a>. Website and content is available to you only for your personal, non-commercial use subject to the TOU set forth in the present agreement ("TOU "), together with our PP.</p>
                <p>For the purpose of these TOU, wherever the context so requires,</p>
                <p>"You", "Your", "Yourself", "User" shall mean and refer to natural and legal individuals who use the Platform and who is competent to enter into binding contracts, as per the laws.</p>
                <p>"We", "Our", and "Us" shall mean and refer to the Domain and/or We, as the context so requires.</p>
                <p>"Third Parties" refer to any Application, company or individual apart from the User, and the creator of this Application.</p>
                <p>"Services" shall refer to Services provided by We, which shall   refer to providing a platform mainly connecting people through their unique QR code based tags at the time of emergency and for the return of lost and/or misplaced goods.</p>
                <p>The term "Platform" refers to the Website/Domain / Application created by We which provides the Client with the services of We thru the use of the platform.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Who can use</h4>
                <p>We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the Intello Tags community.</p>
                <p>By Purchasing, registering or activating Intello Tags products on our website, Mobile APP or over the phone, you acknowledge and warrant that:</p>
                <ul>
                    <li>You are the legal owner of the registered vehicle, items or pet.</li>
                    <li>You are over the age of 18 or the age of majority in your province, state or territory of residence.</li>
                    <li>Your contact information such as your contact number, email address and mailing address provided are indeed yours and are valid & accurate at all times.</li>
                    <li>Your email address is valid and belongs to you, or, in the alternative, you have the permission to use the provided email address.</li>
                    <li>You are responsible to update your contact information with Intello Tags at all times. If your email address is incorrect, Intello Tags may not be able to serve you. To update your information, <a href="/login"><u>login</u></a> through our website /Mobile Application and do the necessary changes.</li>
                    <li>You are the owner of the credit card used to purchase Intello Tags products or memberships on Intello Tags's website(s) / Application, or have the owner's permission to purchase items using the owner's credit card or debit card.</li>
                </ul>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Things to Remember</h4>
                <ul>
                    <li>You may not use this Site / Application to impersonate any person or business partner, or to obtain access to the Site without authorization.</li>
                    <li>You may not use this Site / Application in a manner that is fraudulent or otherwise illegal or promotes illegal activities, including without limitation engaging in phishing or otherwise obtaining financial or other personal information in a misleading manner or for fraudulent or misleading purposes.</li>
                    <li>You may not use this Site / Application in a manner that violates the intellectual property rights of others.</li>
                    <li>You may not use this Site / Application in any manner that might be libellous or defamatory, or in a way that is otherwise malicious or harmful to any person or entity, or discriminatory based on race, colour, sex, religion, nationality, ethnic or national origin, marital status, disability, sexual orientation or age.</li>
                    <li>You may not use this Site / Application to engage in spamming or other advertising or marketing activities that violate any applicable laws, regulations or generally-accepted advertising industry guidelines.</li>
                    <li>You may not interfere with other users’ use of the Site / Application.</li>
                    <li>If customer intent not to receive notification and activate DND option then company will not have any responsibility or liability of services during this period.</li>
                    <li>When your tag is scanned by a finder, location information is requested from the browser on the finder’s smartphone. However, browsers use various mechanisms to determine location, including Wi Fi and GPS, depending upon what’s available under the circumstances. Therefore, location accuracy is dependent upon the browser/smartphone. On average, we have found that location accuracy is very good, but this cannot be guaranteed under all circumstances. However if location of scanned device is not available or off during the scan then company won’t be able to provide scanned location as well as history of last location.</li>
                </ul>
                <p>Breach of any of the foregoing will result in our disabling your access, removing your Content and preventing you from using the Site.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Trademarks & Copyrights</h4>
                <p>The entire contents of the Site are protected under the law. This Site / Application is the property of Intello Tags © 2021. All rights reserved.</p>
                <p>Our name and logo and all related product and service marks and slogans are the service marks and trademarks of Intello Tags. Intello Tags and Intellotagss are the registered trademark of our company. You may not use the Intello Tags and Intellotagss trademarks, service marks or logos without the prior written consent of us.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Payments and Disclaimers</h4>
                <p>The base service is free and ample for most individual users. The base service can be used for commercial purposes, but cannot be resold without a partner agreement.</p>
                <p>The User availing the content available on the platform is free of cost towards the usage of the platform.</p>
                <p>We will not be liable for any credit or debit card fraud. The liability to use a card fraudulently will be on the user and the onus to 'prove otherwise' shall be exclusively on the user. To provide a safe and secure experience, we regularly monitor transactions for fraudulent activity. In the event of detecting any suspicious activity, we reserve the right to cancel all past, pending and future orders without any liability.</p>
                <p>We shall disclaim all responsibility and owns no liability to Users for any outcome (incidental, direct, indirect or otherwise) from the use of the Services. We, as a merchant, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the present limit mutually agreed by us with our acquiring bank from time to time.</p>
                <p>We offers "Intello Tags" with enhanced features and capabilities. These Tags can be obtained by upgrading individual tags, purchasing a set of Tags or enrolling in a professional subscription package.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>No Exchange or Returns</h4>
                <p>All sales are final. Due to the nature of the goods sold by us, we cannot offer returns or exchanges. We do this to ensure that each tag is unique and NEVER before issued to another company/person.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Claims of Infraction</h4>
                <p>If you believe that your work has been copied in a way that constitutes intellectual property infringement, please provide the following information to us:</p>
                <ul>
                    <li>Your current address, telephone number, and email address.</li>
                    <li>Identification of the material that you claim is infringing and a statement as to how it is infringing.</li>
                    <li>Your legible and valid physical or electronic signature.</li>
                    <li>A statement declaring that the information in your notice is accurate, and that you are the owner of the copyright interest involved or you are authorized to act on behalf of that owner.<br />
                    You can reach us at: <a href="mailto:hello@intellotags.com">hello@intellotags.com</a> </li>
                </ul>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Links</h4>
                <p>Our Application / website may be linked to, or have included within, other websites on the Internet. Intello Tags has not reviewed any or all of the sites linked to <a target="_blank" href="https://intellotags.com">https://intellotags.com</a> is not responsible for any links to this site or their content. Your linking to any sites from this site is at your own risk. By linking to a site or permitting a link to this site, Intello Tags does not endorse the site operator or the content of the linked site.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3" data-aos-delay="200" data-aos-duration="200">
                <h4>Termination</h4>
                <p>We reserve the right, in its sole discretion, to unilaterally terminate the User’s access to the Platform, or any portion thereof, at any time, without notice or cause.</p>
                <p>We reserves the right, in its sole discretion, to unilaterally terminate the User’s access to the Platform, or any portion thereof, at any time, without notice or cause.</p>
                <p>The Platform also reserves the universal right to deny access to particular Users, to any/all of are on its Platform without any prior notice/explanation in order to protect the interests of the Platform and/or other visitors to the Platform.</p>
                <p>The Platform reserves the right to limit, deny or create different access to the Platform and its features with respect to different Users, or to change any of the features or introduce new features without prior notice.</p>
                <p>The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of the same.</p>
              </div>

              <div data-aos="zoom-in-up" className="pb-3"  data-aos-duration="200">
                <h4>Law & Jurisdiction</h4>
                <p>These terms and conditions are governed by and construed in accordance with the laws of Capital of India and you irrevocably submit to the exclusive jurisdiction of the courts in Delhi. </p>
                <p><small>Effective date: August 1, 2020 last updated on: September 18, 2023</small></p>
              </div>

            </Container>
          </div>
          </HeaderFooter>
      </React.Fragment>
    );
  }
  export default TermsOfUse;