import React, {useState} from "react";
import Product1 from './../../../../Assets/Images/product/1.jpg';
import Product2 from './../../../../Assets/Images/product/2.jpg';
import Product3 from './../../../../Assets/Images/product/3.jpg';
import Product4 from './../../../../Assets/Images/product/4.jpg';
import Product5 from './../../../../Assets/Images/product/5.jpg';
import Product6 from './../../../../Assets/Images/product/6.jpg';
import Product7 from './../../../../Assets/Images/product/7.jpg';
import Product8 from './../../../../Assets/Images/product/8.png';
import Product9 from './../../../../Assets/Images/product/9.jpg';

import SectionTitle from "../../../../Components/SectionTitle";
import { Container } from "reactstrap";

export default ()=>{
    const [isActive, setIsActive] = useState(1);
const data = [Product1, Product2, Product3,Product4, Product5, Product6,Product7, Product8, Product9]
    return (
        <React.Fragment>
            <SectionTitle >
                <h3 className="section-title" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"> <span>Exquisite</span> Products</h3>
                <div data-aos="zoom-in-up" data-aos-delay="150" data-aos-duration="1000"><span class="sm-title"></span></div>
            </SectionTitle>
            <Container>
                <div className="optionsWrap">
                    <div className="options"  >
                    {data
                        .map((item, index) => (
                        <div style={{
                            background: `url(${item})`,
                        }} className={`option ${isActive == index && 'active'}`} onClick={() => setIsActive(index)} >
                                <div class="shadow"></div>
                        </div>
                        ))}
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
} 