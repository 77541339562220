import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from '../../../utility/rootService';
import { BASE_URL, PRODUCT_CATEGORIES } from '../../../utility/apiRoutes';
import { getProductListDispatch } from './ProductsListing';

const initialState = {
  catagories: [],
  isResGet: false
}

export const ProductCategory = createSlice({
  name: 'ProductCategory',
  initialState: {
    ...initialState
  },
  reducers: {
    setProductCategory: (state, action)=>{
      state.catagories = action.payload;
      state.isResGet = true;
    },
    resetProductCategory: (state, action)=>{
        return {...initialState}
    }
  }
})

export const { setProductCategory, resetProductCategory } = ProductCategory.actions

async function productCategoryDispatchFetch(
  // token, 
  dispatch
  ){

    try{
    //   let url= API.BASE_URL+API.GET_PROFILE_DETAILS;
      let url= BASE_URL+PRODUCT_CATEGORIES;
     
      let headerConfig = {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " +token
      }

      const fetchProductCategory =await getData({url, headerConfig}, dispatch)
    
    return fetchProductCategory;
    
  }catch (err){

    console.log("this is a fetchProductCategory catch")
    throw (err)
  }

}

export const getProductCategoryDispatch = (categoryID) => {

  return async(dispatch, getState) => {
    console.log('fetchProductCategoryDispatch', categoryID);
    
    // if(getState().authToken.payload === "") return;

    try{
        // let token = getState().authToken.payload.accessToken;
        const resProductCategoryDisp =await productCategoryDispatchFetch(
          // token, 
          dispatch
        )
        
        dispatch(setProductCategory(resProductCategoryDisp.Success));
        if(categoryID!== null){
          dispatch(getProductListDispatch(categoryID, -1, 1, 10, -1));
        }else{
          dispatch(getProductListDispatch(resProductCategoryDisp.Success[0].CategoryID, -1, 1, 10, -1));
        }
       
      }catch (err){
        console.log("this is a fetchProductCategoryDispatch catch pp", err)
        if(err!= undefined && err.isError){
        }
    }
  }
}

export const resetProductsDispatch = () => {
    return async(dispatch, getState) => {
        dispatch(resetProductCategory())
    }
}

export default ProductCategory.reducer