// import { setAuthToken } from 'layouts/authentication/slice/authToken.slice';
// import { toast } from 'react-toastify';
// import { BASE_URL, REFRESH_TOKEN } from "./apiRoutes";

import { loaderDispatch } from "../Components/Loader/slice.reducer";

export function postData({url, headerConfig, dataObje= null}, dispatch){

    return new Promise((resolve, reject)=>{

        let body= "";
        if(dataObje!==null && dataObje?.isFormData){
            body= dataObje?.data;
        }else{
            body= dataObje!==null? JSON.stringify(dataObje): "";
        }
        dispatch && dispatch(loaderDispatch(true));
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {},
            signal: AbortSignal.timeout(60000),
            body: body,
        })
        .then(async(response) => {
            dispatch && dispatch(loaderDispatch(false));
            if(!response.ok && response.status === 401){
                // let res =await resetToken("POST" ,{url, headerConfig, dataObje}, dispatch)
                // resolve(res)
            }else if(!response.ok && response.status === 404){
                throw ({status: "404"})
            }else {
                return response.json()
            }
        })
        .then((data) => {
            dispatch && dispatch(loaderDispatch(false));
            if(data.isError !== undefined && data.isError ){
                throw (data)
            }
    
            resolve(data);

        })
        .catch((error) => {
            dispatch && dispatch(loaderDispatch(false));
            reject(error)
        });
        
    })
}

export function getData({url, headerConfig}, dispatch){

    return new Promise((resolve, reject)=>{
        
        dispatch && dispatch(loaderDispatch(true));
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {},
            signal: AbortSignal.timeout(60000),
        })
        .then( async(response) => {
            dispatch && dispatch(loaderDispatch(false));
            if(!response.ok && response.status === 401){
                // let res =await resetToken("GET" ,{url, headerConfig}, dispatch)
                // resolve(res)
            }else{
                return response.json();
            }
            
        })
        .then((data) => {
            dispatch && dispatch(loaderDispatch(false));
            if(data.isError !== undefined && data.isError ){
                throw (data)
            }
            console.log("getCitygetCity", data);
             resolve(data)
        })
        .catch((error) => {
            dispatch && dispatch(loaderDispatch(false));
            reject(error)
        });
        
    })
}

export function postFormData({url, headerConfig, formData}, dispatch){

    return new Promise((resolve, reject)=>{

        dispatch && dispatch(loaderDispatch(true));
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {} ,
            body: formData!=undefined? (formData): "",
        })
        .then(async(response) => {
            dispatch && dispatch(loaderDispatch(false));
            if(!response.ok && response.status === 401){
                // let res =await resetToken("POST" ,{url, headerConfig, formData}, dispatch)
                // resolve(res)
            }else{
                return response.json()
            }
        })
        .then((data) => {
            dispatch && dispatch(loaderDispatch(false));
            if(data.isError !== undefined && data.isError ){
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            dispatch && dispatch(loaderDispatch(false));
            reject(error)
        });
        
    })
}

// export async function resetToken(METHOD, urlDataConfig, dispatch){
    
//     return new Promise( async(resolve, reject)=>{
        
//         const localData = await JSON.parse(localStorage.getItem('data')).payload;
        
//         let url= BASE_URL+REFRESH_TOKEN
        
//         let dataObje = {
//             "accessToken": localData.accessToken,
//             "refreshToken": localData.refreshToken,
//         }

//         let headerConfig = {
//             "Content-Type": "application/json"
//         }

//         fetch(url, {
//             method: 'POST', // or 'PUT'
//             headers: {...headerConfig},
//             body:  JSON.stringify(dataObje)
//         })
//         .then(async(response) => {

//             if(!response.ok && response.status === 400){
//                 return reject(response)
//             }else{
//                 return response.json()
//             }

//         })
//         .then(async (refreshTokenData) => {
            
//             dispatch!=undefined && dispatch(setAuthToken(refreshTokenData.result))
//             await localStorage.setItem("data", JSON.stringify({
//             ...refreshTokenData.result
//             }))

//             let res = {}
//             if(METHOD === "GET"){
//                 res = await getApiAfterRefresh(urlDataConfig, refreshTokenData)
//             }else{
//                 res = await postApiAfterRefresh(urlDataConfig, refreshTokenData)
//             }

//             resolve(res)
//         })
//         .catch((error) => {
//             reject(error)
//         })
//         .finally(() => {
//             console.log('Experiment completed');
//         });
        
//     })
// }

// export function postApiAfterRefresh({url, headerConfig, dataObje}, refreshTokenData){

//     return new Promise((resolve, reject)=>{
        
//         let updateHeaderConfig = {
//             ...headerConfig,
//             "Content-Type": "application/json",
//             "Authorization": "Bearer " +refreshTokenData.result.payload.accessToken
//         }

//         fetch(url, {
//             method: 'POST', // or 'PUT'
//             headers:  updateHeaderConfig !=undefined ? {...updateHeaderConfig}: {} ,
//             body: dataObje!=undefined? JSON.stringify(dataObje): "",
//         })
//         .then( async(response) => {
//             return response.json()
//         })
//         .then((data) => {
          
//             if(data.isError !== undefined && data.isError ){
//                 throw (data)
//             }
//             resolve(data)
//         })
//         .catch((error) => {

//             console.log("this is a getData catch", error)
//             reject(error)
//         });
        
//     })
// }

// export function getApiAfterRefresh({url, headerConfig}, refreshTokenData){

//     return new Promise((resolve, reject)=>{
        
//         let updateHeaderConfig = {
//             ...headerConfig,
//             "Content-Type": "application/json",
//             "Authorization": "Bearer " +refreshTokenData.result.payload.accessToken
//         }

//         fetch(url, {
//             method: 'GET', // or 'PUT'
//             headers:  updateHeaderConfig !=undefined ? {...updateHeaderConfig}: {} ,
//         })
//         .then( async(response) => {
//             return response.json()
//         })
//         .then((data) => {
          
//             if(data.isError !== undefined && data.isError ){
//                 throw (data)
//             }
//             resolve(data)
//         })
//         .catch((error) => {

//             console.log("this is a getData catch", error)
//             reject(error)
//         });
        
//     })
// }

export function deleteAPI({url, headerConfig, dataObje}, dispatch){

    return new Promise((resolve, reject)=>{

        dispatch && dispatch(loaderDispatch(true));
        fetch(url, {
            method: 'DELETE', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {} ,
            body: dataObje!=undefined? JSON.stringify(dataObje): "",
        })
        .then(async(response) => {
            dispatch && dispatch(loaderDispatch(false));
            if(!response.ok && response.status === 401){
                // let res =await resetToken("POST" ,{url, headerConfig, dataObje}, dispatch)
                // resolve(res)
            }else{
                return response.json()
            }
            // return response.json()
        })
        .then((data) => {
            dispatch && dispatch(loaderDispatch(false));
            if(data.isError !== undefined && data.isError ){
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            dispatch && dispatch(loaderDispatch(false));
            reject(error)
        });
        
    })
}

export function putData({url, headerConfig, dataObje= null}, dispatch){

    return new Promise((resolve, reject)=>{

        let body= "";
        if(dataObje!==null && dataObje?.isFormData){
            body= dataObje?.data;
        }else{
            body= dataObje!==null? JSON.stringify(dataObje): "";
        }

        dispatch && dispatch(loaderDispatch(true));
        fetch(url, {
            method: "PUT", // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {},
            body: body
        })
        .then(async(response) => {
            dispatch && dispatch(loaderDispatch(false));
            return true
        })
        .then((data) => {
            dispatch && dispatch(loaderDispatch(false));
            resolve(data)
        })
        .catch((error) => {
            dispatch && dispatch(loaderDispatch(false));
            reject(error)
        });
        
    })
}