import React from "react";
import TagImg from './../../../../Assets/Images/membership-expired.png'
import {Container, Row, Col } from 'reactstrap';
import App from './../../../../Assets/Images/app.png';
import Play from './../../../../Assets/Images/google-play.png';
import Logo from './../../../../Assets/Images/logo.png';
function Cart() {
    
    return (
    <React.Fragment>
        <main className="bg-light qr-screen h-100vh">
            <header className="header  px-3 px-sm-4">
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                        <a href="/">
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section>
                <Container>
                    <Row className="justify-content-center mt-3 text-center">
                        <Col xl={6}>
                            <div className="mb-2 text-primary">
                                <h3 className="text-danger">Membership Expired</h3>
                            </div>
                            <div className="membershipExpired py-4">
                                <img src={TagImg} className="img-fluid" alt="Tag" />
                            </div>
                            <div className="mb-5">
                                <p className="font-medium">Renew your membership for<br/>endless possibilities.</p>
                                <a href="/" className="btn btn-outline-primary px-lg-4">Go to Home</a>
                            </div>
                            <div className="footer-apps">
                                <h5 className="mb-2">Download our App</h5>
                                <a href="#" className="w-100 me-0" target="_blank">
                                    <img src={App} className="img-fluid" alt="App Store" />
                                </a><br/>
                                <a  className="w-100 ms-0" href="https://play.google.com/store/apps/details?id=com.intello.tagss&pli=1" target="_blank">
                                    <img src={Play} className="img-fluid" alt="Play Store" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        
        </main>
    </React.Fragment>
    );
}
  
  export default Cart;